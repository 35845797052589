import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-physical-exercise-fitness-and-gut',
  templateUrl: './physical-exercise-fitness-and-gut.component.html',
  styleUrls: ['./physical-exercise-fitness-and-gut.component.css']
})
export class PhysicalExerciseFitnessAndGutComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
