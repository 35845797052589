import { Component, OnInit } from "@angular/core";
import { FormBuilder } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { IUserProfileCreate } from "interfaces";
import { browser } from "protractor";
import { IAddress } from "../../../interfaces/orders";
import { UserService } from "../api/users/users";
import { AppComponent } from "../app.component";
import { UtilityService } from "../utility.service";

@Component({
  selector: "app-account-questions",
  templateUrl: "./account-questions.component.html",
  styleUrls: ["./account-questions.component.css"],
})
export class AccountQuestionsComponent implements OnInit {
  constructor(
    private formBuilder: FormBuilder,
    private userService: UserService,
    private router: Router,
    private route: ActivatedRoute,
    private utils: UtilityService,
    private translate: TranslateService,
    private app: AppComponent
  ) {}

  accountType: string;
  private sub: any;

  createAccountForm = this.formBuilder.group({
    first_name: "",
    last_name: "",
    street_name: "",
    street_number: "",
    zip_code: "",
    city: "",
    country: "",
    email: "",
    email_confirm: "",
    password: "",
    date_of_birth: "",
    phone_number: "",
    street_number_extension: "",
  });
  // test
  ngOnInit() {
    this.sub = this.route.params.subscribe((params) => {
      this.accountType = params.type;
    });
  }

  async onSubmit() {
    let isEmailTaken = null;
    let isPhoneNumberTaken = null;
    let isAccountActivated = null;

    if (this.createAccountForm.get("password").value.length < 5) {
      this.utils.showWarning("SHORT_PW_WARNING", "SHORT_PW_WARNING");
      return;
    }
    if (this.createAccountForm.get("password").value.length > 20) {
      this.utils.showWarning("LONG_PW_WARNING", "LONG_PW_WARNING");
      return;
    }
    if (
      this.createAccountForm.get("email_confirm").value !==
      this.createAccountForm.get("email").value
    ) {
      this.utils.showWarning("EMAILS_NOT_IDENTICAL", "EMAILS_NOT_IDENTICAL");
      return;
    }

    await this.userService
      .IsEmailTaken(this.createAccountForm.get("email").value)
      .then((data) => (isEmailTaken = data.data));
    console.log(isEmailTaken);

    if (isEmailTaken) {
      await this.userService
        .IsAccountActive(this.createAccountForm.get("email").value)
        .then((data) => (isAccountActivated = data.data));
      console.log(isAccountActivated);

      if (!isAccountActivated) {
        this.utils.showWarning(
          "ACCOUNT_NOT_ACTIVATED_WARNING",
          "ACCOUNT_NOT_ACTIVATED_WARNING"
        );
        return;
      } else {
        this.utils.showWarning("EMAIL_IN_USE_WARNING", "EMAIL_IN_USE_WARNING");
        return;
      }
    }

    const newUser: IUserProfileCreate = {
      first_name: this.createAccountForm.get("first_name").value,
      last_name: this.createAccountForm.get("last_name").value,
      email: this.createAccountForm.get("email").value.toLowerCase(),
      password: this.createAccountForm.get("password").value,
      is_medical_user: this.accountType === "specialist",
    };
    try {
      console.log(localStorage.getItem("language"));
      const response = await this.userService.createAccount(
        newUser,
        localStorage.getItem("language"),
        this.app.partnerVersion
      );
      await this.router.navigate(["/test-results"]);
      this.utils.showSuccess("REGISTRATION_SUCCESS", "REGISTRATION_SUCCESS");
    } catch {
      this.utils.showWarning("NEW_USER_WARNING", "NEW_USER_WARNING");
    }
  }
}
