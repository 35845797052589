import axios from 'axios';
import { Injectable } from '@angular/core';
import {IItem} from '../../../../interfaces/orders';
import {environment} from '../../../environments/environment';

axios.defaults.baseURL = environment.url.api;

function authHeaders() {
  const token = localStorage.getItem('token');
  return {
    headers: {
      Authorization: `Bearer ${token}`
    }
  };
}

@Injectable()
export class ItemsService {
  constructor() {

  }


  async GetItems() {
    return axios.get<IItem[]>(`/api/v1/items/active`, authHeaders());
  }

  async GetItem(id: number) {
    return axios.get<IItem>(`/api/v1/items/active/${id}`, authHeaders());
  }
}
