import {
  Component,
  OnInit,
  ViewEncapsulation,
  HostListener,
  ElementRef,
} from "@angular/core";
import { DietaryService } from "../api/test/dietary";
import { ActivatedRoute, Router } from "@angular/router";
import {
  IDietaryIntakeDay,
  IDietaryIntakeItemGet,
  IDietaryIntakeItemV2,
  IDietaryIntakeItem,
  IFoodItemCustom,
  IFoodItem,
  IMissingFoodItem,
} from "../../../interfaces/advice";
import { SubjectService } from "../api/test/subject";
import { UtilityService } from "../utility.service";
import { AuthenticationService } from "../api/login/authentication";
import { LangChangeEvent, TranslateService } from "@ngx-translate/core";
import { delay } from "rxjs/operators";
import { ISubject, ITestKit } from "../../../interfaces/tests";
import { type } from "os";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import Fuse from "fuse.js";
import { AppComponent } from "../app.component";

@Component({
  selector: "app-food-diary",
  templateUrl: "./food-diary.component.html",
  styleUrls: ["./food-diary.component.css"],
  encapsulation: ViewEncapsulation.None,
})
export class FoodDiaryComponent implements OnInit {
  constructor(
    private dietaryService: DietaryService,
    private route: ActivatedRoute,
    private subjectService: SubjectService,
    private utils: UtilityService,
    public app: AppComponent,
    private router: Router,
    private translate: TranslateService,
    private authenticationService: AuthenticationService,
    private formBuilder: FormBuilder,
    private elementRef: ElementRef
  ) {}

  testSubjects: ISubject[];
  dietaryDays: IDietaryIntakeDay[];
  id: number;
  submitPopUp = false;
  itemsToDelete: IDietaryIntakeItemGet[];
  editMode = false;
  notSubmitted = true;
  saveChangesPopUp = false;
  selectedDay = 0;
  referenceTable = "";
  foodItems: IFoodItemCustom[];
  title = "Track your meals";
  description =
    "In order to have a more accurate view of your microbiome and understand the connection between your bacteria and your diet, we require you to track your meals for 3 days before taking the sample. After submitting your food diary you will receive an email containing the shipping address for your sample.";
  btnAddItem = "Add item";
  btnViewDiary = "View food diary";
  public mealTypes: { name: string; translatedName: string }[] = [];
  selectedLanguage: string;
  searchLbl = "Search..";
  aClass = "not-active";
  itemsToUpdate: any[] = [];
  addItemTextFirst: string;
  addItemButton: string;
  addItemTextSecond: string;
  addedItems: { [key: string]: IDietaryIntakeItemGet[][] } = {
    Breakfast: [[], [], []],
    Lunch: [[], [], []],
    Dinner: [[], [], []],
    Snacks: [[], [], []],
  };
  showAddItemForm: boolean[] = [false, false, false, false];
  foodTitle: string[] = ["", "", "", ""];
  @HostListener("document:click", ["$event"])
  public onDocumentClick(event: MouseEvent): void {
    this.handleClickOutside(event);
  }

  foodForm = this.formBuilder.group({
    food_item: "",
    unit: "",
    quantity: "",
    quantity_custom: 0
  });

  userAddItem = this.formBuilder.group({
    submittedItem: "",
  });

  selectedFoodItem = "";
  selectedFood: IFoodItem;
  testkitId: number;
  testkitStatus: string;
  showReference = false;
  showCustomerField = false;
  filteredItems: any[];

  customUnits = false;
  unitForSpecificItem: string = '---';

  enUrl = '../assets/food_diary_pdfs/food_diary';
  nlUrl = '../assets/food_diary_pdfs/voedingsdagboek';
  itUrl = '../assets/food_diary_pdfs/diario_alimentare';
  skUrl = '../assets/food_diary_pdfs/stravovací_denník';
  deUrl = '../assets/food_diary_pdfs/essenstagebuch';

  partnerImgSrc = '';

  async ngOnInit() {

    if (this.app.partnerVersion != ''){
      this.partnerImgSrc = '-' + this.app.partnerVersion;
    }

    const response = await this.subjectService.GetSubjects();
    this.testSubjects = response.data["results"];
    this.id = Number(this.route.snapshot.paramMap.get("id"));
    this.dietaryDays = await (
      await this.dietaryService.GetDietaryDays(this.id)
    ).data;

    console.log(this.dietaryDays);

    for (const subject of this.testSubjects) {
      for (const i of subject.testkits) {
        if (i.id === this.id) {
          if (i.dietary_done) {
            this.notSubmitted = false;
          }
          break;
        }
      }
    }
    this.itemsToDelete = [];
    for (let d of this.dietaryDays) {
      d.dietary_intake_items.sort((a, b) => (a.id > b.id ? 1 : -1));
      for (const item of d.dietary_intake_items) {
        const mealCategory = this.getTimeCategory(item.time);
        if (mealCategory && this.addedItems.hasOwnProperty(mealCategory)) {
          const dayIndex = this.addedItems[mealCategory].findIndex(
            (dayItems) =>
              dayItems.length > 0 && dayItems[0].dietary_day_id === d.id
          );
          if (dayIndex !== -1) {
            this.addedItems[mealCategory][dayIndex].push(item);
          } else {
            this.addedItems[mealCategory][parseInt(d.day_index) - 1].push(item);
          }
        }
      }
    }

    this.updateTable();
    this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      this.updateTable();
    });
    this.testkitId = Number(this.route.snapshot.paramMap.get("id"));
    const testKit = (await this.subjectService.GetTestKit(this.testkitId)).data;
    this.testkitStatus = testKit.status;
    const response2 = await this.dietaryService.GetFoodItem();
    this.foodItems = response2.data;
    this.filteredItems = this.foodItems.slice();
    this.dietaryDays = await (
      await this.dietaryService.GetDietaryDays(this.testkitId)
    ).data;
    this.foodForm = new FormGroup({
      food_item: new FormControl(null, [Validators.required]),
      quantity: new FormControl(null, [Validators.required]),
      unit: new FormControl(null, [Validators.required]),
      quantity_custom: new FormControl(null, [Validators.required])
    });
    this.userAddItem = new FormGroup({
      submittedItem: new FormControl(null, [Validators.required]),
    });

    this.addItemTextFirst = "FOOD_DIARY.NEW_TEXT_PARAGRAPH_4_PART_1";
    this.addItemButton = "FOOD_DIARY.NEW_TEXT_PARAGRAPH_4_BUTTON";
    this.addItemTextSecond = "FOOD_DIARY.NEW_TEXT_PARAGRAPH_4_PART_2";

    // Add an english name and a translated one for the table headers.
    this.mealTypes = [
      {
        name: "Breakfast",
        translatedName: await this.translateText(
          "WEBSITE_REDESIGN_DIMITAR.MEAL_TYPE_BREAKFAST"
        ),
      },
      {
        name: "Lunch",
        translatedName: await this.translateText(
          "WEBSITE_REDESIGN_DIMITAR.MEAL_TYPE_LUNCH"
        ),
      },
      {
        name: "Dinner",
        translatedName: await this.translateText(
          "WEBSITE_REDESIGN_DIMITAR.MEAL_TYPE_DINNER"
        ),
      },
      {
        name: "Snacks",
        translatedName: await this.translateText(
          "WEBSITE_REDESIGN_DIMITAR.MEAL_TYPE_SNACKS"
        ),
      },
    ];

    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth"
    });
  }

  async OnSubmit() {
    if (!this.selectedFood || !this.selectedFood.id) {
      this.utils.showError(
        "DIETARY_INTAKE_ITEM_ERROR",
        "DIETARY_INTAKE_ITEM_ERROR"
      );
      return;
    }

    if (this.foodForm.get("quantity").value == undefined && this.foodForm.get("unit").value == undefined){
      this.utils.showError(
        "DIETARY_INTAKE_ITEM_ERROR",
        "DIETARY_INTAKE_ITEM_ERROR"
      );
      return;
    }

    if (this.foodForm.get("unit").value == null) {
      this.utils.showError(
        "DIETARY_INTAKE_ITEM_ERROR",
        "DIETARY_INTAKE_ITEM_ERROR"
      );
      return;
    }

    const currentMeal = ["Breakfast", "Lunch", "Dinner", "Snacks"][
      this.showAddItemForm.indexOf(true)
    ];
    const defaultTime = this.getDefaultTimeForMeal(currentMeal);
    const dietaryDayId = this.dietaryDays.find((day) => day.day_index == (this.selectedDay + 1).toString());

    const food_item: IFoodItem = {
      id: this.selectedFood.id,
      title: this.selectedFood.title,
      food_group_title: this.selectedFood.food_group_title,
      image_url: this.selectedFood.image_url,
      measurement_type: this.selectedFood.measurement_type,
      unit: this.selectedFood.unit,
      amount: this.selectedFood.amount
    };

    let foodItemQuantity;
    let foodItemUnit;
    let usesCustomUnit;
    if (this.foodForm.get("unit").value == 'other') {
      foodItemQuantity = this.foodForm.get("quantity").value * food_item.amount;
      foodItemUnit = food_item.unit;
      usesCustomUnit = true;
    } else {
      foodItemQuantity = this.foodForm.get("quantity").value;
      foodItemUnit = this.foodForm.get("unit").value;
      usesCustomUnit = false;
    }

    const newDietaryItemGet: IDietaryIntakeItemGet = {
      id: null,
      dietary_day_id: dietaryDayId.id,
      food_item: food_item,
      quantity: foodItemQuantity,
      meal: currentMeal,
      unit: foodItemUnit,
      time: defaultTime,
      custom_unit_type: usesCustomUnit
    };

    const postDietaryItem: IDietaryIntakeItem = {
      ...newDietaryItemGet,
      food_item_id: this.selectedFood.id,
    };

    this.selectedFood = null;
    this.foodForm.get("quantity").setValue(null);
    this.foodForm.get("food_item").setValue(null);
    this.foodForm.get("quantity_custom").setValue(null);
    this.addedItems[currentMeal][this.selectedDay].push(newDietaryItemGet);

    try {
      const response = await this.dietaryService.PostDietaryIntakeItem(postDietaryItem);
      this.utils.showSuccess(
        "DIETARY_INTAKE_SUCCESS",
        "DIETARY_INTAKE_SUCCESS"
      );
      await this.updateItems();
    } catch (err) {
      this.utils.showError("DIETARY_INTAKE_ERROR", "DIETARY_INTAKE_ERROR");
    }
  }

  // Items array updated whenever a new item is added to the food diary
  async updateItems() {
    try {
      const response = await this.dietaryService.GetDietaryDays(this.id);
      this.dietaryDays = response.data;

      for (const meal in this.addedItems) {
        this.addedItems[meal].forEach((items, index) => {
          this.addedItems[meal][index] = [];
        });
      }

      for (const d of this.dietaryDays) {
        d.dietary_intake_items.sort((a, b) => (a.id > b.id ? 1 : -1));
        for (const item of d.dietary_intake_items) {
          const mealCategory = this.getTimeCategory(item.time);
          if (mealCategory && this.addedItems.hasOwnProperty(mealCategory)) {
            this.addedItems[mealCategory][parseInt(d.day_index) - 1].push(item);
          }
        }
      }
    } catch (err) {
      console.log("Unexpected error occured.." + err);
    }
  }

  async submitDietaryIntake() {
    const response = await this.subjectService.SubmitDietary(this.id);
    this.utils.showSuccess(
      "DIETARY_INTAKE_SUBMIT_SUCCESS",
      "DIETARY_INTAKE_SUBMIT_SUCCESS"
    );
    await this.router.navigate(["/account-category/1"]);
  }

  async translateText(key: string): Promise<string> {
    return new Promise<string>((resolve) => {
      this.translate.get(key).subscribe((text: string) => {
        resolve(text);
      });
    });
  }

  getAddedItemsByMeal(meal: string) {
    if (this.addedItems.hasOwnProperty(meal)) {
      return this.addedItems[meal][this.selectedDay];
    } else {
      return [];
    }
  }

  async reopenDietaryIntake() {
    const response = await this.subjectService.ReopenDietary(this.id);
    await this.router.navigate(["/account-category/1"]);
  }

  addItemToDeleteList(item) {
    if (this.itemsToDelete.indexOf(item) === -1) {
      this.itemsToDelete.push(item);
    } else {
      const index = this.itemsToDelete.indexOf(item, 0);
      if (index > -1) {
        this.itemsToDelete.splice(index, 1);
      }
    }
  }

  showDay(day: number) {
    this.selectedDay = day;
    this.showAddItemForm = [false, false, false, false];
  }

  onItemInputChange(event: any, item: any) {
    if (!this.itemsToUpdate.includes(item)) {
      this.itemsToUpdate.push(item);
    }
    item.updatedQuantity = event.target.value;
  }

  onCustomUnitInputChange(event: any, item: any) {
    if (!this.itemsToUpdate.includes(item)) {
      this.itemsToUpdate.push(item);
    }
    item.updatedQuantity = event.target.value * item.food_item.amount;
  }

  onUnitChange(event: any, item: any) {
    if (!this.itemsToUpdate.includes(item)) {
      this.itemsToUpdate.push(item);
    }
    if (event.target.value == 'other') {
      item.custom_unit_type = true;
    } else {
      item.custom_unit_type = false;
      item.updatedUnit = event.target.value;
    }

    // console.log(item.unit);
    // item.updatedUnit = event.target.value;
    // console.log(item.unit);
  }

  async saveChanges(stayInEditMode: boolean) {
    let itemUpdated = false;
    let itemDeleted = false;

    for (const item of this.itemsToUpdate) {
      const updatedItem: IDietaryIntakeItem = {
        dietary_day_id: item.dietary_day_id,
        food_item_id: item.food_item.id,
        quantity: item.updatedQuantity || item.quantity,
        unit: item.updatedUnit || item.unit,
        time: item.time,
        custom_unit_type: item.custom_unit_type
      };

      await this.dietaryService
        .UpdateDietaryIntakeItem(updatedItem, item.id)
        .catch((error) => {
          console.error("Error updating item:", error);
        });
      this.saveChangesPopUp = false;
      itemUpdated = true;
    }

    for (const foodItem of this.itemsToDelete) {
      await this.dietaryService
        .DeleteDietaryIntakeItem(foodItem.id)
        .catch((error) => {
          console.error("Error deleting item:", error);
        });

      for (const meal in this.addedItems) {
        const index = this.addedItems[meal][this.selectedDay].indexOf(foodItem);
        if (index > -1) {
          this.addedItems[meal][this.selectedDay].splice(index, 1);
          break;
        }
      }

      itemDeleted = true;
      this.saveChangesPopUp = false;
    }

    if (itemUpdated) {
      this.utils.showSuccess(
        "SAVE_CHANGES_EDIT_SUCCESS",
        "SAVE_CHANGES_EDIT_SUCCESS"
      );
      this.updateItems();
    }
    if (itemDeleted) {
      this.utils.showSuccess(
        "SAVE_CHANGES_DELETE_SUCCESS",
        "SAVE_CHANGES_DELETE_SUCCESS"
      );
      this.updateItems();
    }

    this.saveChangesPopUp = false;
    this.itemsToUpdate = [];
    this.itemsToDelete = [];
    this.editMode = stayInEditMode;
  }

  cancelChanges() {
    this.itemsToDelete = [];
    this.itemsToUpdate = [];
    this.editMode = false;
  }

  toggleAddItemForm(index: number): void {
    event.stopPropagation();
    for (let i = 0; i < this.showAddItemForm.length; i++) {
      if (i === index) {
        this.showAddItemForm[i] = !this.showAddItemForm[i];
      } else {
        this.showAddItemForm[i] = false;
      }
    }
  }

  handleClickOutside(event: MouseEvent): void {
    const formElement = this.elementRef.nativeElement.querySelector("form");
    let targetElement = event.target as HTMLElement;

    while (targetElement != null) {
      if (targetElement.classList.contains("exclude-close")) {
        return;
      }
      targetElement = targetElement.parentElement;
    }

    if (formElement && !formElement.contains(event.target)) {
      this.closeAddItemForm();
    }
  }

  closeAddItemForm(): void {
    this.showAddItemForm = [false, false, false, false];
  }

  filterItemsByMeal(meal: string, items: any[]) {
    return items.filter((item) => item.time === meal);
  }

  updateTable() {
    this.translate
      .get("FOOD_TRACKER.REFERENCE_TABLE")
      .subscribe((text: string) => {
        this.referenceTable = text;
      });
    this.translate.get("FOOD_TRACKER.ADD_ITEM").subscribe((text: string) => {
      this.btnAddItem = text;
    });
    this.translate
      .get("FOOD_TRACKER.VIEW_FOOD_DIARY")
      .subscribe((text: string) => {
        this.btnViewDiary = text;
      });
    this.translate.get("FOOD_TRACKER.SEARCH_LBL").subscribe((text: string) => {
      this.searchLbl = text;
    });
  }

  async userSubmitItem() {
    console.log('there he is ', this.userAddItem.get('submittedItem').value);

    if (this.userAddItem.get('submittedItem').value === null) {
      this.utils.showWarning('EMPTY_USER_ITEM_WARNING', 'EMPTY_USER_ITEM_WARNING');
    } else {
      const postMissingFoodItem: IMissingFoodItem = {
        title: this.userAddItem.get('submittedItem').value
      };
      try {
        const response = await this.dietaryService.PostMissingFoodItem(postMissingFoodItem);
      } catch {
        console.log('Missing food item post Error');
      }

      this.addItemTextFirst = 'FOOD_TRACKER.CUSTOMER_ITEM_ADDED_TEXT';
      this.addItemButton = 'FOOD_TRACKER.CUSTOMER_ITEM_BUTTON_CLICKED';
      this.addItemTextSecond = "";
      this.userAddItem.get('submittedItem').setValue(null);
    }
  }


  openNewTab() {
    const url = this.router.serializeUrl(
      this.router.createUrlTree([this.app.activeLinks.foodDiaryExample])
    );

    window.open(url, "_blank");
  }

  selectChangeHandler(event: any) {
    this.selectedFoodItem = event.target.value;
  }

  checkDietaryItemEmpty(item: IDietaryIntakeItemV2) {
    if (!item.food_item_id) {
      return true;
    }
    if (!item.dietary_day_id) {
      return true;
    }
    if (!item.unit) {
      return true;
    }
    if (!item.quantity) {
      return true;
    }
    return false;
  }

  toggleDropdown(event: MouseEvent) {
    event.stopPropagation();
    if (this.aClass === "not-active") {
      this.aClass = "active";
    } else {
      this.aClass = "not-active";
    }
  }

  toggleReference(event: MouseEvent) {
    event.stopPropagation();
    this.showReference = !this.showReference;
  }

  activateDropdown() {
    this.aClass = "active";
  }

  chooseItem(selected: IFoodItem) {
    this.selectedFood = selected;
    this.foodForm.get("food_item").setValue(this.selectedFood.title);
    this.unitForSpecificItem =  "MEASUREMENT_TRANSLATIONS." + selected.measurement_type.toUpperCase();
  }

  fuzzyFilterFunction() {
    let input = document.getElementById("food_item") as HTMLInputElement;
    let filter = input.value.toUpperCase();

    const options = {
      includeScore: true,
      minMatchCharLength: 2,
      threshold: 0.2,
      limit: 10,
      keys: ["title"],
      useExtendedSearch: true,
      tokenize: true,
      matchAllTokens: true,
    };
    const fuse = new Fuse(this.foodItems, options);
    const result = fuse.search(filter);

    const sortedResult = result.sort((a, b) => {
      const titleA = a.item.title.toUpperCase();
      const titleB = b.item.title.toUpperCase();
      if (titleA === filter && titleB !== filter) {
        return -1;
      } else if (titleA !== filter && titleB === filter) {
        return 1;
      } else if (titleA === titleB) {
        return a.score - b.score;
      } else {
        return titleA.localeCompare(titleB);
      }
    });

    this.filteredItems = sortedResult.map((item) => item.item);
  }

  private getTimeCategory(time: string): string {
    const timeInMinutes =
      parseInt(time.slice(0, 2)) * 60 + parseInt(time.slice(3));
    if (timeInMinutes >= 360 && timeInMinutes <= 600) {
      return "Breakfast";
    } else if (timeInMinutes >= 720 && timeInMinutes <= 840) {
      return "Lunch";
    } else if (timeInMinutes >= 1020 && timeInMinutes <= 1200) {
      return "Dinner";
    } else {
      return "Snacks";
    }
  }

  getDefaultTimeForMeal(mealCategory: string): string {
    switch (mealCategory) {
      case "Breakfast":
        return "07:00";
      case "Lunch":
        return "12:00";
      case "Dinner":
        return "20:00";
      case "Snacks":
        return "15:00";
      default:
        return "00:00";
    }
  }

  getCustomUnitValue(item: IDietaryIntakeItemGet) {
    if (item.custom_unit_type) {
      return  Math.round((item.quantity / item.food_item.amount)*100)/100;
    } else {
      return item.quantity;
    }
  }

  getItemUnit(item: IDietaryIntakeItemGet) {
    if (item.custom_unit_type) {
      return 'other';
    } else {
      console.log(item.unit + ' ' + item.food_item.title);
      return item.unit;
    }
  }

  openDiarySheetPDF() {
    let partnerAddon = '';
    if (this.app.partnerVersion != '') {
      partnerAddon = '_' + this.app.partnerVersion;
    }

    if (this.translate.currentLang === 'nl') {
      window.open(String(this.nlUrl) + partnerAddon + '.pdf', '_blank');
    } else if (this.translate.currentLang === 'it') {
      window.open(String(this.itUrl) + partnerAddon + '.pdf', '_blank');
    } else if (this.translate.currentLang === 'sk') {
      window.open(String(this.skUrl) + partnerAddon + '.pdf', '_blank');
    } else if (this.translate.currentLang === 'de') {
      window.open(String(this.deUrl) + partnerAddon + '.pdf', '_blank');
    } else {
      window.open(String(this.enUrl) + partnerAddon + '.pdf', '_blank');
    }
  }

  getRoundedUpNumber(amount: number) {
    return Math.round(amount*100)/100;
  }
}
