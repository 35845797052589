import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { IDashboardDietary, IEyyo } from "../../../interfaces/advice";
import bacteriaSubCategories from "../../assets/json/bacteriaSubCategories.json";
import { DietaryDashService } from "../api/calculations/dietarydash";
import { AppComponent } from "../app.component";

@Component({
  selector: "app-gut-health",
  templateUrl: "./gut-health.component.html",
  styleUrls: ["./gut-health.component.css"],
})
export class GutHealthComponent implements OnInit {
  constructor(
    public dietaryDashService: DietaryDashService,
    private route: ActivatedRoute,
    public app: AppComponent,
    private translate: TranslateService
  ) {}

  resultText = "";
  dietaryAdviceText = "";
  introTableText = "";
  gutHealth;
  range;
  dashBacteria: IEyyo;
  dietaryAdvice: IDashboardDietary[];
  foodCategories: String[] = [];
  selectedFoodCategory: String;
  pageTitle: string;
  textString: string;
  selectedBacteria: string;
  bacteriaCategories = bacteriaSubCategories;
  lowRangeBacteria: number = 0;
  avgRangeBacteria: number = 0;
  highRangeBacteria: number = 0;
  overallResult: string;
  flipGraphColorItems: string[] = [
    "POTENTIAL_COLON_PROBLEMS_NEW",
    "INFECTION_ALARM_NEW",
    "FAT_ALARM_NEW",
  ];
  gutChallengesGroup: string[] = [
    "FAT_ALARM_NEW",
    "INFECTION_ALARM_NEW",
    "POTENTIAL_COLON_PROBLEMS_NEW",
  ];
  encouragedFoods: object[] = [];
  encouragedFoodCategories: string[] = [];
  limitedFoods: object[] = [];
  limitedFoodsCategories: string[] = [];
  selectedLanguage: string = localStorage.getItem("language");

  ibsTagsIds: string[] = ["128", "152", "352", "540", "671"];
  glutenTagsIds: string[] = ["150", "151", "369", "370", "561", "562", "645"];
  lactoseTagsIds: string[] = ["153", "154", "371", "372", "563", "564", "639"];
  histamineTagsIds: string[] = ["633"]

  hasIbsCondition: boolean = false;
  hasGlutenCondition: boolean = false;
  hasLactoseCondition: boolean = false;
  hasHistamineCondition: boolean = false;

  showIbsTag: boolean = false;
  showGlutenTag: boolean = false;
  showLactoseTag: boolean = false;
  showHistamineTag: boolean = false;

  conditions: any;

  partnerImgSrc = "";

  async ngOnInit() {
    if (this.app.partnerVersion != "") {
      this.partnerImgSrc = "-" + this.app.partnerVersion;
    }

    this.dashBacteria = JSON.parse(sessionStorage.getItem("Dashboard"));

    const testKitID = Number(this.route.params["value"].id);
    let tempTitle = this.route.params["value"].page.replaceAll("-", " ");
    this.pageTitle = tempTitle.charAt(0).toUpperCase() + tempTitle.slice(1);

    this.textString =
      // "SUB_CATEGORIES." + this.pageTitle.replaceAll(" ", "_").toUpperCase();
      "SUB_CATEGORIES." + this.pageTitle.split(" ").join("_").toUpperCase();

    this.selectedBacteria = this.pageTitle.split(" ").join("_").toUpperCase();

    this.dietaryAdvice = await (
      await this.dietaryDashService.GetDietaryAdvice(testKitID, this.pageTitle)
    ).data;

    this.getMedicalConditions();

    if (this.gutChallengesGroup.includes(this.selectedBacteria)) {
      this.splitGutChallengeFoods();
    }

    this.getOverallResult();
    this.splitFoodItemCategories();

    // this.setText();
  }

  async getMedicalConditions() {
    this.conditions = await this.dietaryDashService.GetMedicalCondition(
      Number(this.route.params["value"].id)
    );

    this.conditions.data.forEach((element) => {
      if (this.ibsTagsIds.includes(element.answerID.toString())) {
        this.hasIbsCondition = true;
      }
      if (this.glutenTagsIds.includes(element.answerID.toString())) {
        this.hasGlutenCondition = true;
      }
      if (this.lactoseTagsIds.includes(element.answerID.toString())) {
        this.hasLactoseCondition = true;
      }
      if (this.histamineTagsIds.includes(element.answerID.toString())) {
        this.hasHistamineCondition = true;
      }
    });
  }

  getMedicalTagImage(type, values) {
    let tempArray = [];
    if (Object.values(values).length > 0) {
      Object.values(values).forEach((element) => {
        tempArray.push(element);
      });

      switch (type) {
        case 1:
          return tempArray.some((element) => this.ibsTagsIds.includes(element));
        case 2:
          return tempArray.some((element) =>
            this.glutenTagsIds.includes(element)
          );
        case 3:
          return tempArray.some((element) =>
            this.lactoseTagsIds.includes(element)
          );
        case 4:
          return tempArray.some((element) =>
            this.histamineTagsIds.includes(element)
          );
      }
    }
  }

  selectFoodCategory(category) {
    if (this.selectedFoodCategory == category) {
      this.selectedFoodCategory = "";
      return;
    }
    this.selectedFoodCategory = category;
  }

  splitFoodItemCategories() {
    this.dietaryAdvice.forEach((elem) => {
      if (!this.foodCategories.includes(elem.food_item.food_group_title)) {
        this.foodCategories.push(elem.food_item.food_group_title);
      }

      if (
        elem.food_item["medical_tags"] != null &&
        elem.food_item["medical_tags"] != ""
      ) {
        let values = elem.food_item["medical_tags"].split(",");

        let tempArray = [];
        if (Object.values(values).length > 0) {
          Object.values(values).forEach((element) => {
            tempArray.push(element);
          });
        }

        if (tempArray.some((element) => this.ibsTagsIds.includes(element))) {
          this.showIbsTag = true;
        }
        if (tempArray.some((element) => this.glutenTagsIds.includes(element))) {
          this.showGlutenTag = true;
        }
        if (
          tempArray.some((element) => this.lactoseTagsIds.includes(element))
        ) {
          this.showLactoseTag = true;
        }
        if (
          tempArray.some((element) => this.histamineTagsIds.includes(element))
        ) {
          this.showHistamineTag = true;
        }
      }
    });
    this.foodCategories.sort();
  }

  splitGutChallengeFoods() {
    this.dietaryAdvice.forEach((elem) => {
      if (elem.is_positive) {
        if (!this.encouragedFoods.includes(elem)) {
          this.encouragedFoods.push(elem);
        }
        if (
          !this.encouragedFoodCategories.includes(
            elem.food_item.food_group_title
          )
        ) {
          this.encouragedFoodCategories.push(elem.food_item.food_group_title);
        }
      }

      if (!elem.is_positive) {
        if (!this.limitedFoods.includes(elem)) {
          this.limitedFoods.push(elem);
        }
        if (
          !this.limitedFoodsCategories.includes(elem.food_item.food_group_title)
        ) {
          this.limitedFoodsCategories.push(elem.food_item.food_group_title);
        }
      }
      if (
        elem.food_item["medical_tags"] != null &&
        elem.food_item["medical_tags"] != ""
      ) {
        let values = elem.food_item["medical_tags"].split(",");
        let tempArray = [];
        if (Object.values(values).length > 0) {
          Object.values(values).forEach((element) => {
            tempArray.push(element);
          });
        }

        if (tempArray.some((element) => this.ibsTagsIds.includes(element))) {
          this.showIbsTag = true;
        }
        if (tempArray.some((element) => this.glutenTagsIds.includes(element))) {
          this.showGlutenTag = true;
        }
        if (
          tempArray.some((element) => this.lactoseTagsIds.includes(element))
        ) {
          this.showLactoseTag = true;
        }
        if (
          tempArray.some((element) => this.histamineTagsIds.includes(element))
        ) {
          this.showHistamineTag = true;
        }
      }
    });
    this.encouragedFoodCategories.sort();
    this.limitedFoodsCategories.sort();

    console.log(this.encouragedFoodCategories);
  }

  getBacteriaLevel(bacteria) {
    if (typeof bacteria == "string") {
      let temp = this.dashBacteria.top35.filter(
        (n) => n.bacteria == bacteria
      )[0];
      if (temp) {
        // @ts-ignore: Object is possibly 'null'.
        const dif = ((temp.range[1] - temp.range[0]) / 70) * 15;
        // @ts-ignore: Object is possibly 'null'.
        const refUp = temp.range[1] + dif; // potential for gas production
        // @ts-ignore: Object is possibly 'null'.
        const refLow = temp.range[0] - dif; // potential for gas production
        // @ts-ignore: Object is possibly 'null'.
        let cal = ((temp.value - refLow) / (refUp - refLow)) * 100;
        if (cal < 2) {
          cal = 2;
        }
        if (cal > 98) {
          cal = 98;
        }
        return "calc(" + cal + "% - 15px)";
      }
    }
  }

  resultSampleText() {
    if (this.gutHealth < this.range[0]) {
      this.createResultString("GUT_HEALTH.BE_AWARE_RESULT");
      return;
    }
    if (this.gutHealth > this.range[1]) {
      this.createResultString("GUT_HEALTH.GREAT_RESULT");
      return;
    }
    this.createResultString("GUT_HEALTH.NORMAL_RESULT");
  }

  getOverallResult() {
    this.bacteriaCategories[this.selectedBacteria].forEach((element) => {
      let temp = this.dashBacteria.top35.filter(
        (n) => n.bacteria == element
      )[0];
      if (temp.status == "low") {
        this.lowRangeBacteria++;
      }
      if (temp.status == "avg") {
        this.avgRangeBacteria++;
      }
      if (temp.status == "high") {
        this.highRangeBacteria++;
      }
    });
  
    // Handle the case where only low bacteria are present
    if (this.lowRangeBacteria > 0 && this.avgRangeBacteria == 0 && this.highRangeBacteria == 0) {
      this.overallResult = "LOW_TEXT";
      return;
    }
  
    // Handle the case where only high bacteria are present
    if (this.highRangeBacteria > 0 && this.avgRangeBacteria == 0 && this.lowRangeBacteria == 0) {
      this.overallResult = "HIGH_TEXT";
      return;
    }
  
    // Handle the case where only avg bacteria are present
    if (this.avgRangeBacteria > 0 && this.lowRangeBacteria == 0 && this.highRangeBacteria == 0) {
      this.overallResult = "AVG_TEXT";
      return;
    }
  
    // Case where high and average bacteria are equal, but different from low
    if (
      this.highRangeBacteria == this.avgRangeBacteria &&
      this.highRangeBacteria != this.lowRangeBacteria
    ) {
      this.overallResult = "HIGH_NORMAL_TEXT";
      if (
        !this.flipGraphColorItems.includes(this.selectedBacteria) &&
        this.selectedBacteria != "FIBRE_DEGRADATION"
      ) {
        return;
      }
    }
  
    // Case where high-range bacteria dominate
    if (
      this.highRangeBacteria > this.avgRangeBacteria &&
      this.highRangeBacteria > this.lowRangeBacteria
    ) {
      this.overallResult = "HIGH_TEXT";
    } else if (
      this.lowRangeBacteria >= this.highRangeBacteria &&
      this.lowRangeBacteria >= this.avgRangeBacteria
    ) {
      this.overallResult = "LOW_TEXT";
    } else {
      this.overallResult = "AVG_TEXT";
    }
  
    // Gut challenges
    if (this.flipGraphColorItems.includes(this.selectedBacteria)) {
      if (this.lowRangeBacteria == this.avgRangeBacteria) {
        if (this.lowRangeBacteria > 0 && this.avgRangeBacteria > 0) {
          this.overallResult = "HIGH_NORMAL_TEXT";
          return;
        }
      }
      if (
        this.highRangeBacteria >= this.avgRangeBacteria &&
        this.highRangeBacteria >= this.lowRangeBacteria
      ) {
        this.overallResult = "HIGH_TEXT";
        return;
      }
    }
  
    // Fibre degradation case
    if (this.selectedBacteria == "FIBRE_DEGRADATION") {
      if (this.highRangeBacteria == this.lowRangeBacteria) {
        if (this.highRangeBacteria > 0 && this.lowRangeBacteria > 0) {
          this.overallResult = "HIGH_NORMAL_TEXT";
          return;
        }
      }
      if (this.avgRangeBacteria == this.lowRangeBacteria) {
        if (this.avgRangeBacteria > 0 && this.lowRangeBacteria > 0) {
          this.overallResult = "LOW_TEXT";
          return;
        }
      }
      if (this.avgRangeBacteria == this.highRangeBacteria) {
        if (this.highRangeBacteria > 0 && this.avgRangeBacteria > 0) {
          this.overallResult = "HIGH_TEXT";
          return;
        }
      }
    }
  }
  
  createResultString(tag: string) {
    this.translate.get(tag).subscribe((text: string) => {
      this.resultText = text;
    });
  }

  setText() {
    this.resultSampleText();
    this.translate
      .get("GUT_HEALTH.DIETARY_ADVICE_TEXT")
      .subscribe((text: string) => {
        this.dietaryAdviceText = text;
      });
    this.translate
      .get("GUT_HEALTH.INTRO_TABLE_TEXT")
      .subscribe((text: string) => {
        this.introTableText = text;
      });
  }

  getFoodCategoryText(category: string) {
    console.log(category);
    if (
      [
        "Plant-based alternatives",
        "Plantaardige alternatieven",
        "Alternative a base vegetale",
        "Pflanzliche Alternativen",
        "Rastlinné alternatívy",
        "Alternatives végétales",
      ].includes(category)
    ) {
      return "FOOD_GROUP_DESCRIPTIONS.PLANT_BASED_ALTERNATIVES";
    } else if (
      [
        "Vegetables",
        "Groenten",
        "Verdure",
        "Gemüse",
        "Zelenina",
        "Légumes",
      ].includes(category)
    ) {
      return "FOOD_GROUP_DESCRIPTIONS.VEGETABLES";
    } else if (
      [
        "Beverages",
        "Dranken",
        "Bevande",
        "Getränke",
        "Nápoje",
        "Breuvages",
      ].includes(category)
    ) {
      return "FOOD_GROUP_DESCRIPTIONS.BEVERAGES";
    } else if (
      [
        "Cereals",
        "Granen",
        "Cereali",
        "Getreide",
        "Obilniny",
        "Céréales",
      ].includes(category)
    ) {
      return "FOOD_GROUP_DESCRIPTIONS.CEREALS";
    } else if (
      [
        "Condiments and seasonings",
        "Vetten en smaakmakers",
        "Condimenti e aromi",
        "Gewürze und Gewürze",
        "Koreniny, dochucovadlá a oleje",
        "Condiments et assaisonnements",
      ].includes(category)
    ) {
      return "FOOD_GROUP_DESCRIPTIONS.CONDIMENTS_AND_SEASONINGS";
    } else if (
      [
        "Dairy",
        "Zuivel",
        "Latticini",
        "Molkerei",
        "Mliečne výrobky",
        "Laitier",
      ].includes(category)
    ) {
      return "FOOD_GROUP_DESCRIPTIONS.DAIRY";
    } else if (
      ["Fish", "Vis", "Pesce", "Fisch", "Ryba", "Poisson"].includes(category)
    ) {
      return "FOOD_GROUP_DESCRIPTIONS.FISH";
    } else if (
      ["Fruits", "Fruit", "Frutta", "Früchte", "Ovocie", "Des fruits"].includes(
        category
      )
    ) {
      return "FOOD_GROUP_DESCRIPTIONS.FRUITS";
    } else if (
      [
        "Legumes",
        "Peulvruchten",
        "Legumi",
        "Hülsenfrüchte",
        "Strukoviny",
        "Les légumineuses",
      ].includes(category)
    ) {
      return "FOOD_GROUP_DESCRIPTIONS.LEGUMES";
    } else if (
      [
        "Nuts and seeds",
        "Noten en zaden",
        "Frutta secca e semi",
        "Nüsse und Samen",
        "Orechy a semená",
        "Noix et graines",
      ].includes(category)
    ) {
      return "FOOD_GROUP_DESCRIPTIONS.NUTS_AND_SEEDS";
    } else if (
      [
        "Sweets, confectionery and pastries",
        "Snoep, zoetwaren en gebak",
        "Dolci, confetteria e pasticceria",
        "Süßigkeiten, Süßwaren und Gebäck",
        "Sladkosti, cukrovinky a pečivo",
        "Bonbons, confiseries et pâtisseries",
      ].includes(category)
    ) {
      return "FOOD_GROUP_DESCRIPTIONS.SWEETS_CONFECTIONERY_AND_PASTRIES";
    } else if (
      [
        "White meat",
        "Wit vlees",
        "Carne bianca",
        "Weisses Fleisch",
        "Biele mäso",
        "viande blanche",
      ].includes(category)
    ) {
      return "FOOD_GROUP_DESCRIPTIONS.WHITE_MEAT";
    } else {
      return "FOOD_GROUP_DESCRIPTIONS.EMPTY";
    }
  }
}
