import axios from 'axios';
import {Injectable} from '@angular/core';
import {IAddress, IOrder} from '../../../../interfaces/orders';
import {environment} from '../../../environments/environment';

axios.defaults.baseURL = environment.url.api;

function authHeaders() {
  const token = localStorage.getItem('token');
  return {
    headers: {
      Authorization: `Bearer ${token}`
    }
  };
}

@Injectable()
export class OrdersService {
  constructor() {
  }

  async GetOrders() {
    return axios.get<IOrder[]>(`/api/v1/orders`, authHeaders());
  }

  async UpdateAddress(data: IAddress) {
    return axios.post<IAddress>(`/api/v1/address`, data, authHeaders());
  }

  async PlaceOrder(data: IOrder, userId: string) {
    return axios.post<string>(`/api/v1/orders/${userId}`, data, authHeaders());
  }

  async PlaceOrderTest(data: IOrder, userId: string) {
    return axios.post<string>(`/api/v1/orders/test/${userId}`, data, authHeaders());
  }
}
