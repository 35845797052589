import {Component, OnInit} from '@angular/core';
import {Chart} from 'chart.js';
import {TranslateService} from "@ngx-translate/core";
import {DashboardComponent} from "../dashboard/dashboard.component";
import {DashboardService} from "../api/calculations/dashboard";
import {ActivatedRoute} from "@angular/router";
import { AppComponent } from '../app.component';

@Component({
  selector: 'app-bmi',
  templateUrl: './bmi.component.html',
  styleUrls: ['./bmi.component.css']
})
export class BmiComponent implements OnInit {

  constructor(
    private dashboardService: DashboardService, 
    private route: ActivatedRoute, 
    public app: AppComponent,
    ) {
  }
  bmiValue: any;
  testKitID;

  partnerImgSrc = '';

  async ngOnInit() {
    if (this.app.partnerVersion != ''){
      this.partnerImgSrc = '-' + this.app.partnerVersion;
    }
    this.testKitID = Number(this.route.snapshot.paramMap.get('id'));
    this.bmiValue = (await this.dashboardService.GetBmi(this.testKitID)).data;
  }

  getBMILevel() {
    if (!this.bmiValue) {
      this.bmiValue = 'Undefined'
    }

    let value = 30;
    if (this.bmiValue < 18.5) {
      value = 10;
    }
    if (this.bmiValue > 18.5 && this.bmiValue < 25) {
      value = 30;
    }
    if (this.bmiValue > 25 && this.bmiValue < 30) {
      value = 50;
    }
    if (this.bmiValue > 30 && this.bmiValue < 35) {
      value = 70;
    }
    if (this.bmiValue > 35) {
      value = 90;
    }

    // Account for inbetween  values
    if (this.bmiValue == 25) {value = 40;}
    if (this.bmiValue == 30) {value = 60;}
    if (this.bmiValue == 35) {value = 80;}

    return 'calc(' + value + '% - 15px)';
  }
}
