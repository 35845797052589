import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import {IDashboardDietary, IEyyo} from "../../../interfaces/advice";
import {DietaryDashService} from "../api/calculations/dietarydash";
import {ActivatedRoute} from "@angular/router";
import {LangChangeEvent, TranslateService} from "@ngx-translate/core";

@Component({
  selector: 'app-gas-production',
  templateUrl: './gas-production.component.html',
  styleUrls: ['./gas-production.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class GasProductionComponent implements OnInit {

  constructor(public dietaryDashService: DietaryDashService, private route: ActivatedRoute, private translate: TranslateService) {
  }

  resultText = '';
  tableOne = '';
  tableTwo = '';
  poepikaki;
  range;
  dashBacteria: IEyyo;
  dietaryAdvice: IDashboardDietary[];

  async ngOnInit() {
    this.updateTable();
    this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      this.updateTable();
      this.resultSampleText();
    });
    this.dashBacteria = JSON.parse(sessionStorage.getItem('Dashboard'));
    this.poepikaki = this.dashBacteria.graphs.find(x => x.graph === 'Gas Production').value;
    this.range = this.dashBacteria.graphs.find(x => x.graph === 'Gas Production').range;
    console.log(this.range);
    const testKitID = Number(this.route.snapshot.paramMap.get('id'));
    this.dietaryAdvice = await (await this.dietaryDashService.GetDietaryAdvice(testKitID, 'Gas production possibilities')).data;
    this.resultSampleText();
  }

  getBacteriaLevel() {
    const dif = (this.range[1] - this.range[0]) / 70 * 15;
    const refUp = this.range[1] + dif; // potential for gas production
    const refLow = this.range[0] - dif; // potential for gas production
    console.log('value:');
    console.log(this.poepikaki);
    let cal = (this.poepikaki - refLow) / (refUp - refLow) * 100;
    if (cal < 0) {
      cal = 0;
    }
    if (cal > 100) {
      cal = 100;
    }
    return 'calc(' + cal + '% - 15px)';
  }

  resultSampleText() {
    if (this.poepikaki < this.range[0]) {
      this.createResultString('GAS_PRODUCTION_POSSIBILITIES.BE_AWARE_RESULT');
      return;
    }
    if (this.poepikaki > this.range[1]) {
      this.createResultString('GAS_PRODUCTION_POSSIBILITIES.GREAT_RESULT');
      return;
    }
    this.createResultString('GAS_PRODUCTION_POSSIBILITIES.NORMAL_RESULT');
  }

  createResultString(tag: string) {
    this.translate.get(tag).subscribe((text: string) => {
      this.resultText = text;
    });
  }

  updateTable() {
    this.translate.get('GAS_PRODUCTION_POSSIBILITIES.GAS_PRODUCTION_TABLE').subscribe((text: string) => {
      this.tableOne = text;
    });
    this.translate.get('GAS_PRODUCTION_POSSIBILITIES.WEEKLY_TABLE_HTML').subscribe((text: string) => {
      this.tableTwo = text;
    });
  }

}
