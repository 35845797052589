import { Component, OnInit } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { AuthenticationService } from "../api/login/authentication";
import { UtilityService } from "../utility.service";
import { TranslateService } from "@ngx-translate/core";
import { UserService } from "../api/users/users";
import { AppComponent } from "../app.component";

@Component({
  selector: "app-test-results",
  templateUrl: "./test-results.component.html",
  styleUrls: ["./test-results.component.css"],
})
export class TestResultsComponent implements OnInit {
  constructor(
    private authenticationService: AuthenticationService,
    private userService: UserService,
    private router: Router,
    private route: ActivatedRoute,
    private utils: UtilityService,
    private translate: TranslateService,
    public app: AppComponent
  ) {}

  loginForm: FormGroup;
  emailPlaceHolder = "Enter email";
  passwordPlaceHolder = "Enter password";
  selectedLanguage: string;
  appPartner: string = "INNERBUDDIES";
  passwordRecovery = this.app.activeLinks.passwordRecovery;

  async ngOnInit() {
    if (this.app.partnerVersion != "") {
      this.appPartner = this.app.partnerVersion.toUpperCase();
    }
    this.loginForm = new FormGroup({
      email: new FormControl(null, [Validators.required]),
      password: new FormControl(null, [Validators.required]),
    });

    if (this.route.snapshot.paramMap.get("token")) {
      const token = this.route.snapshot.paramMap.get("token");
      const response = await this.authenticationService.activateAccount(token);
      console.log(response.data);
      if (response.data === "Account activated successfully") {
        this.utils.showSuccess("ACTIVATION_SUCCESS", "ACTIVATION_SUCCESS");
      }
    }
    this.translate.get("ACCOUNT_CREATION.EMAIL").subscribe((text: string) => {
      this.emailPlaceHolder = text;
    });
    this.translate
      .get("ACCOUNT_CREATION.PASSWORD")
      .subscribe((text: string) => {
        this.passwordPlaceHolder = text;
      });
  }

  getLink() {
    return this.app.activeLinks.passwordRecovery;
  }

  async login() {
    let isAccountActivated = null;
    await this.userService
      .IsAccountActive(this.loginForm.get("email").value.toLowerCase())
      .then((data) => (isAccountActivated = data.data));
    if (!isAccountActivated) {
      // this.utils.showWarning('ACCOUNT_NOT_ACTIVATED_WARNING', 'ACCOUNT_NOT_ACTIVATED_WARNING');
      return;
    }

    try {
      const response = await this.authenticationService.logInGetToken(
        this.loginForm.get("email").value,
        this.loginForm.get("password").value
      );
      const token = response.data.access_token;
      if (token) {
        localStorage.setItem("token", token);

        // Save token to cookies (optional, for server-side validation)
        document.cookie = `token=${token}; path=/; secure; SameSite=Strict;`;
        this.router.navigate([this.app.activeLinks.results]);
        this.utils.showSuccess("LOGIN_SUCCESS", "LOGIN_SUCCESS");
        this.app.hideLogin();
        await this.app.changeLanguage(false);
      } else {
        console.log("No token returned");
      }
    } catch {
      this.utils.showWarning("LOGIN_WARNING", "LOGIN_WARNING");
    }
  }
}
