import axios from 'axios';
import { Injectable } from '@angular/core';
import { IUserProfile, IUserProfileCreate, IUserProfileUpdate } from 'interfaces';
import {environment} from '../../../environments/environment';

axios.defaults.baseURL = environment.url.api;

function authHeaders() {
    const token = localStorage.getItem('token');
    return {
        headers: {
            Authorization: `Bearer ${token}`
        }
    };
}

@Injectable()
export class UserService {
    constructor() {}
    async createAccount(data: IUserProfileCreate, language: string, design: string) {
      if (design == '') {
        design = 'default';
      }
        return axios.post<IUserProfile>(`/api/v1/users/open?design=${design}`, {
          user_in: data,
          language
        });
    }

    async GetUser() {
        return axios.get<IUserProfile>(`/api/v1/users/me`, authHeaders());
    }

    async UpdateUser(data: IUserProfileUpdate) {
        return axios.put<IUserProfile>(`/api/v1/users/me`, data, authHeaders());
    }

    async UpdateUserLanguage(data: number) {
        return axios.get<boolean>(`/api/v1/language_code/user/${data}`, authHeaders());
    }

    async IsEmailTaken(data: string) {
        return axios.get<boolean>(`api/v1/users/check-email/${data}`, authHeaders());
    }

    async IsPhoneTaken(data: string) {
        return axios.get<boolean>(`api/v1/users/check-phone-number/${data}`, authHeaders());
    }

    async IsAccountActive(data: string) {
      return axios.get<boolean>(`api/v1/users/check-account-activated/${data}`, authHeaders());
    }

    async UnsubscribeFromEmailList(email: string) {
        let response = axios.put<any>(`/api/v1/users/unsubscribe_email/${email}`)
        .catch(function (error) {
            if (error.response) {
              if (error.response.status == 404) {
                return error.response.status
              }
            } else {
              console.log('Error', error.message);
              return 400
            }
          });
        
        return response;
    }

}
