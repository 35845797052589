// import { Component, OnInit } from "@angular/core";
// import { TranslateService } from "@ngx-translate/core";
// import { Chart } from "chart.js";
// import "chartjs-chart-box-and-violin-plot/build/Chart.BoxPlot.js";
// import "chartjs-gauge";
// import { Console } from "console";
// import { IEyyo } from "../../../interfaces/advice";
// import { AppComponent } from "../app.component";

// @Component({
//   selector: "app-bacteria-diversity",
//   templateUrl: "./bacteria-diversity.component.html",
//   styleUrls: ["./bacteria-diversity.component.css"],
// })
// export class BacteriaDiversityComponent implements OnInit {
//   constructor(private translate: TranslateService, public app: AppComponent) {}

//   dashBacteria: IEyyo;
//   doughnutChart: any;
//   doughnutChart1: any;
//   doughnutChart2: any;
//   doughnutChart3: any;
//   bacteriaLevels;
//   diversityValue: number;
//   shannonValue: any;
//   shannonMoreInfo: Boolean;
//   shannonConf: Object;
//   otuValue: any;
//   otuMoreInfo: Boolean;
//   otuConf: Object;
//   pielouValue: any;
//   pielouMoreInfo: Boolean;
//   pielouConf: Object;

//   gaugeColorVariants = {
//     default: {
//       normal: "#ad66d9",
//       okay: "#cfbcf2",
//       great: "#05e399",
//       bad: "#fad408",
//       aware: "#c8bfe7",
//     },
//     iprobio: {
//       normal: "#62b3b3",
//       okay: "#b5d2d2",
//       great: "#6fd06f",
//       bad: "#f299a1",
//       aware: "#339d9c69",
//     },
//     patris_health: {
//       normal: "#33ab9f",
//       okay: "#81c784",
//       great: "#66bb6a",
//       bad: "#f44336",
//       aware: "#b2dfdb",
//     },
//   };
//   selectedColors = this.gaugeColorVariants.default;
//   explanationText = "BACTERIA_DIVERSITY.EXPLANATION_NEW";

//   ngOnInit() {
//     if (this.app.partnerVersion !== "") {
//       this.selectedColors = this.gaugeColorVariants[this.app.partnerVersion];
//     }
//     // this.explanationText =
//     //   "BACTERIA_DIVERSITY.EXPLANATION_NEW_" +
//     //   this.app.partnerVersion.toUpperCase();
//     this.dashBacteria = JSON.parse(sessionStorage.getItem("Dashboard"));
//     this.bacteriaLevels = this.dashBacteria.graphs.find(
//       (x) => x.graph === "Bacteria Levels"
//     ).value;

//     // this.shannonValue = this.limitValue(
//     //   parseFloat(
//     //     this.dashBacteria.graphs.find((x) => x.graph === "Shannon").value
//     //   )
//     // );
//     this.shannonValue = parseFloat(
//       this.dashBacteria.graphs.find((x) => x.graph === "Shannon_v2").value
//     );

//     this.otuValue = this.limitValue(
//       parseFloat(this.dashBacteria.graphs.find((x) => x.graph === "OTUs").value)
//     );

//     this.pielouValue = this.limitValue(
//       parseFloat(
//         this.dashBacteria.graphs.find((x) => x.graph === "Evenness").value
//       )
//     );
//     this.diversityValue = Number(
//       this.dashBacteria.graphs.find((x) => x.graph === "Bacteria Diversity")
//         .value
//     );

//     const canvasDiversity = document.getElementById(
//       "shannon"
//     ) as HTMLCanvasElement;

//     const canvasDiversity1 = document.getElementById(
//       "otus"
//     ) as HTMLCanvasElement;

//     const canvasDiversity2 = document.getElementById(
//       "bacteriaDiversity2"
//     ) as HTMLCanvasElement;

//     const canvasDiversity3 = document.getElementById(
//       "pielou"
//     ) as HTMLCanvasElement;

//     const shannon = canvasDiversity.getContext("2d");
//     const otus = canvasDiversity1.getContext("2d");
//     // const ctxDiversity2 = canvasDiversity2.getContext("2d");
//     const pielou = canvasDiversity3.getContext("2d");
//     // var ctx2 = document.getElementById("bacteriaDiversity");
//     // const ref = [32, 147]; // Bacteria diversity
//     // const upperRange = 3;
//     // //let diversityScore = ((this.diversityValue - ref[0]) * 70) / (ref[1] - ref[0]) + 15;
//     // let diversityScore = (this.diversityValue / upperRange) * 100;
//     // if (diversityScore < 0) {
//     //   diversityScore = 0;
//     // }
//     // if (diversityScore > 100) {
//     //   diversityScore = 100;
//     // }
//     console.log("bla");
//     console.log(this.shannonValue);

//     this.doughnutChart = new Chart(
//       shannon,
//       this.getGraphConf(this.shannonValue, true)
//     );

//     this.doughnutChart1 = new Chart(
//       otus,
//       this.getGraphConf(this.getDiversityScore(this.otuValue), false)
//     );

//     // this.doughnutChart2 = new Chart(ctxDiversity2, conf);
//     this.doughnutChart3 = new Chart(
//       pielou,
//       this.getGraphConf(this.getDiversityScore(this.pielouValue), false)
//     );
//   }
//   limitValue(value: number): number {
//     if (value < -3) return -3;
//     if (value > 3) return 3;
//     return value;
//   }

//   toggleGraphDescription(param) {
//     switch (param) {
//       case 1:
//         this.shannonMoreInfo = !this.shannonMoreInfo;
//         this.otuMoreInfo = false;
//         this.pielouMoreInfo = false;
//         break;
//       case 2:
//         this.shannonMoreInfo = false;
//         this.pielouMoreInfo = false;
//         this.otuMoreInfo = !this.otuMoreInfo;
//         break;
//       case 3:
//         this.shannonMoreInfo = false;
//         this.otuMoreInfo = false;
//         this.pielouMoreInfo = !this.pielouMoreInfo;
//         break;
//       default:
//         break;
//     }
//   }

//   getDiversityScore(value) {
//     const upperRange = 6;
//     //let diversityScore = ((this.diversityValue - ref[0]) * 70) / (ref[1] - ref[0]) + 15;
//     let diversityScore = ((value + 3) / upperRange) * 100;
//     if (diversityScore < 0) {
//       diversityScore = 0;
//     }
//     if (diversityScore > 100) {
//       diversityScore = 100;
//     }

//     let min_value = -3;
//     let max_value = 3;
//     let value_normalized = (value - min_value) / (max_value - min_value);

//     return value_normalized * 100;
//   }

//   // getGraphConf(graphValue) {
//   //   return {
//   //     type: "gauge",
//   //     data: {
//   //       datasets: [
//   //         {
//   //           data: [15, 85, 100],
//   //           value: graphValue,
//   //           backgroundColor: [
//   //             this.selectedColors["bad"],
//   //             this.selectedColors["normal"],
//   //             this.selectedColors["great"],
//   //           ],
//   //           hoverBackgroundColor: [
//   //             this.selectedColors["bad"],
//   //             this.selectedColors["normal"],
//   //             this.selectedColors["great"],
//   //           ],
//   //           borderWidth: 2,
//   //         },
//   //       ],
//   //     },
//   //     options: {
//   //       interaction: {
//   //         mode: "point",
//   //       },
//   //       responsive: true,
//   //       title: {
//   //         display: false,
//   //         text: "Gauge chart",
//   //       },
//   //       layout: {
//   //         padding: {
//   //           bottom: 10,
//   //         },
//   //       },
//   //       needle: {
//   //         // Needle circle radius as the percentage of the chart area width
//   //         radiusPercentage: 2,
//   //         // Needle width as the percentage of the chart area width
//   //         widthPercentage: 3.2,
//   //         // Needle length as the percentage of the interval between inner radius (0%) and outer radius (100%) of the arc
//   //         lengthPercentage: 80,
//   //         // The color of the needle
//   //         color: "rgba(0, 0, 0, 1)",
//   //       },
//   //       valueLabel: {
//   //         display: false,
//   //         formatter: Math.round,
//   //       },
//   //     },
//   //   };
//   // }
//   getGraphConf(graphValue: number, isShannon: boolean) {
//     if (isShannon) {
//       // Normalize Shannon value to a range of 0–1
//       const normalizedValue = (graphValue - 2) / (6 - 2);

//       // Define thresholds for the specified ranges
//       const thresholds = [
//         (3 - 2) / (6 - 2), // Normalized threshold for 3
//         (4 - 2) / (6 - 2), // Normalized threshold for 4
//         1, // Normalized threshold for 6
//       ] // [0.25, 0.625, 1]
//       const backgroundColors = [
//         this.selectedColors["bad"], // Yellow for 2–3
//         this.selectedColors["normal"], // Purple for 3–4
//         this.selectedColors["great"], // Green for 4.5–6
//       ];

//       // Clamp normalized value within [0, 1]
//       const clampedValue = Math.min(Math.max(normalizedValue, 0), 1) * 100; // Convert to percentage for display

//       return {
//         type: "gauge",
//         data: {
//           datasets: [
//             {
//               data: thresholds.map((t) => t * 100), // Convert to percentages for Chart.js
//               value: clampedValue, // Use normalized value as percentage
//               backgroundColor: backgroundColors,
//               hoverBackgroundColor: backgroundColors,
//               borderWidth: 2,
//             },
//           ],
//         },
//         options: {
//           interaction: {
//             mode: "point",
//           },
//           responsive: true,
//           title: {
//             display: false,
//             text: "Shannon Index",
//           },
//           layout: {
//             padding: {
//               bottom: 10,
//             },
//           },
//           needle: {
//             radiusPercentage: 2,
//             widthPercentage: 3.2,
//             lengthPercentage: 80,
//             color: "rgba(0, 0, 0, 1)",
//           },
//           valueLabel: {
//             display: false,
//             formatter: Math.round,
//           },
//         },
//       };
//     }

//     // Default graph logic for non-Shannon graphs
//     return {
//       type: "gauge",
//       data: {
//         datasets: [
//           {
//             data: [15, 85, 100],
//             value: graphValue,
//             backgroundColor: [
//               this.selectedColors["bad"],
//               this.selectedColors["normal"],
//               this.selectedColors["great"],
//             ],
//             hoverBackgroundColor: [
//               this.selectedColors["bad"],
//               this.selectedColors["normal"],
//               this.selectedColors["great"],
//             ],
//             borderWidth: 2,
//           },
//         ],
//       },
//       options: {
//         interaction: {
//           mode: "point",
//         },
//         responsive: true,
//         title: {
//           display: false,
//           text: "General Diversity",
//         },
//         layout: {
//           padding: {
//             bottom: 10,
//           },
//         },
//         needle: {
//           radiusPercentage: 2,
//           widthPercentage: 3.2,
//           lengthPercentage: 80,
//           color: "rgba(0, 0, 0, 1)",
//         },
//         valueLabel: {
//           display: false,
//           formatter: Math.round,
//         },
//       },
//     };
//   }
// }
import { Component, OnInit } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { Chart } from "chart.js";
import "chartjs-chart-box-and-violin-plot/build/Chart.BoxPlot.js";
import "chartjs-gauge";
import { Console } from "console";
import { IEyyo } from "../../../interfaces/advice";
import { AppComponent } from "../app.component";

@Component({
  selector: "app-bacteria-diversity",
  templateUrl: "./bacteria-diversity.component.html",
  styleUrls: ["./bacteria-diversity.component.css"],
})
export class BacteriaDiversityComponent implements OnInit {
  constructor(private translate: TranslateService, public app: AppComponent) {}

  dashBacteria: IEyyo;
  doughnutChart: any;
  doughnutChart1: any;
  doughnutChart2: any;
  doughnutChart3: any;
  bacteriaLevels;
  diversityValue: number;
  shannonValue: any;
  shannonMoreInfo: Boolean;
  shannonConf: Object;
  otuValue: any;
  otuMoreInfo: Boolean;
  otuConf: Object;
  pielouValue: any;
  pielouMoreInfo: Boolean;
  pielouConf: Object;

  gaugeColorVariants = {
    default: {
      normal: "#ad66d9",
      okay: "#cfbcf2",
      great: "#05e399",
      bad: "#fad408",
      aware: "#c8bfe7",
    },
    iprobio: {
      normal: "#62b3b3",
      okay: "#b5d2d2",
      great: "#6fd06f",
      bad: "#f299a1",
      aware: "#339d9c69",
    },
    patris_health: {
      normal: "#33ab9f",
      okay: "#81c784",
      great: "#66bb6a",
      bad: "#f44336",
      aware: "#b2dfdb",
    },
  };
  selectedColors = this.gaugeColorVariants.default;
  explanationText = "BACTERIA_DIVERSITY.EXPLANATION_NEW";

  ngOnInit() {
    if (this.app.partnerVersion !== "") {
      this.selectedColors = this.gaugeColorVariants[this.app.partnerVersion];
    }
    // this.explanationText =
    //   "BACTERIA_DIVERSITY.EXPLANATION_NEW_" +
    //   this.app.partnerVersion.toUpperCase();
    this.dashBacteria = JSON.parse(sessionStorage.getItem("Dashboard"));
    this.bacteriaLevels = this.dashBacteria.graphs.find(
      (x) => x.graph === "Bacteria Levels"
    ).value;

    this.shannonValue = this.limitValue(
      parseFloat(
        this.dashBacteria.graphs.find((x) => x.graph === "Shannon").value
      )
    );

    this.otuValue = this.limitValue(
      parseFloat(this.dashBacteria.graphs.find((x) => x.graph === "OTUs").value)
    );

    this.pielouValue = this.limitValue(
      parseFloat(
        this.dashBacteria.graphs.find((x) => x.graph === "Evenness").value
      )
    );
    this.diversityValue = Number(
      this.dashBacteria.graphs.find((x) => x.graph === "Bacteria Diversity")
        .value
    );

    const canvasDiversity = document.getElementById(
      "shannon"
    ) as HTMLCanvasElement;

    const canvasDiversity1 = document.getElementById(
      "otus"
    ) as HTMLCanvasElement;

    const canvasDiversity2 = document.getElementById(
      "bacteriaDiversity2"
    ) as HTMLCanvasElement;

    const canvasDiversity3 = document.getElementById(
      "pielou"
    ) as HTMLCanvasElement;

    const shannon = canvasDiversity.getContext("2d");
    const otus = canvasDiversity1.getContext("2d");
    // const ctxDiversity2 = canvasDiversity2.getContext("2d");
    const pielou = canvasDiversity3.getContext("2d");
    // var ctx2 = document.getElementById("bacteriaDiversity");
    // const ref = [32, 147]; // Bacteria diversity
    // const upperRange = 3;
    // //let diversityScore = ((this.diversityValue - ref[0]) * 70) / (ref[1] - ref[0]) + 15;
    // let diversityScore = (this.diversityValue / upperRange) * 100;
    // if (diversityScore < 0) {
    //   diversityScore = 0;
    // }
    // if (diversityScore > 100) {
    //   diversityScore = 100;
    // }
    console.log(this.shannonValue);

    this.doughnutChart = new Chart(
      shannon,
      this.getGraphConf(this.getDiversityScore(this.shannonValue))
    );

    this.doughnutChart1 = new Chart(
      otus,
      this.getGraphConf(this.getDiversityScore(this.otuValue))
    );

    // this.doughnutChart2 = new Chart(ctxDiversity2, conf);
    this.doughnutChart3 = new Chart(
      pielou,
      this.getGraphConf(this.getDiversityScore(this.pielouValue))
    );
  }
  limitValue(value: number): number {
    if (value < -3) return -3;
    if (value > 3) return 3;
    return value;
  }

  toggleGraphDescription(param) {
    switch (param) {
      case 1:
        this.shannonMoreInfo = !this.shannonMoreInfo;
        this.otuMoreInfo = false;
        this.pielouMoreInfo = false;
        break;
      case 2:
        this.shannonMoreInfo = false;
        this.pielouMoreInfo = false;
        this.otuMoreInfo = !this.otuMoreInfo;
        break;
      case 3:
        this.shannonMoreInfo = false;
        this.otuMoreInfo = false;
        this.pielouMoreInfo = !this.pielouMoreInfo;
        break;
      default:
        break;
    }
  }

  getDiversityScore(value) {
    const upperRange = 6;
    //let diversityScore = ((this.diversityValue - ref[0]) * 70) / (ref[1] - ref[0]) + 15;
    let diversityScore = ((value + 3) / upperRange) * 100;
    if (diversityScore < 0) {
      diversityScore = 0;
    }
    if (diversityScore > 100) {
      diversityScore = 100;
    }

    let min_value = -3;
    let max_value = 3;
    let value_normalized = (value - min_value) / (max_value - min_value);

    return value_normalized * 100;
  }

  getGraphConf(graphValue) {
    return {
      type: "gauge",
      data: {
        datasets: [
          {
            data: [15, 85, 100],
            value: graphValue,
            backgroundColor: [
              this.selectedColors["bad"],
              this.selectedColors["normal"],
              this.selectedColors["great"],
            ],
            hoverBackgroundColor: [
              this.selectedColors["bad"],
              this.selectedColors["normal"],
              this.selectedColors["great"],
            ],
            borderWidth: 2,
          },
        ],
      },
      options: {
        interaction: {
          mode: "point",
        },
        responsive: true,
        title: {
          display: false,
          text: "Gauge chart",
        },layout: {
          padding: {
            bottom: 10,
          },
        },
        needle: {
          // Needle circle radius as the percentage of the chart area width
          radiusPercentage: 2,
          // Needle width as the percentage of the chart area width
          widthPercentage: 3.2,
          // Needle length as the percentage of the interval between inner radius (0%) and outer radius (100%) of the arc
          lengthPercentage: 80,
          // The color of the needle
          color: "rgba(0, 0, 0, 1)",
        },
        valueLabel: {
          display: false,
          formatter: Math.round,
        },
      },
    };
  }
}

