import { ThrowStmt } from "@angular/compiler";
import {
  ChangeDetectorRef,
  Component,
  ComponentFactoryResolver,
  Input,
  OnInit,
} from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { LangChangeEvent, TranslateService } from "@ngx-translate/core";
import { Chart, ChartConfiguration } from "chart.js";
import "chartjs-chart-box-and-violin-plot/build/Chart.BoxPlot.js";
import "chartjs-gauge";
import html2canvas from "html2canvas";
import * as html2pdf from "html2pdf.js";
import jsPDF, { ShadingPattern } from "jspdf";
import { Observable, Subscription, fromEvent } from "rxjs";
import {
  IEyyo,
  INutrientAdvice,
  IProbioticPrebioticAdvice,
} from "../../../interfaces/advice";
import BacteriaSubCategories from "../../assets/json/bacteriaSubCategories.json";
import { DashboardService } from "../api/calculations/dashboard";
import { DietaryDashService } from "../api/calculations/dietarydash";
import { UtilsService } from "../api/utils/utils";
import { SubjectService } from "../api/test/subject";
import { AppComponent } from "../app.component";
import { TestKitIdService } from "../testkit-id-get/testkitid.service";
import { SurveyService } from "../api/survey/survey";
import { resolve } from "url";

@Component({
  selector: "app-dashboard",
  templateUrl: "./dashboard.component.html",
  styleUrls: ["./dashboard.component.css"],
})
export class DashboardComponent implements OnInit {
  // tslint:disable-next-line:max-line-length
  @Input() hidePdfButton: boolean;
  takeHomeData: Object = {
    focusCategories: [],
    meals: {
      breakfast: 0,
      lunch: 0,
      dinner: 0,
    },
    foodRecommendation: [],
  };
  constructor(
    public dashboardService: DashboardService,
    private route: ActivatedRoute,
    private router: Router,
    public app: AppComponent,
    private translate: TranslateService,
    public dietaryDashService: DietaryDashService,
    private testKitIdService: TestKitIdService,
    private utilsService: UtilsService,
    private subjectService: SubjectService,
    private cdr: ChangeDetectorRef
  ) {}

  resizeObservable$: Observable<Event>;
  resizeSubscription$: Subscription;

  warnings = {
    "immune-strength": 0,
    "gut-wall-strength": 0,
    "weight-reduction-support": 0,
    "fibre-degradation": 0,
    "potential-colon-problems-new": 0,
    "infection-alarm-new": 0,
    "fat-alarm-new": 0,
  };

  boxplotChart: any;
  bacteriaLevels = [0, 0, 0, 0];
  barChart: any;
  doughnutChart: any;
  doughnutChartD: any;
  pieChart: any;
  bmiChart: any;
  testKitID: number;
  dashBacteria: IEyyo;
  nutrientValues: INutrientAdvice[];
  bacteriaSubCategories: Object;
  pdfUrls: JSON;
  pdfGenerated: boolean = false;
  showPdfSection: boolean = false;
  activatedOptions: number[] = [];
  subjectId: number;
  loading: boolean = true;
  nooryStatus: string;
  nooryURL: string | null | undefined;
  nooryExplanation: string = "";
  infoBoxNoory = false;

  pieChartLabelsNl = [
    "Graan en graanproducten",
    "Fruit",
    "Noten en zaden",
    "Groenten",
    "Zuivel",
    "Oliën en boters",
    "Vlees",
    "Zeevruchten",
    "Peulvruchten",
    "Water",
  ];
  pieChartLabelsEn = [
    "Grains",
    "Fruits",
    "Nuts and seeds",
    "Vegetables",
    "Dairy",
    "Condiments (oils and butter)",
    "Meat",
    "Seafood",
    "Legumes",
    "Water",
  ];

  baseSidebuttons = [
    {
      id: 1,
      alias: "immune-strength",
      name: "DASHBOARD_OVERVIEW.GUT_HEALTH_TITLE",
      icon: "../../assets/shield_variant" + this.app.partnerVersion + ".png",
      title: "gut-health",
    },
    {
      id: 2,
      alias: "potential-colon-problems-new",
      name: "DASHBOARD_OVERVIEW.COLON_PROBLEM_TITLE",
      icon: "../../assets/colon_variant" + this.app.partnerVersion + ".png",
      title: "gut-challenges",
    },
    {
      id: 3,
      alias: "infection-alarm-new",
      name: "DASHBOARD_OVERVIEW.INFECTION_ALARM_TITLE",
      icon: "../../assets/virus_2_variant" + this.app.partnerVersion + ".png",
      title: "gut-challenges",
    },
    {
      id: 4,
      alias: "gut-wall-strength",
      name: "DASHBOARD_OVERVIEW.PRESENCE_OF_MICROORGANISMS_TITLE",
      icon: "../../assets/guts_variant" + this.app.partnerVersion + ".png",
      title: "gut-health",
    },
    {
      id: 5,
      alias: "fibre-degradation",
      name: "DASHBOARD_OVERVIEW.GAS_PROD_TITLE",
      icon: "../../assets/fart_variant" + this.app.partnerVersion + ".png",
      title: "fiber-degradation",
    },
    {
      id: 6,
      alias: "fat-alarm-new",
      name: "DASHBOARD_OVERVIEW.FAT_ALARM_TITLE",
      icon: "../../assets/body_fat_variant" + this.app.partnerVersion + ".png",
      title: "gut-challenges",
    },
    {
      id: 7,
      alias: "weight-reduction-support",
      name: "DASHBOARD_OVERVIEW.HEALTH_PROMOTING_MICROBIAL_TITLE",
      icon: "../../assets/substance_variant" + this.app.partnerVersion + ".png",
      title: "gut-health",
    },
  ];

  gutHealthSubCategories = this.baseSidebuttons.filter(
    (i) => i.title == "gut-health"
  );
  fibreDegradationSubCategories = this.baseSidebuttons.filter(
    (i) => i.title == "fiber-degradation"
  );
  gutChallengesSubCategories = this.baseSidebuttons.filter(
    (i) => i.title == "gut-challenges"
  );

  sidebuttons = [
    {
      id: 1,
      alias: "immune-strength",
      name: "Immune strength",
      icon: "../../assets/shield_variant-iprobio1.png",
      title: "gut-health",
    },
    {
      id: 2,
      alias: "gut-wall-strength",
      name: "Potential colon problem",
      icon: "../../assets/colon_variant.png",
      title: "gut-challenges",
    },
    {
      id: 3,
      alias: "weight-reduction-support",
      name: "Infection alarm",
      icon: "../../assets/virus_2_variant.png",
      title: "gut-challenges",
    },
    {
      id: 4,
      alias: "fiber-degradation",
      name: "Presence of healthy microorganisms",
      icon: "../../assets/guts_variant.png",
      title: "gut-health",
    },
    {
      id: 5,
      alias: "infection-alarm-new",
      name: "Gas production possibilities",
      icon: "../../assets/fart_variant.png",
      title: "fiber-degradation",
    },
    {
      id: 6,
      alias: "fat-alarm-new",
      name: "Fat alarm",
      icon: "../../assets/body_fat_variant.png",
      title: "gut-challenges",
    },
    {
      id: 7,
      alias: "potential-colon-problems-new",
      name: "Production of health-promoting microbial substances",
      icon: "../../assets/substance_variant.png",
      title: "gut-health",
    },
  ];

  bmiValue: number | null = null;
  hipWaistValue: number | null = null;
  isFemale: boolean;

  bacPercentage = 0;
  adviceLevels = [1, 2, 3, 4, 5, 6, 7];

  carbohydratesValue = 1;
  fatValue = 1;
  proteinValue = 1;
  totalNutrients = 3;

  gaugeColorVariants = {
    default: {
      normal: "#ad66d9",
      okay: "#cfbcf2",
      great: "#05e399",
      bad: "#fad408",
      aware: "#c8bfe7",
      bmi1: "#E5CEF3",
      bmi2: "#D3ADEB",
      bmi3: "#C28DE2",
    },
    iprobio: {
      normal: "#62b3b3",
      okay: "#b5d2d2",
      great: "#6fd06f",
      bad: "#f299a1",
      aware: "#339d9c69",
      bmi1: "#54adb426",
      bmi2: "#54adb440",
      bmi3: "#54adb47d",
    },
    patris_health: {
      normal: "#33ab9f",
      okay: "#81c784",
      great: "#66bb6a",
      bad: "#f44336",
      aware: "#b2dfdb",
      bmi1: "#33ab9f26",
      bmi2: "#33ab9f40",
      bmi3: "#33ab9f7d",
    },
  };
  selectedColors = this.gaugeColorVariants["default"];
  partnerImgSrc = "";
  probioticCount: number = 0;
  prebioticCount: number = 0;

  newSurvey: boolean = false;

  async ngOnInit() {
    if (this.app.partnerVersion != "") {
      this.partnerImgSrc = "-" + this.app.partnerVersion;
      this.selectedColors = this.gaugeColorVariants[this.app.partnerVersion];
    }
    console.log(this.baseSidebuttons);

    this.testKitID = Number(this.route.snapshot.paramMap.get("id"));
    this.fetchAndLogSurveyAnswers();

    this.fetchSubjects();

    // Exporting TestKitID for Usage in other pages & saving to local storage
    localStorage.setItem("currentTestKitID", this.testKitID.toString());
    this.testKitIdService.setTestKitID(this.testKitID);

    // TODO: add data for the 'your bacteria levels' chart

    try {
      const response = await this.dashboardService.GetDashboardBacteria(
        this.testKitID
      );
      console.log("EHOOOOOO");
      console.log(response);

      sessionStorage.setItem("Dashboard", JSON.stringify(response.data));
      this.dashBacteria = JSON.parse(sessionStorage.getItem("Dashboard"));
      this.getCategoryWarnings();
    } catch (error) {
      console.error("Failed to fetch dashboard bacteria data:", error);
      throw error; // Re-throw or handle it
    }

    if (!this.dashBacteria.graphs.find((x) => x.graph === "OTUs")) {
      console.log(this.route.params);
      this.router.navigateByUrl("/mantainance");
      return;
    }

    // const shannonGraph = this.dashBacteria.graphs.find(
    //   (x) => x.graph === "Shannon"
    // );
    // const diversityValue = shannonGraph ? Number(shannonGraph.value) : 0;

    const shannonNew = this.dashBacteria.graphs.find(
      (x) => x.graph === "Shannon"
    );
    const diversityValue = shannonNew ? Number(shannonNew.value) : 0;

    console.log(shannonNew);

    if (isNaN(diversityValue)) {
      console.error("Diversity value is invalid:", diversityValue);
      return; // Exit if the value is invalid
    }

    try {
      this.bmiValue = (await this.dashboardService.GetBmi(this.testKitID)).data;
      console.log(this.bmiValue);
      this.cdr.detectChanges();
    } catch (error) {
      console.error("Failed to fetch BMI data:", error);
      this.bmiValue = null; // Set to null if fetching fails
    }

    try {
      const response = await this.dashboardService.GetHipWaist(this.testKitID);
      console.log(response);
      this.hipWaistValue = response.data["hip_waist_ratio"];
      console.log("Fetched hipWaistValue:", this.hipWaistValue);

      // Trigger change detection
      this.cdr.detectChanges();
    } catch (error) {
      console.error("Failed to fetch Hip/Waist data:", error);
      this.hipWaistValue = null;
    }
    this.bacteriaLevels = this.calculateBacteriaLevels(this.dashBacteria.top35);
    this.bacPercentage =
      (this.bacteriaLevels[0] +
        this.bacteriaLevels[1] +
        this.bacteriaLevels[2]) /
      100;

    this.updateChartLabels();
    this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      this.updateChartLabels();
    });

    this.resizeObservable$ = fromEvent(window, "resize");
    this.resizeSubscription$ = this.resizeObservable$.subscribe((evt) => {
      // @ts-ignore
      //this.pieChart.options.legend.display = !(evt.target.innerWidth < 480);
      //this.pieChart.update();
    });

    // Get the language code id
    let langId;
    if (localStorage.getItem("language") == "en") {
      langId = 1;
    } else if (localStorage.getItem("language") == "it") {
      langId = 3;
    } else if (localStorage.getItem("language") == "sk") {
      langId = 4;
    } else {
      langId = 2;
    }

    // Retrieve the pdf url
    if (!this.pdfUrls || Object.keys(this.pdfUrls).length === 0) {
      try {
        this.pdfUrls = await this.utilsService.GetDashboardPdfLinks(
          this.testKitID,
          this.app.selectedLanguage
        );
        if (this.pdfUrls && Object.keys(this.pdfUrls).length > 0) {
          this.showPdfSection = true;
          this.pdfGenerated = true;
          document.getElementById("pdfButton").setAttribute("style", "100%");
        }
      } catch (error) {
        console.error("Error with retrieving PDFs: " + error);
      }
    }
    this.loading = false;
    this.cdr.detectChanges();
    if (this.bmiValue !== null && this.bmiValue !== undefined) {
      this.renderBmiGraph(this.bmiValue);
    }
    if (this.hipWaistValue !== null && this.hipWaistValue !== undefined) {
      this.renderHipWaistGraph(this.hipWaistValue);
    }
    this.renderBacteriaLevelsPieChart();
    this.renderBacteriaDiversityGraph(diversityValue);
    await this.setTargetNutChart(this.testKitID);
  }

  // tslint:disable-next-line:use-lifecycle-interface
  // ngOnDestroy() {
  //   this.resizeSubscription$.unsubscribe();
  // }

  // tslint:disable-next-line:use-lifecycle-interface

  // async setTargetNutChart(testKitID: number) {
  //   try {
  //     const response = await this.dietaryDashService.GetNutrientAdvice(
  //       testKitID
  //     );
  //     this.nutrientValues = response.data["nutrients"];
  //     console.log("Nutrient Advice:", response);

  //     // Extract raw nutrient values
  //     const carb = this.nutrientValues.find((x) => x.type === "carbohydrates");
  //     const carbRaw = carb && carb.raw ? carb.raw : 0;

  //     const fat = this.nutrientValues.find((x) => x.type === "fat");
  //     const fatRaw = fat && fat.raw ? fat.raw : 0;

  //     const protein = this.nutrientValues.find((x) => x.type === "protein");
  //     const proteinRaw = protein && protein.raw ? protein.raw : 0;

  //     // Calculate total
  //     const total = carbRaw + fatRaw + proteinRaw;

  //     // Normalize to percentages for the doughnut chart
  //     this.carbohydratesValue = Math.round(carbRaw);
  //     this.fatValue = Math.round(fatRaw);
  //     this.proteinValue = Math.round(proteinRaw);

  //     console.log("Normalized Nutrient Values:", {
  //       carbohydrates: this.carbohydratesValue,
  //       fat: this.fatValue,
  //       protein: this.proteinValue,
  //     });

  //     this.renderTargetNutrientChart();
  //   } catch (error) {
  //     console.error("Error fetching or processing nutrient data:", error);

  //     // Set fallback values or display error messages
  //     this.carbohydratesValue = 0;
  //     this.fatValue = 0;
  //     this.proteinValue = 0;

  //     this.nutrientValues = []; // Clear nutrient values in case of an error
  //   }
  // }
  async setTargetNutChart(testKitID: number) {
    try {
      const response = await this.dietaryDashService.GetNutrientAdvice(
        testKitID
      );
      this.nutrientValues = response.data["nutrients"];
      console.log("Nutrient Advice:", response);

      // Extract raw nutrient values
      const carb = this.nutrientValues.find((x) => x.type === "carbohydrates");
      const carbRaw = carb && carb.value ? carb.value : 0;

      const fat = this.nutrientValues.find((x) => x.type === "fat");
      const fatRaw = fat && fat.value ? fat.value : 0;

      const protein = this.nutrientValues.find((x) => x.type === "protein");
      const proteinRaw = protein && protein.value ? protein.value : 0;

      // Calculate total
      const total = carbRaw + fatRaw + proteinRaw;

      // Normalize to percentages for the doughnut chart
      this.carbohydratesValue =
        total > 0 ? Math.round((carbRaw / total) * 100) : 0;
      this.fatValue = total > 0 ? Math.round((fatRaw / total) * 100) : 0;
      this.proteinValue =
        total > 0 ? Math.round((proteinRaw / total) * 100) : 0;

      console.log("Normalized Nutrient Values (Percentages):", {
        carbohydrates: this.carbohydratesValue,
        fat: this.fatValue,
        protein: this.proteinValue,
      });

      this.renderTargetNutrientChart();
    } catch (error) {
      console.error("Error fetching or processing nutrient data:", error);

      // Set fallback values or display error messages
      this.carbohydratesValue = 0;
      this.fatValue = 0;
      this.proteinValue = 0;

      this.nutrientValues = []; // Clear nutrient values in case of an error
    }
  }

  updateSideButtons() {
    let i = 0;
    for (const step of this.baseSidebuttons) {
      this.translate.get(step.name).subscribe((text: string) => {
        this.sidebuttons[i].name = text;
      });
      i++;
    }
  }

  updateChartLabels() {
    // if (this.translate.currentLang.toString() === "nl") {
    //   this.pieChart.data.labels = this.pieChartLabelsNl;
    // } else {
    //   this.pieChart.data.labels = this.pieChartLabelsEn;
    // }
    // this.pieChart.update();
  }

  calculateBacteriaLevels(bacteria) {
    // Bacteria to ignore during calculation
    const goodBacteria = [
      "Akkermansia",
      "Anaerostipes",
      "Barnesiella",
      "Bifidobacterium",
      "Blautia",
      "Butyricicoccus",
      "Christensenellaceae_R-7_group",
      "Coprococcus",
      "Eubacterium",
      "Faecalibacterium",
      "Hafnia-Obesumbacterium",
      "Holdemanella",
      "Lactobacillus",
      "Parabacteroides",
      "Roseburia",
      "Ruminococcaceae",
    ];

    // Other bacteria of interest
    const otherBacteria = [
      "Clostridium_sensu_stricto_1",
      "Ruminiclostridium",
      "Dorea",
      "Fusicatenibacter",
      "Lachnoclostridium",
      "Lachnospira",
      "Lachnospiraceae",
      "Prevotella",
      "Subdoligranulum",
      "Methanobrevibacter",
      "Methanosphaera",
    ];

    // Bacteria considered "bad"
    const badBacteria = [
      "Bacteroides",
      "Bilophila",
      "Desulfovibrio",
      "Escherichia-Shigella",
      "Fusobacterium",
      "Klebsiella",
      "Sutterella",
    ];

    const levels = [0, 0, 0, 0]; // [Bad, Normal, Other, Great]

    bacteria.forEach((item) => {
      const { bacteria: bacteriaName, status } = item;

      // Skip ignored bacteria
      // if (
      //   !goodBacteria.includes(bacteriaName) ||
      //   !badBacteria.includes(bacteriaName) ||
      //   !otherBacteria.includes(bacteriaName)
      // ) {
      //   return;
      // }

      if (otherBacteria.includes(bacteriaName)) {
        if (status === "high" || status === "low") {
          levels[2] += 1;
        } else if (status === "avg") {
          levels[1] += 1;
        } else return;
      }
      if (badBacteria.includes(bacteriaName)) {
        if (status === "high") {
          levels[0] += 1; // Be Aware
        } else if (status === "low") {
          levels[3] += 1; // Great (less bad bacteria is better)
        } else if (status === "avg") {
          levels[1] += 1;
        } else return;
      }
      if (goodBacteria.includes(bacteriaName)) {
        if (status === "low") {
          levels[0] += 1;
        } else if (status === "avg") {
          levels[1] += 1;
        } else if (status === "high") {
          levels[3] += 1;
        }
      } else return;
    });

    console.log("Calculated bacteria levels:", levels);
    return levels;
  }

  // Very slow download with some elements - More testing to be done.
  printPdf() {
    const hiddenContainer = document.getElementById("pdf-hidden-container");
    hiddenContainer.classList.remove("hidden-for-pdf");

    const cardIds = [
      "bmi-card",
      "bacteria-diversity-card",
      "bacteria-levels-card",
      "target-values-card",
    ];
    const detailIds = [
      ["bmi-details"],
      [
        "bacteria-diversity-details-1",
        "bacteria-diversity-details-2",
        "bacteria-diversity-details-3",
      ],
      ["bacteria-levels-details"],
      ["target-values-details"],
    ];

    const processCard = async (cardId: string, detailIdsForCard: string[]) => {
      const cardElement = document.getElementById(cardId);

      if (cardElement && detailIdsForCard.length > 0) {
        for (const detailId of detailIdsForCard) {
          const detailElement = document.getElementById(detailId);
          if (detailElement) {
            cardElement.appendChild(detailElement.cloneNode(true));
          }
        }
      }
    };

    const processAllCards = async () => {
      for (let i = 0; i < cardIds.length; i++) {
        await processCard(cardIds[i], detailIds[i]);
      }
    };

    processAllCards().then(() => {
      const opt = {
        margin: [1, 0.5],
        filename: "dashboard.pdf",
        image: { type: "jpeg", quality: 0.98 },
        html2canvas: { scale: 2 },
        jsPDF: { unit: "in", format: "a4", orientation: "portrait" },
        pagebreak: { mode: "avoid-all", before: ".avoid-pagebreak-before" },
        onclone: (clonedContent: HTMLElement) => {
          const hiddenElements =
            clonedContent.querySelectorAll(".hidden-for-pdf");
          Array.from(hiddenElements).forEach((element: HTMLElement) => {
            if (element) {
              element.style.visibility = "visible";
            }
          });
        },
      };

      html2pdf()
        .from(hiddenContainer)
        .set(opt)
        .save()
        .then(() => {
          setTimeout(() => {
            hiddenContainer.classList.add("hidden-for-pdf");
          }, 1000);
        });
    });
  }

  getCategoryWarnings() {
    this.dashBacteria.top35.forEach((elem) => {
      console.log("elem");
      console.log(elem);
      if (BacteriaSubCategories.IMMUNE_STRENGTH.includes(elem.bacteria)) {
        if (elem.status == "low") this.warnings["immune-strength"]++;
        return;
      }
      if (BacteriaSubCategories.GUT_WALL_STRENGTH.includes(elem.bacteria)) {
        if (elem.status == "low") this.warnings["gut-wall-strength"]++;
        return;
      }
      if (
        BacteriaSubCategories.WEIGHT_REDUCTION_SUPPORT.includes(elem.bacteria)
      ) {
        if (elem.status == "low") this.warnings["weight-reduction-support"]++;
        return;
      }
      if (BacteriaSubCategories.FIBRE_DEGRADATION.includes(elem.bacteria)) {
        if (elem.status == "low" || elem.status == "high")
          this.warnings["fibre-degradation"]++;
        return;
      }
      if (
        BacteriaSubCategories.POTENTIAL_COLON_PROBLEMS_NEW.includes(
          elem.bacteria
        )
      ) {
        if (elem.status == "high")
          this.warnings["potential-colon-problems-new"]++;
        return;
      }
      if (BacteriaSubCategories.INFECTION_ALARM_NEW.includes(elem.bacteria)) {
        if (elem.status == "high") this.warnings["infection-alarm-new"]++;
        return;
      }
      if (BacteriaSubCategories.FAT_ALARM_NEW.includes(elem.bacteria)) {
        if (elem.status == "high") this.warnings["fat-alarm-new"]++;
        return;
      }
    });
  }

  async getTakeHomeMessage() {
    const response = await this.dashboardService.GetTakeHomeMessage(
      parseInt(localStorage.getItem("s_id" + this.testKitID)),
      localStorage.getItem("k_uid" + this.testKitID),
      localStorage.getItem("language").toUpperCase()
    );
    response.data["generated_categories"].forEach((element) => {
      this.takeHomeData["focusCategories"].push(element.category);
    });

    response.data["generated_options"].forEach((element) => {
      let temp = {
        name: element.food_item,
        foodGroup: element.food_group,
      };
      this.takeHomeData["foodRecommendation"].push(temp);
    });

    response.data["generated_rules"].forEach((element) => {
      switch (element.show_meal_type) {
        case "Breakfast":
          this.takeHomeData["meals"]["breakfast"]++;
          break;
        case "Lunch":
          this.takeHomeData["meals"]["lunch"]++;
          break;
        case "Dinner":
          this.takeHomeData["meals"]["dinner"]++;
          break;
      }
    });
  }

  // async loadPdf() {
  //   // Open the pdf in a new tab
  //   if (this.pdfGenerated == true) {
  //     window.open(this.pdfUrls[this.translate.currentLang], "_blank");
  //   }
  // }
  goToReports(): void {
    this.router.navigate(['/reports']);
  }
  

  // Prebiotic/Probiotic data
  // Prebiotic/Probiotic data
  async getProbioticAndPrebioticDataCount() {
    await this.dietaryDashService
      .GetSupplementAdvice(this.testKitID, this.translate.getDefaultLang())
      .then((data: IProbioticPrebioticAdvice | undefined) => {
        if (!data) {
          console.warn("No probiotic/prebiotic data found.");
          return;
        }

        // Get unique probiotic strains
        this.probioticCount = data.probiotic_results
          ? new Set(data.probiotic_results.map((item) => item.probiotic_strain))
              .size
          : 0;

        // Get unique prebiotic supplements
        const uniquePrebioticSupplements = data.prebiotic_supplements
          ? new Set(data.prebiotic_supplements.map((item) => item.bacteria))
              .size
          : 0;

        // Get unique prebiotic foods
        const uniquePrebioticFoods = data.prebiotic_compounds
          ? new Set(
              data.prebiotic_compounds
                .map((compound) => compound.foods || [])
                .reduce<string[]>(
                  (acc, foods) => acc.concat(foods.map((food) => food.name)),
                  []
                )
            ).size
          : 0;

        // Total unique prebiotic count
        this.prebioticCount = uniquePrebioticSupplements + uniquePrebioticFoods;

        console.log(`Unique Probiotic count: ${uniquePrebioticFoods}`);
        console.log(`Unique Prebiotic count: ${uniquePrebioticSupplements}`);
      })
      .catch((error) => {
        console.error("Error fetching probiotic and prebiotic data:", error);
      });
  }
  async fetchAndLogSurveyAnswers() {
    try {
      // Replace with an appropriate kitId for your testing
      const kitId = this.testKitID; // Example kit ID, update it accordingly
      const surveyService = new SurveyService();
      const surveyAnswers = await surveyService.GetUserServeyAnswers(kitId);
      this.newSurvey = surveyAnswers.data.some(
        (item) => item.question_id === 275
      );

      const genderAnswer = surveyAnswers.data.find(
        (item) => item.question_id === 279
      );
      if (genderAnswer && genderAnswer.answer_text === "Female") {
        this.isFemale = true;
      } else {
        this.isFemale = false;
      }
      console.log(this.newSurvey);
      console.log("User Survey Answers:", surveyAnswers.data);
    } catch (error) {
      console.error("Error fetching user survey answers:", error);
    }
  }
  isNextVisibleSegment(index: number): boolean {
    return (
      this.bacteriaLevels[index] > 0 &&
      (index === 0 || this.bacteriaLevels[index - 1] === 0)
    );
  }

  isLastVisibleSegment(index: number): boolean {
    return (
      this.bacteriaLevels[index] > 0 &&
      (index === this.bacteriaLevels.length - 1 ||
        this.bacteriaLevels[index + 1] === 0)
    );
  }
  async getNoory() {
    console.log(this.subjectId);
    this.dietaryDashService
      .GetNoory(this.subjectId)
      .then((data: any) => {
        if (!data) {
          return;
        }

        this.nooryStatus = data.data.status;

        if (data.data.url) {
          try {
            // Format the URL
            const formattedUrl = new URL(data.data.url);
            this.nooryURL = formattedUrl.href; // Ensure the URL is well-formed
          } catch (error) {
            console.error("Invalid URL format:", data.data.url);
            this.nooryURL = null; // Set to null if the URL is invalid
          }
        }
        if (this.nooryStatus === "linked") {
          this.nooryURL =
            "https://dashboard.noory.com/" +
            localStorage.getItem("language") +
            "/";
        }
        this.updateNooryExplanation();
        console.log(this.nooryStatus);
        console.log(this.nooryURL);
      })
      .catch((error) => {
        console.error("Error fetching probiotic and prebiotic data:", error);
      });
  }
  navigateToNoory(): void {
    if (this.nooryStatus === "pending") {
      if (this.nooryURL) {
        // Navigate to the URL from the response
        window.open(this.nooryURL, "_blank"); // Opens in a new tab
      } else {
        console.error("Noory URL is unavailable.");
      }
    } else if (this.nooryStatus === "linked") {
      const language = localStorage.getItem("language") || "en"; // Default to 'en' if language is not set
      const hardcodedUrl = `https://dashboard.staging.noory.com/${language}/`;
      // Navigate to the hardcoded URL with the language parameter
      window.open(hardcodedUrl, "_blank"); // Opens in a new tab
    } else {
      console.error("Invalid Noory status.");
    }
  }

  updateNooryExplanation(): void {
    if (this.nooryStatus === "unlinked") {
      this.nooryExplanation =
        "The Noory functionality is not yet available for your account. Stay tuned!";
    } else if (this.nooryStatus === "pending") {
      this.nooryExplanation =
        "Your Noory registration is pending. Please complete the registration by clicking the button below.";
    } else if (this.nooryStatus === "linked") {
      this.nooryExplanation =
        "You are linked to the Noory recipe platform. Please note that new test kits will overwrite the current recipe platform. Click the button to access Noory.";
    }
  }
  
  async fetchSubjects() {
    try {
      // Fetch subjects using the kit ID
      const response = await this.subjectService.GetSingleKit(this.testKitID);
      console.log(response.data);

      this.subjectId = response.data["subject_id"];
      this.activatedOptions = response.data["activated_options"];

      

      const actionsMap = {
        1: () => this.getTakeHomeMessage(),
        3: () => this.getProbioticAndPrebioticDataCount(),
        4: () => this.getNoory(),
      };

      // Iterate over activatedOptions and execute corresponding actions
      this.activatedOptions.forEach((option) => {
        const action = actionsMap[option];
        if (action) {
          action(); // Execute the action if it exists
        }
      });
    } catch (error) {
      console.error("Error fetching subjects:", error);
    }
  }
  // Check if a specific option is enabled
  isOptionEnabled(option: number): boolean {
    return this.activatedOptions.includes(option);
  }

  renderBacteriaLevelsPieChart() {
    const ctx = document.getElementById(
      "bacteriaLevelsPieChart"
    ) as HTMLCanvasElement;

    if (!ctx) {
      console.error("Canvas element for bacteria levels pie chart not found.");
      return;
    }

    const colors = this.selectedColors; // Use the selectedColors dynamically
    this.pieChart = new Chart(ctx, {
      type: "doughnut",
      data: {
        datasets: [
          {
            data: this.bacteriaLevels || [0, 0, 0, 0], // Fallback if data is undefined
            backgroundColor: [
              colors.bad, // Be Aware
              colors.normal, // Normal
              colors.okay, // Other Than Normal
              colors.great, // Great
            ],
          },
        ],
      },
      options: {
        responsive: true,
        legend: {
          display: false,
        },
        tooltips: {
          enabled: false,
        },
      },
    });
  }
  renderTargetNutrientChart() {
    const canvasTargetNut = document.getElementById(
      "target-nut"
    ) as HTMLCanvasElement;

    if (!canvasTargetNut) {
      console.error("Canvas element for target nutrient chart not found.");
      return;
    }

    const ctxTargetNut = canvasTargetNut.getContext("2d");

    if (this.doughnutChart) {
      this.doughnutChart.destroy(); // Destroy the existing chart if it exists
    }

    const confTargetNut = {
      type: "doughnut",
      data: {
        labels: ["Carbohydrates", "Fat", "Protein"],
        datasets: [
          {
            data: [this.carbohydratesValue, this.fatValue, this.proteinValue],
            backgroundColor: [
              this.selectedColors["bmi1"],
              this.selectedColors["bmi2"],
              this.selectedColors["bmi3"],
            ],
            borderWidth: 2,
          },
        ],
      },
      options: {
        responsive: true,
        legend: {
          display: false,
        },
        tooltips: {
          enabled: false,
        },
      },
    };
    if (this.doughnutChart) {
      this.doughnutChart.destroy(); // Destroy any existing chart before creating a new one
    }
    this.doughnutChart = new Chart(ctxTargetNut, confTargetNut);
  }
  renderBacteriaDiversityGraph(diversityValue: number) {
    console.log(diversityValue);
    const canvasDiversity = document.getElementById(
      "bacteriaDiversity"
    ) as HTMLCanvasElement;

    if (!canvasDiversity) {
      console.error("Canvas element for bacteria diversity not found.");
      return;
    }

    const ctxDiversity = canvasDiversity.getContext("2d");
    const upperRange = 6; // Maximum diversity range
    let diversityScore = ((diversityValue + 3) / upperRange) * 100;

    if (diversityScore < 0) {
      diversityScore = 0;
    }
    if (diversityScore > 100) {
      diversityScore = 100;
    }
    console.log(diversityScore);

    const conf = {
      type: "gauge",
      data: {
        datasets: [
          {
            data: [15, 85, 100],
            value: diversityScore,
            backgroundColor: [
              this.selectedColors["bad"],
              this.selectedColors["normal"],
              this.selectedColors["great"],
            ],
            hoverBackgroundColor: [
              this.selectedColors["bad"],
              this.selectedColors["normal"],
              this.selectedColors["great"],
            ],
            borderWidth: 2,
          },
        ],
      },
      options: {
        interaction: {
          mode: "point",
        },
        responsive: true,
        title: {
          display: false,
          text: "Gauge chart",
        },
        layout: {
          padding: {
            top: 10,
            bottom: 10,
          },
        },
        needle: {
          // Needle circle radius as the percentage of the chart area width
          radiusPercentage: 2,
          // Needle width as the percentage of the chart area width
          widthPercentage: 3.2,
          // Needle length as the percentage of the interval between inner radius (0%) and outer radius (100%) of the arc
          lengthPercentage: 80,
          // The color of the needle
          color: "rgba(0, 0, 0, 1)",
        },
        valueLabel: {
          display: false,
          formatter: Math.round,
        },
      },
    };

    this.doughnutChartD = new Chart(ctxDiversity, conf);
  }
  // renderBacteriaDiversityGraph(diversityValue: number) {
  //   console.log(diversityValue);
  //   const canvasDiversity = document.getElementById(
  //     "bacteriaDiversity"
  //   ) as HTMLCanvasElement;

  //   if (!canvasDiversity) {
  //     console.error("Canvas element for bacteria diversity not found.");
  //     return;
  //   }

  //   const ctxDiversity = canvasDiversity.getContext("2d");

  //   // Adjusted thresholds for Shannon Diversity
  //   const graphThresholds = [
  //     (3 - 2) / (6 - 2), // Normalized threshold for 3
  //     (4.5 - 2) / (6 - 2), // Normalized threshold for 4.5
  //     1, // Normalized threshold for 6
  //   ]; // [0.25, 0.625, 1]
  //   const normalizedValue = (diversityValue - 2) / (6 - 2);
  //   const clampedValue = Math.min(Math.max(normalizedValue, 0), 1) * 100;
  //   const backgroundColors = [
  //     this.selectedColors["bad"], // 2–3 (Yellow)
  //     this.selectedColors["normal"], // 3–4.5 (Purple)
  //     this.selectedColors["great"], // 4.5–6 (Green)
  //   ];

  //   const conf = {
  //     type: "gauge",
  //     data: {
  //       datasets: [
  //         {
  //          data: [15, 85, 100],
  //           value: clampedValue, // The value to display
  //           backgroundColor: backgroundColors,
  //           hoverBackgroundColor: backgroundColors,
  //           borderWidth: 2,
  //         },
  //       ],
  //       labels: ["Bad (2-3)", "Normal (3-4.5)", "Great (4.5-6)"],
  //     },
  //     options: {
  //       interaction: {
  //         mode: "point",
  //       },
  //       responsive: true,
  //       title: {
  //         display: false,
  //         text: "Shannon Diversity Chart",
  //       },
  //       layout: {
  //         padding: {
  //           top: 10,
  //           bottom: 10,
  //         },
  //       },
  //       needle: {
  //         radiusPercentage: 2,
  //         widthPercentage: 3.2,
  //         lengthPercentage: 80,
  //         color: "rgba(0, 0, 0, 1)",
  //       },
  //       valueLabel: {
  //         display: true,
  //         formatter: (value: number) => `Shannon: ${diversityValue.toFixed(2)}`, // Show original value
  //       },
  //     },
  //   };

  //   if (this.doughnutChartD) {
  //     this.doughnutChartD.destroy(); // Destroy existing chart if it exists
  //   }

  //   this.doughnutChartD = new Chart(ctxDiversity, conf);
  // }

  renderBmiGraph(bmiValue: number) {
    const canvasBmi = document.getElementById("bmiGraph") as HTMLCanvasElement;

    if (!canvasBmi) {
      console.error("Canvas element for BMI graph not found.");
      return;
    }

    const ctxBmi = canvasBmi.getContext("2d");

    // Define the BMI value thresholds for each segment
    const bmiThresholds = [18.5, 25, 30, 35, 40];
    const graphThresholds = [6.5, 13, 18, 23, 28];
    const totalParts = bmiThresholds.length; // 5 parts

    // Normalize the BMI value to a percentage of the total range (18.5 to 40)
    const lowerLimit = bmiThresholds[0];
    const upperLimit = bmiThresholds[bmiThresholds.length - 1];
    // let bmiScore = ((bmiValue - lowerLimit) / (upperLimit - lowerLimit)) * 100;
    console.log(bmiValue);
    const graphValue = mapBmiToGraph(bmiValue, bmiThresholds, graphThresholds);
    // Ensure the score is within bounds
    let bmiScore = Math.max(0, Math.min(graphValue, 100));

    // Divide the graph into 5 equal visual parts
    const segmentSize = 100 / totalParts; // Each part is 20% of the graph
    const segmentValues = bmiThresholds.map((value, index, arr) =>
      index === 0 ? segmentSize : segmentSize * (index + 1)
    );

    // Define colors for each segment
    const backgroundColors = [
      this.selectedColors["great"], // 18.5 - 25
      this.selectedColors["normal"], // 25 - 30
      this.selectedColors["okay"], // 30 - 35
      this.selectedColors["bad"], // 35 - 40
      this.selectedColors["aware"], // > 40 (optional if extending beyond 40)
    ];

    const conf = {
      type: "gauge",
      data: {
        datasets: [
          {
            data: graphThresholds, // Equal visual parts
            value: bmiScore, // BMI value as percentage
            backgroundColor: backgroundColors.slice(0, totalParts), // Colors for each segment
            hoverBackgroundColor: backgroundColors.slice(0, totalParts),
            borderWidth: 2,
          },
        ],
      },
      options: {
        responsive: true,
        maintainAspectRatio: true,
        needle: {
          radiusPercentage: 2,
          widthPercentage: 3.2,
          lengthPercentage: 80,
          color: "rgba(0, 0, 0, 1)",
        },
        valueLabel: {
          display: true,
          formatter: (value: number) => `${this.bmiValue.toFixed(1)} BMI`,
        },
        layout: {
          padding: {
            top: 10,
            bottom: 10,
          },
        },
        plugins: {
          tooltip: {
            enabled: true,
            callbacks: {
              label: (context: any) => {
                const rawValue = context.raw;
                return `BMI: ${rawValue.toFixed(1)}`;
              },
            },
          },
        },
      },
    };

    new Chart(ctxBmi, conf);
  }

  renderHipWaistGraph(hipWaistValue: number) {
    const canvasHipWaist = document.getElementById(
      "hipWaistGraph"
    ) as HTMLCanvasElement;

    if (!canvasHipWaist) {
      console.error("Canvas element for Hip/Waist graph not found.");
      return;
    }

    const ctxHipWaist = canvasHipWaist.getContext("2d");

    // Define thresholds based on gender
    const thresholds = this.isFemale
      ? { normal: 0.8, overweight: 0.85 }
      : { normal: 0.9, overweight: 1.0 };

    // Determine the range
    let range = "Obese";
    if (hipWaistValue < thresholds.normal) {
      range = "Normal";
    } else if (
      hipWaistValue >= thresholds.normal &&
      hipWaistValue <= thresholds.overweight
    ) {
      range = "Overweight";
    }

    // Log the range for debugging
    console.log(`Hip/Waist Value: ${hipWaistValue}, Range: ${range}`);

    // Map ranges to graph thresholds and colors
    let graphThresholds = [0.79, 0.84, 1.58]; // Adjust segments as needed
    if (!this.isFemale) {
      graphThresholds = [0.89, 0.99, 1.78];
    }
    const colors = {
      Normal: this.selectedColors["great"],
      Overweight: this.selectedColors["normal"],
      Obese: this.selectedColors["bad"],
    };

    // Ensure the score is within bounds
    let hipWaistScore = Math.max(0, Math.min(hipWaistValue, 100));
    console.log(hipWaistScore);
    if (hipWaistScore < 0) {
      hipWaistScore = 0;
    } else if (hipWaistScore > 1) {
      hipWaistScore = 1;
    }

    const conf = {
      type: "gauge",
      data: {
        datasets: [
          {
            data: graphThresholds,
            value: hipWaistScore,
            backgroundColor: [
              colors["Normal"],
              colors["Overweight"],
              colors["Obese"],
            ],
            hoverBackgroundColor: [
              colors["Normal"],
              colors["Overweight"],
              colors["Obese"],
            ],
            borderWidth: 2,
          },
        ],
      },
      options: {
        responsive: true,
        maintainAspectRatio: true,
        needle: {
          radiusPercentage: 2,
          widthPercentage: 3.2,
          lengthPercentage: 80,
          color: "rgba(0, 0, 0, 1)",
        },
        valueLabel: {
          display: true,
          formatter: () => `Range: ${range}`, // Display the range label
        },
        layout: {
          padding: {
            top: 10,
            bottom: 10,
          },
        },
        plugins: {
          tooltip: {
            enabled: true,
            callbacks: {
              label: () => `Hip/Waist: ${hipWaistValue.toFixed(2)} (${range})`,
            },
          },
        },
      },
    };

    new Chart(ctxHipWaist, conf);
  }
  toggleInfoBox() {
    if (this.infoBoxNoory) {
      this.infoBoxNoory = false;
    } else {
      this.infoBoxNoory = true;
    }
  }
  getBMILevel(): string {
    if (!this.bmiValue) return "0%";

    const bmiThresholds = [0, 18.5, 25, 30, 40]; // BMI thresholds
    const graphThresholds = [0, 25, 50, 75, 100]; // Graph positions for each range

    // Find the correct range and calculate proportional position
    for (let i = 1; i < bmiThresholds.length; i++) {
      if (this.bmiValue <= bmiThresholds[i]) {
        const position =
          graphThresholds[i - 1] +
          ((this.bmiValue - bmiThresholds[i - 1]) /
            (bmiThresholds[i] - bmiThresholds[i - 1])) *
            (graphThresholds[i] - graphThresholds[i - 1]);
        return `calc(${position}%)`; // Adjust for the needle's width
      }
    }

    // If BMI exceeds the last threshold, return the maximum position
    return "calc(100% - 15px)";
  }

  getHipWaistLevel(): string {
    if (!this.hipWaistValue) return "0%";

    const ranges = this.isFemale ? [0.79, 0.84, 10] : [0.89, 0.99, 10];

    for (let i = 0; i < ranges.length; i++) {
      if (this.hipWaistValue <= ranges[i]) {
        const position =
          (i + this.hipWaistValue / ranges[i]) * (100 / ranges.length);
        return `calc(${position}% - 15px)`;
      }
    }

    return "calc(100% - 15px)";
  }
}
function mapBmiToGraph(
  bmiValue: number,
  bmiThresholds: number[],
  graphThresholds: number[]
): number {
  if (bmiThresholds.length !== graphThresholds.length) {
    throw new Error("Threshold arrays must have the same length");
  }

  // Find the corresponding range in bmiThresholds
  for (let i = 1; i < bmiThresholds.length; i++) {
    if (bmiValue <= bmiThresholds[i]) {
      const start1 = bmiThresholds[i - 1];
      const end1 = bmiThresholds[i];
      const start2 = graphThresholds[i - 1];
      const end2 = graphThresholds[i];

      // Map the value proportionally to the graph thresholds
      const mappedValue =
        start2 + ((bmiValue - start1) / (end1 - start1)) * (end2 - start2);
      return mappedValue;
    }
  }

  // If the value exceeds the last threshold, map it to the last position
  return graphThresholds[graphThresholds.length - 1];
}
