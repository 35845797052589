import {Component, OnInit} from '@angular/core';
import {IUserProfile, IUserProfileUpdate} from 'interfaces';
import {UserService} from '../api/users/users';
import {FormBuilder} from '@angular/forms';
import {IAddress} from '../../../interfaces/orders';
import {OrdersService} from '../api/orders/orders';
import {TranslateService} from '@ngx-translate/core';
import {ActivatedRoute} from "@angular/router";
import {UtilityService} from "../utility.service";
import {AuthenticationService} from "../api/login/authentication";
import { AppComponent } from "../app.component";

@Component({
  selector: 'app-account-details',
  templateUrl: './account-details.component.html',
  styleUrls: ['./account-details.component.css']
})
export class AccountDetailsComponent implements OnInit {

  constructor(
    private orderService: OrdersService, 
    private userService: UserService, 
    private formBuilder: FormBuilder, 
    private translate: TranslateService, 
    private utils: UtilityService,
    public app: AppComponent, 
    public auth: AuthenticationService
  ) {
  }

  editAccountForm = this.formBuilder.group({
    first_name: '',
    last_name: '',
    street_name: '',
    street_number: '',
    zip_code: '',
    city: '',
    country: '',
    email: '',
    date_of_birth: '',
    phone_number: '',
    street_number_extension: ''
  });
  accountName = '';
  user: IUserProfile;
  address: IAddress;
  partnerImgSrc = '';

  async ngOnInit() {
    const response = await this.userService.GetUser();
    this.user = response.data;
    this.address = this.user.addresses.slice(-1)[0];
    this.editAccountForm.get('first_name').setValue(this.user.first_name);
    this.editAccountForm.get('last_name').setValue(this.user.last_name);
    this.editAccountForm.get('city').setValue(this.address.city);
    this.editAccountForm.get('country').setValue(this.address.country);
    this.editAccountForm.get('street_name').setValue(this.address.street_name);
    this.editAccountForm.get('street_number').setValue(this.address.street_number);
    this.editAccountForm.get('zip_code').setValue(this.address.zip_code);
    this.editAccountForm.get('email').setValue(this.user.email);
    this.editAccountForm.get('phone_number').setValue(this.user.phone_number);
    this.editAccountForm.get('date_of_birth').setValue(this.user.date_of_birth);
    this.accountName = this.user.first_name.concat(' ', this.user.last_name);
    if (this.app.partnerVersion != ''){
      this.partnerImgSrc = '-' + this.app.partnerVersion;
    }
  }

  async onSubmit() {
    const update_user_boi: IUserProfileUpdate = {
      first_name: this.editAccountForm.get('first_name').value,
      last_name: this.editAccountForm.get('last_name').value,
      email: this.editAccountForm.get('email').value.toLowerCase(),
      date_of_birth: this.editAccountForm.get('date_of_birth').value,
      phone_number: this.editAccountForm.get('phone_number').value
    };
    const response = await this.userService.UpdateUser(update_user_boi).then(data => {
      this.utils.showSuccess('UPDATE_USER_INFO', 'UPDATE_USER_INFO');
    }).catch(error => {
      this.utils.showError('UPDATE_USER_INFO', 'UPDATE_USER_INFO');
    });

    const update_address: IAddress = {
      country: this.editAccountForm.get('country').value,
      city: this.editAccountForm.get('city').value,
      street_name: this.editAccountForm.get('street_name').value,
      street_number: this.editAccountForm.get('street_number').value,
      zip_code: this.editAccountForm.get('zip_code').value,
      street_number_extension: this.editAccountForm.get('street_number_extension').value
    };
    const response2 = await this.orderService.UpdateAddress(update_address).then(data => {
      this.utils.showSuccess('UPDATE_USER_ADDRESS_SUCCESS', 'UPDATE_USER_ADDRESS_SUCCESS');
    }).catch(error => {
      this.utils.showError('UPDATE_USER_ADDRESS_ERROR', 'UPDATE_USER_ADDRESS_ERROR');
    });
    this.accountName = this.editAccountForm.get('first_name').value.concat(' ', this.editAccountForm.get('last_name').value);

  }

  logout() {
    this.app.logOut();
  }
}
