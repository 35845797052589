import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from '../api/login/authentication';
import { AppComponent } from '../app.component';

@Component({
  selector: 'app-log-out-page',
  templateUrl: './not-logged-in-page.component.html',
  styleUrls: ['./not-logged-in-page.component.css']
})
export class NotLoggedInComponent implements OnInit {

  constructor(private authService: AuthenticationService, public app: AppComponent) { }

  ngOnInit() {
  }
}
