import {Component, OnInit} from '@angular/core';
import {IDashboardDietary, IEyyo} from "../../../interfaces/advice";
import {DietaryDashService} from "../api/calculations/dietarydash";
import {ActivatedRoute} from "@angular/router";
import {TranslateService} from "@ngx-translate/core";

@Component({
  selector: 'app-healthy-substances',
  templateUrl: './healthy-substances.component.html',
  styleUrls: ['./healthy-substances.component.css']
})
export class HealthySubstancesComponent implements OnInit {

  constructor(public dietaryDashService: DietaryDashService, private route: ActivatedRoute, private translate: TranslateService) {
  }

  resultText = '';
  healthySubstances;
  range;
  dashBacteria: IEyyo;
  dietaryAdvice: IDashboardDietary[];

  async ngOnInit() {
    this.dashBacteria = JSON.parse(sessionStorage.getItem('Dashboard'));
    console.log(this.dashBacteria);
    this.healthySubstances = this.dashBacteria.graphs.find(x => x.graph === 'Production Of').value;
    this.range = this.dashBacteria.graphs.find(x => x.graph === 'Production Of').range;
    const testKitID = Number(this.route.snapshot.paramMap.get('id'));
    this.dietaryAdvice = await (await this.dietaryDashService.GetDietaryAdvice(testKitID, 'Production of health-promoting microbial substances')).data;
    this.resultSampleText();
  }

  getBacteriaLevel() {
    const dif = (this.range[1] - this.range[0]) / 70 * 15;
    const refUp = this.range[1] + dif; // potential for gas production
    const refLow = this.range[0] - dif; // potential for gas production

    let cal = (this.healthySubstances - refLow) / (refUp - refLow) * 100;
    if (cal < 0) {
      cal = 0;
    }
    if (cal > 100) {
      cal = 100;
    }
    return 'calc(' + cal + '% - 15px)';
  }

  resultSampleText() {
    if (this.healthySubstances < this.range[0]) {
      this.createResultString('PRODUCTION_OF_HEALTH.BE_AWARE_RESULT');
      return;
    }
    if (this.healthySubstances > this.range[1]) {
      this.createResultString('PRODUCTION_OF_HEALTH.GREAT_RESULT');
      return;
    }
    this.createResultString('PRODUCTION_OF_HEALTH.NORMAL_RESULT');
    return;
  }

  createResultString(tag: string) {
    this.translate.get(tag).subscribe((text: string) => {
      this.resultText = text;
    });
  }

}
