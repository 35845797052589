import { Component, OnInit } from "@angular/core";
import { Chart } from "chart.js";
import { TranslateService } from "@ngx-translate/core";
import { DashboardComponent } from "../dashboard/dashboard.component";
import { DashboardService } from "../api/calculations/dashboard";
import { ActivatedRoute, Router } from "@angular/router";
import { AppComponent } from "../app.component";
import { SurveyService } from "../api/survey/survey";

@Component({
  selector: "app-bmi",
  templateUrl: "./bmi.component.html",
  styleUrls: ["./bmi.component.css"],
})
export class BmiComponent implements OnInit {
  constructor(
    private dashboardService: DashboardService,
    private route: ActivatedRoute,
    public app: AppComponent,
    private router: Router
  ) {}
  bmiValue: any;
  testKitID;
  importantSurveyQuestions = [282, 283, 284, 285];

  loading: boolean = true;
  partnerImgSrc = "";

  newSurvey: boolean = false;
  hipWaistRanges: Array<{
    label: string;
    min: number;
    max: number;
    className: string;
  }>;
  hipWaistValue: number;
  isFemale: boolean;
  async ngOnInit() {
    try {
      if (this.app.partnerVersion != "") {
        this.partnerImgSrc = "-" + this.app.partnerVersion;
      }
      this.testKitID = Number(this.route.snapshot.paramMap.get("id"));
      this.bmiValue = (await this.dashboardService.GetBmi(this.testKitID)).data;

      this.hipWaistRanges = this.isFemale
        ? [
            {
              label: "Low risk",
              min: 0,
              max: 0.79,
              className: "bmi-2",
            },
            {
              label: "Moderate risk",
              min: 0.8,
              max: 0.84,
              className: "bmi-1",
            },
            {
              label: "High risk",
              min: 0.85,
              max: 10,
              className: "bmi-4",
            },
          ]
        : [
            {
              label: "Low risk",
              min: 0,
              max: 0.89,
              className: "bmi-2",
            },
            {
              label: "Moderate risk",
              min: 0.9,
              max: 0.99,
              className: "bmi-1",
            },
            {
              label: "High risk",
              min: 1.0,
              max: 10,
              className: "bmi-4",
            },
          ];
      try {
        const response = await this.dashboardService.GetHipWaist(
          this.testKitID
        );
        this.hipWaistValue = response.data["hip_waist_ratio"];
        console.log("Fetched hipWaistValue:", this.hipWaistValue);
      } catch (error) {
        console.error("Failed to fetch Hip/Waist data:", error);
        this.hipWaistValue = null;
      }
      this.importantSurveyQuestions = [282, 283];
      if (this.bmiValue && this.hipWaistValue) {
        this.importantSurveyQuestions.push(284, 285);
      }
      await this.fetchAndLogSurveyAnswers();
    } catch (error) {
      console.error("Error during initialization:", error);
    } finally {
      // Ensure loading is set to false after all data is fetched
    }
  }
  getHipWaistLevel(): string {
    if (!this.hipWaistValue || this.hipWaistRanges.length === 0) return "0%";

    const totalWidth = 100; // Total width of the chart in percentage
    const rangeWidth = totalWidth / this.hipWaistRanges.length;

    // Find the appropriate range for the value
    for (let i = 0; i < this.hipWaistRanges.length; i++) {
      const range = this.hipWaistRanges[i];
      if (this.hipWaistValue >= range.min && this.hipWaistValue <= range.max) {
        // Calculate the position within the range
        const offsetWithinRange =
          ((this.hipWaistValue - range.min) / (range.max - range.min)) *
          rangeWidth;
        const position = i * rangeWidth + offsetWithinRange;
        return `calc(${position}% - 15px)`; // Adjust for needle width
      }
    }

    return "0%"; // Default fallback
  }
  getBMILevel() {
    if (!this.bmiValue) {
      return "calc(0% - 15px)"; // Default position for undefined BMI
    }

    let value = 0; // Default position

    // Determine the position based on BMI value
    if (this.bmiValue < 18.5) {
      value = 12.5; // Midpoint of the Underweight range
    } else if (this.bmiValue >= 18.5 && this.bmiValue < 25) {
      value = 37.5; // Interpolated value within the Normal Weight range
    } else if (this.bmiValue >= 25 && this.bmiValue < 30) {
      value = 62.5; // Interpolated value within the Overweight range
    } else if (this.bmiValue >= 30) {
      value = 87.5; // Midpoint of the Obese range
    }

    // Adjust for exact values at boundaries
    if (this.bmiValue === 25) value = 50;
    if (this.bmiValue === 30) value = 75;

    return `calc(${value}% - 15px)`; // Adjust for needle width
  }

  async fetchAndLogSurveyAnswers() {
    try {
      // Replace with an appropriate kitId for your testing
      const kitId = this.testKitID; // Example kit ID, update it accordingly
      const surveyService = new SurveyService();
      const surveyAnswers = await surveyService.GetUserServeyAnswers(kitId);
      this.newSurvey = surveyAnswers.data.some(
        (item) => item.question_id === 275
      );

      const genderAnswer = surveyAnswers.data.find(
        (item) => item.question_id === 279
      );
      if (genderAnswer && genderAnswer.answer_text === "Female") {
        this.isFemale = true;
      } else {
        this.isFemale = false;
      }
      console.log(this.newSurvey);
      console.log("User Survey Answers:", surveyAnswers.data);
    } catch (error) {
      console.error("Error fetching user survey answers:", error);
    } finally {
      this.loading = false;
    }
  }
}
