import { Component, OnInit } from '@angular/core';
import {LangChangeEvent, TranslateService} from "@ngx-translate/core";

@Component({
  selector: 'app-process',
  templateUrl: './process.component.html',
  styleUrls: ['./process.component.css']
})
export class ProcessComponent implements OnInit {
  enUrl = 'https://storage.googleapis.com/ib_public_storage/stool%20sample.pdf';
  nlUrl = 'https://storage.googleapis.com/ib_public_storage/ontlastingsmonster.pdf';
  baseSteps = [
    {
      id: 1,
      alias: 'purchase',
      title: '6_STEPS.PURCHASE_TEST',
      image: '../../assets/shopping-cart2.png',
      description: '6_STEPS.PURCHASE_TEST_TEXT'
    },
    {
      id: 2,
      alias: 'code',
      title: '6_STEPS.CONNECT_PROFILE',
      image: '../../assets/barcode_variant.png',
      description: '6_STEPS.CONNECT_PROFILE_TEXT'
    },
    {
      id: 3,
      alias: 'food',
      title: '6_STEPS.FILL_FOOD_DIARY',
      image: '../../assets/survey_list.png',
      description: '6_STEPS.FILL_FOOD_DIARY_TEXT'
    },
    {
      id: 4,
      alias: 'sample',
      title: '6_STEPS.TAKE_SAMPLE',
      image: '../../assets/cotton-swab.png',
      description: '6_STEPS.TAKE_SAMPLE_TEXT'
    },
    {
      id: 5,
      alias: 'survey',
      title: '6_STEPS.TAKE_SURVEY',
      image: '../../assets/food_list.png',
      description: '6_STEPS.TAKE_SURVEY_TEXT'
    },
    {
      id: 6,
      alias: 'results',
      title: '6_STEPS.VIEW_RESULTS',
      image: '../../assets/bar-chart.png',
      description: '6_STEPS.VIEW_RESULTS_TEXT'
    }
  ];

  steps = [
    {
      id: 1,
      alias: 'purchase',
      title: '6_STEPS.PURCHASE_TEST',
      image: '../../assets/shopping-cart2.png',
      description: '6_STEPS.PURCHASE_TEST_TEXT'
    },
    {
      id: 2,
      alias: 'code',
      title: '6_STEPS.CONNECT_PROFILE',
      image: '../../assets/barcode_variant.png',
      description: '6_STEPS.CONNECT_PROFILE_TEXT'
    },
    {
      id: 3,
      alias: 'food',
      title: '6_STEPS.FILL_FOOD_DIARY',
      image: '../../assets/survey_list.png',
      description: '6_STEPS.FILL_FOOD_DIARY_TEXT'
    },
    {
      id: 4,
      alias: 'sample',
      title: '6_STEPS.TAKE_SAMPLE',
      image: '../../assets/cotton-swab.png',
      description: '6_STEPS.TAKE_SAMPLE_TEXT'
    },
    {
      id: 5,
      alias: 'survey',
      title: '6_STEPS.TAKE_SURVEY',
      image: '../../assets/food_list.png',
      description: '6_STEPS.TAKE_SURVEY_TEXT'
    },
    {
      id: 6,
      alias: 'results',
      title: '6_STEPS.VIEW_RESULTS',
      image: '../../assets/bar-chart.png',
      description: '6_STEPS.VIEW_RESULTS_TEXT'
    }
  ];

  constructor(private translate: TranslateService) {
    this.updateSteps();
    this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      this.updateSteps();
    });
  }

  ngOnInit() {
  }

  openInfo() {
    console.log(this.translate.currentLang);
    if (this.translate.currentLang === 'en') {
      window.open(String(this.enUrl), '_blank');
    } else {
      window.open(String(this.nlUrl), '_blank');
    }
  }

  updateSteps() {
    let i = 0;
    for (const step of this.baseSteps) {
      this.translate.get(step.title).subscribe((text: string) => {
        this.steps[i].title = text;
      });
      this.translate.get(step.description).subscribe((text: string) => {
        this.steps[i].description = text;
      });
      i++;
    }
  }

}
