import { Component, OnInit, Input } from "@angular/core";
import BacteriaDivision from "../../assets/json/bacteriaDivision.json";
import { AppComponent } from "../../app/app.component";
import top35 from "../../assets/json/top35.json";
import { IEyyo } from "interfaces/advice";

@Component({
  selector: "app-comparison-bacteria-levels",
  templateUrl: "./comparison-bacteria-levels.component.html",
  styleUrls: ["./comparison-bacteria-levels.component.css"],
})
export class ComparisonBacteriaLevelsComponent implements OnInit {
  @Input() data: any = {};

  legendColorsVariants: any = {
    default: ["purple", "green", "yellow", "blue", "light_purple"],
    iprobio: [
      "iprobio_blue",
      "iprobio_blue_light",
      "iprobio_green",
      "iprobio_red",
      "iprobio_blue_dark",
    ],
    patris_health: {
      iprobio_blue: "#1150a0",
      iprobio_blue_light: "#4e91d4",
      iprobio_green: "#4caf50",
      iprobio_red: "#ff6b6b",
      iprobio_blue_dark: "#0d3c78",
    },
  };
  legendColors: any = this.legendColorsVariants["default"];
  goodBacteria: any[] = [];
  goodBacteriaTEST: any[] = [];
  badBacteria: any[] = [];
  relevantBacteria: any[] = [];

  division: any;
  dashBacteria: IEyyo;

  bacteriaDescription: any;

  partnerImgSrc: string = "";

  constructor(public app: AppComponent) {}

  ngOnInit() {
    // Pick correct marker colors
    if (this.app.partnerVersion != "") {
      this.legendColors = this.legendColorsVariants[this.app.partnerVersion];
      this.partnerImgSrc = "-" + this.app.partnerVersion;
    }
    this.dashBacteria = JSON.parse(sessionStorage.getItem("Dashboard"));
    this.division = BacteriaDivision;
    this.divideBacterias();
  }

  toggleBacteriaDescription(bacteria) {
    bacteria
      ? (this.bacteriaDescription = bacteria)
      : (this.bacteriaDescription = "");
  }
  sharesValue(item: any, i: any, values: any) {
    let flip = false;
    values.forEach((element: any, index: any) => {
      if (element !== item) {
        let tempItemLevel = this.getBacteriaLevel(
          item.range[0],
          item.range[1],
          item.value
        ).replace("%", "");
        let tempElementLevel = this.getBacteriaLevel(
          element.range[0],
          element.range[1],
          element.value
        ).replace("%", "");
        let diff = Math.abs(Number(tempItemLevel) - Number(tempElementLevel));

        if (diff < 1) {
          if (i > index) flip = true;
        }
      }
    });

    return flip;
  }
  divideBacterias() {
    const lowercasedTop35 = top35["top35"].map((bacteria: string) =>
      bacteria.toLowerCase()
    );
    console.log(lowercasedTop35);

    this.data.forEach((elem: any) => {
      elem.top35.forEach((element: any) => {
        let temp = {
          name: element.bacteria,
          values: [],
        };
        let tempValues = {
          date: elem.date,
          range: element.range,
          value: element.value,
        };
        temp.values.push(tempValues);
        console.log(temp);
        const bacteriaName = (element.bacteria as string).toLowerCase();

        if (
          Object.values(this.division.good)
            .map((name: string) => name.toLowerCase())
            .includes(bacteriaName)
        ) {
          if (
            bacteriaName === "christensenellaceae" ||
            (!lowercasedTop35.includes("christensenellaceae") &&
              bacteriaName === "christensenellaceae_r-7_group")
          ) {
            const bacteriaToUse =
              bacteriaName === "christensenellaceae"
                ? "Christensenellaceae"
                : "Christensenellaceae_R-7_group";
          } else {
            console.log("yey");
            if (lowercasedTop35.includes(bacteriaName)) {
              const existingBacteria = this.goodBacteria.find(
                (x) => x.name.toLowerCase() === bacteriaName
              );
              if (existingBacteria) {
                existingBacteria.values.push(tempValues);
              } else {
                this.goodBacteria.push(temp);
              }
            }
          }
        }
        if (Object.values(this.division.bad).indexOf(element.bacteria) > -1) {
          if (this.badBacteria.find((x) => x.name === element.bacteria)) {
            let existingBacteria = this.badBacteria.find(
              (x) => x.name === element.bacteria
            );
            existingBacteria.values.push(tempValues);
          } else {
            this.badBacteria.push(temp);
          }
        }
        if (
          Object.values(this.division.relevant).indexOf(element.bacteria) > -1
        ) {
          if (this.relevantBacteria.find((x) => x.name === element.bacteria)) {
            let existingBacteria = this.relevantBacteria.find(
              (x) => x.name === element.bacteria
            );
            existingBacteria.values.push(tempValues);
          } else {
            this.relevantBacteria.push(temp);
          }
        }
      });
    });
    this.goodBacteria.sort((a, b) => {
      const bacteriaA = a.name.toLowerCase();
      const bacteriaB = b.name.toLowerCase();
      return bacteriaA.localeCompare(bacteriaB);
    });
  
    this.badBacteria.sort((a, b) => {
      const bacteriaA = a.name.toLowerCase();
      const bacteriaB = b.name.toLowerCase();
      return bacteriaA.localeCompare(bacteriaB);
    });
  
    this.relevantBacteria.sort((a, b) => {
      const bacteriaA = a.name.toLowerCase();
      const bacteriaB = b.name.toLowerCase();
      return bacteriaA.localeCompare(bacteriaB);
    });
    this.relevantBacteria.forEach((elem) => {
      if (elem.name === "Clostridium_sensu_stricto_1") {
        elem.name = "Clostridium";
      }
    });
    this.goodBacteria.forEach((elem) => {
      if (elem.name === "Christensenellaceae_R-7_group") {
        elem.name = "Christensenellaceae";
      }
    });
    console.log(this.goodBacteria);
    console.log(this.relevantBacteria);
  }

  getBacteriaLevel(range1,range2,value): string {
  
    const dif = ((range2 - range1) / 70) * 15;
    const refUp = range2 + dif; // Adjusted upper reference
    const refLow = range1 - dif; // Adjusted lower reference
  
    // Calculate the percentage position
    let cal =
      ((value - range1) * 70) / (range2 - range1) + 15;
  
    // Clamp the value between 2% and 98%
    if (cal < 0) {
      cal = 0;
    }
    if (cal > 100) {
      cal = 100;
    }
  
    return `calc(${cal}% - 0px)`;
  }
  
  
}
