import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from '../api/login/authentication';
import { AppComponent } from '../app.component';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute } from "@angular/router";
import { UserService } from "../api/users/users";
import { UtilityService } from "../utility.service";
import { UtilsService } from "../api/utils/utils";
import { IEmailUnsubscribeStatsCreate } from 'interfaces';

@Component({
  selector: 'app-log-out-page',
  templateUrl: './unsubscribe-email.component.html',
  styleUrls: ['./unsubscribe-email.component.css']
})
export class UnsubscribeEmail implements OnInit {

  constructor(private authService: AuthenticationService, 
              private formBuilder: FormBuilder, 
              private route: ActivatedRoute,
              private userService: UserService,
              public utils: UtilityService,
              private util: UtilsService,
              public app: AppComponent) { }

  userEmail: string

  unsubscribeForm = this.formBuilder.group({
    unsubscribeReason: 0
  });

  ngOnInit() {
    this.userEmail = this.route.snapshot.paramMap.get('email');
  }

  throwError(){
    this.utils.showWarning("UNUSED_EMAIL", "UNUSED_EMAIL");
  }

  async onSubmit() {

    const reasons = new Map([
      [1, 'too many emails'],
      [2, 'content too long'],
      [3, 'not relevant'],
      [4, 'other']
    ]);
    
    let response = await this.userService.UnsubscribeFromEmailList(this.userEmail)

    if (typeof(response) === 'number') {
      this.utils.showWarning("UNUSED_EMAIL", "UNUSED_EMAIL")
    } else {
      this.utils.showSuccess("UNSUBSCRIBE_SUCCESS", "UNSUBSCRIBE_SUCCESS");
      let unsubValue = Number(this.unsubscribeForm.get('unsubscribeReason').value);
      let chosenReason = reasons.get(unsubValue);

      let unsubStat: IEmailUnsubscribeStatsCreate = {
        email: this.userEmail,
        reason: chosenReason
      }
      if (unsubValue == 4) {
        unsubStat.open_answer = (document.getElementById('openChoice') as HTMLInputElement).value
      }

      let statsResponse = this.util.UnsubscribeReason(unsubStat);

      window.setInterval(() => {
        window.location.replace("https://innerbuddies.com/nl");
      }, 3000)
    }
  }
}
