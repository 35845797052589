import {Injectable} from '@angular/core';
import {ToastrService} from 'ngx-toastr';
import {TranslateService} from "@ngx-translate/core";

@Injectable({
  providedIn: 'root'
})
export class UtilityService {


  constructor(private toastrService: ToastrService, private translate: TranslateService) {
  }

  public showSuccess(title: string, message: string) {
    this.translate.get('NOTIFICATIONS.'.concat(title, '.TITLE')).subscribe((titleText: string) => {
      this.translate.get('NOTIFICATIONS.'.concat(message, '.MESSAGE')).subscribe((messageText: string) => {
        this.toastrService.success(messageText.toString(), titleText.toString());
      });
    });
  }

  public showInfo(title: string, message: string): void {
    this.translate.get('NOTIFICATIONS.'.concat(title, '.TITLE')).subscribe((titleText: string) => {
      this.translate.get('NOTIFICATIONS.'.concat(message, '.MESSAGE')).subscribe((messageText: string) => {
        this.toastrService.info(messageText.toString(), titleText.toString());
      });
    });
  }

  public showWarning(title: string, message: string): void {
    this.translate.get('NOTIFICATIONS.'.concat(title, '.TITLE')).subscribe((titleText: string) => {
      this.translate.get('NOTIFICATIONS.'.concat(message, '.MESSAGE')).subscribe((messageText: string) => {
        this.toastrService.warning(messageText.toString(), titleText.toString());
      });
    });
  }

  public showError(title: string, message: string): void {
    this.translate.get('NOTIFICATIONS.'.concat(title, '.TITLE')).subscribe((titleText: string) => {
      this.translate.get('NOTIFICATIONS.'.concat(message, '.MESSAGE')).subscribe((messageText: string) => {
        this.toastrService.error(messageText.toString(), titleText.toString());
      });
    });
  }
}
