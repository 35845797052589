import { Theme } from './symbols';

export const patrisHealthTheme: Theme = {
  name: 'patris_health',
  properties: {
    '--color-profile-tab': '#1150a0',  // Main theme color
    '--navbar-img': 'url("assets/patris-health-logo.png")',
    '--footer-img': 'url("assets/patris-health-logo.png")',
    '--navbar-color': '#0e3d7a',  // Darker shade for nav elements
    '--navbar-light-color': '#ffffff',  // Light text color
    '--conditions-color': '#0d4b8a',  // Slightly lighter than the main color
    '--navigation-color': '#0d4b8a',  // Consistent with conditions color
    '--contact-color': '#0d4b8a',
    '--copyrights-color': '#0c3b6b',  // Darker accent for text
    '--btn-dark-color': 'linear-gradient(135deg, #0e4c8a 0%, #4e91d4 100%)', // Gradient with main color accents
    '--btn-light-color': '#4e91d4a1',  // Semi-transparent light blue
    '--btn-light-color-active': '#4e91d4',  // Full opacity of light blue
    '--table-header-color': '#1150a0',  // Matches primary color
    '--kit-code-btn-color': '#1150a0',
    '--btn-fill-in-color': '#4e91d48c',  // Light blue with opacity
    '--btn-fill-in-color-active': '#4e91d4',
    '--food-diary-color': '#1150a0',  // Primary color for high importance
    '--food-diary-color-light': '#4e91d43b',  // Very light blue for backgrounds
    '--survey-color': '#1150a0',
    '--survey-color-complete': '#0a3d7c',  // Dark blue for completed surveys
    '--dashboard-container': '#1150a021',  // Lightened primary color for containers
    '--dashboard-container-hover': '#1150a054',  // Slightly stronger hover effect
    '--sub-dropdown-color': '#1150a0',
    '--dropdown-box': '#e6f0fa',  // Very light blue for dropdown boxes
    '--compound-container-color': '#e6f0fa',
    '--normal-color': '#5194c5',  // Soft blue for “normal” indications
    '--okay-color': '#91bce0',  // Lighter blue for “okay” indications
    '--great-color': '#4caf50',  // Bright blue for “great”
    '--bad-color': '#ff6161',  // Red for negative/bad status
    '--aware-color': '#a5c5e0',  // Muted light blue for awareness notes
    '--warning-color': '#ff6161',  // Orange for warnings
    '--warning-color-fibre': '#ff6161',  // Slightly brighter orange for emphasis
    '--take-home-bubble-color': '#5194c5',  // Matches “normal” color for callouts
    '--rectangle-color': '#d9e5f0',  // Light gray-blue for rectangles
    '--description-color': '#0e3d7a2b',  // Very light overlay of the navbar color
    '--protein-color': '#d9e5f0',  // Matches rectangle color for consistency
    '--bmi-1-color': '#1150a026',  // Very light primary color for BMI level 1
    '--bmi-2-color': '#1150a040',  // Gradual increase in opacity for BMI 2
    '--bmi-3-color': '#1150a07d',  // Higher opacity for BMI level 3
    '--bmi-4-color': '#1150a0c7',  // Strong opacity for BMI level 4
    '--bmi-5-color': '#1150a0',  // Full primary color for BMI level 5
    '--recomendations-color': '#1150a0',  // Primary color for recommendations
    '--img-placeholder-color': '#d9e5f0',  // Consistent light background color
    '--fibre-color': '#a5c5e08f',  // Light semi-transparent color for fiber
    '--green-color': '#4caf50',  // Green for positive or good status
    '--yellow-color': '#ff6161',
    '--footer-justify-content': 'space-between'
  }
};
