import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-health-and-diseases',
  templateUrl: './health-and-diseases.component.html',
  styleUrls: ['./health-and-diseases.component.css']
})
export class HealthAndDiseasesComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
