import {Component, OnInit} from '@angular/core';
import 'chartjs-chart-box-and-violin-plot/build/Chart.BoxPlot.js';
import {DietaryDashService} from '../api/calculations/dietarydash';
import {ActivatedRoute} from '@angular/router';
import {IDashboardDietary, IEyyo} from '../../../interfaces/advice';
import {TestsService} from '../api/test/bacteria';
import {IBacteria} from '../../../interfaces/tests';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-bifidobacterium',
  templateUrl: './bifidobacterium.component.html',
  styleUrls: ['./bifidobacterium.component.css']
})
export class BifidobacteriumComponent implements OnInit {

  constructor(private dietaryDashService: DietaryDashService, private testsService: TestsService, private route: ActivatedRoute, private translate: TranslateService) {
  }

  specificBacteria;
  foodAdvicePresent;
  ref;
  page;
  bacteria: IBacteria;
  boxplotChart: any;
  dashBacteria: IEyyo;
  dietaryAdvice: IDashboardDietary[];
  resultText = '';

  // category1 = "Inulin rich products:";
  // category2 = "Probiotic products:";
  categories = [{
    id: 1,
    name: 'Inulin rich products:',
    types: [
      {
        id: 1,
        name: 'vegetables',
        types: ['chicory', 'artichokes', 'salsify', 'asparagus', 'leeks', 'onion', 'garlic']
      },
      {
        id: 2,
        name: 'fruit',
        types: ['(unripe) bananas']
      }
    ]
  },
    {
      id: 2,
      name: 'Probiotic products:',
      types: [
        {
          id: 1,
          name: 'dairy products',
          types: ['yoghurt', 'quark', 'kefir', 'cultured drinks']
        },
        {
          id: 2,
          name: 'fermeneted meat and fish products',
          types: ['corned beef', 'sour herring', 'pickled eggs']
        },
        {
          id: 3,
          name: 'kimchi products',
          types: ['ginger beer', 'kombucha', 'misosoup', 'sauerkraut']
        }
      ]
    }
  ];

  async ngOnInit() {

    this.page = this.route.snapshot.paramMap.get('page');
    const testKitID = Number(this.route.snapshot.paramMap.get('id'));
    this.dashBacteria = JSON.parse(sessionStorage.getItem('Dashboard'));
    this.specificBacteria = this.dashBacteria.top35.find(x => x.bacteria === this.page).value;
    this.ref = this.dashBacteria.top35.find(x => x.bacteria === this.page).range;
    this.bacteria = (await this.testsService.GetSingleBacteria(this.page)).data;
    this.setText();

  }

  getBacteriaLevel() {
    if (Number(this.specificBacteria) === 0) {
      // Return 0 position when temp.value is 0
      return "calc(0% - 15px)";
    }
    const dif = (this.ref[1] - this.ref[0]) / 70 * 15;
    const refUp = this.ref[1] + dif; // gut health
    const refLow = this.ref[0] - dif; // gut health

    let cal = (this.specificBacteria - refLow) / (refUp - refLow) * 100;
    if (cal < 0) {
      cal = 0;
    }
    if (cal > 100) {
      cal = 100;
    }
    return 'calc(' + cal + '% - 15px)';
  }

  async resultSampleText() {
    const bactLvls = this.bacteriaValues();
    if (this.specificBacteria < this.ref[0]) {
      this.createResultString(this.createTag('BE_AWARE_RESULT'));
      this.dietaryAdvice = await (await this.dietaryDashService.GetDietaryAdvicePerBacteria(
        Number(this.route.snapshot.paramMap.get('id')),
        this.page)
      ).data;
      console.log(this.dietaryAdvice);
      this.foodAdvicePresent = true;
      return 'be_aware';
    }
    if (this.specificBacteria > this.ref[1]) {
      this.createResultString(this.createTag('GREAT_RESULT'));

      return 'great';
    }
    this.createResultString(this.createTag('NORMAL_RESULT'));

    return 'normal';
  }

  bacteriaValues(): number[] {
    if (this.specificBacteria === 'Bifidobacterium') {
      return [0.01334715, 0.062869284];
    }
    if (this.specificBacteria === 'Akkermansia') {
      return [0.021071317, 0.1653548];
    }
    if (this.specificBacteria === 'Lactobacillus') {
      return [0.013819647, 0.202785177];
    }
    return [0.038934236, 0.156664786];
  }

  createTag(tag: string): string {
    return this.page.toUpperCase().concat('.', tag);
  }

  createResultString(tag: string) {
    this.translate.get(tag).subscribe((text: string) => {
        this.resultText = text;
      });
  }

  setText() {
    this.resultSampleText();
  }
}
