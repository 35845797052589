import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { LangChangeEvent, TranslateService } from "@ngx-translate/core";
import { Router } from "@angular/router";
import * as enData from './foodData_en.json';
import { AppComponent } from '../app.component';
import * as nlData from './foodData_nl.json';
import * as itData from './foodData_it.json';
import * as skData from './foodData_sk.json';
import * as deData from './foodData_de.json';


interface LanguageData {
  day: number;
  meals: {
    name: string;
    items: {
      food_item: {
        title: string;
      };
      quantity: number;
      unit: string;
    }[];
  }[];
}

interface LanguageDataWithDefault extends LanguageData {
  default: LanguageData[];
}

type EnData = typeof enData;
type NlData = typeof nlData;
type ITData = typeof itData;
type SKData = typeof skData;


@Component({
  selector: 'app-example-food-diary',
  templateUrl: './example-food-diary.component.html',
  styleUrls: ['./example-food-diary.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class ExampleFoodDiaryComponent implements OnInit {
  selectedDay = 0;

  constructor(
    private translate: TranslateService,
    public app: AppComponent
  ) { }

  ngOnInit() {
  }

  getFoodDataForDay(day: number) {
    return this.getFoodData().filter((item: any) => item.day === day + 1)[0].meals;
  }

  getFoodData() {
    return this.app.selectedLanguage === "nl" ? (nlData as unknown as LanguageDataWithDefault).default
      : this.app.selectedLanguage === "it" ? (itData as unknown as LanguageDataWithDefault).default
      : this.app.selectedLanguage === "sk" ? (skData as unknown as LanguageDataWithDefault).default
      : this.app.selectedLanguage === "de" ? (deData as unknown as LanguageDataWithDefault).default
      : (enData as unknown as LanguageDataWithDefault).default;
  }

  showDay(day: number) {
    this.selectedDay = day;
  }
}