import { Component, OnInit, Input } from "@angular/core";
import { Chart } from "chart.js";
import { AppComponent } from "../../app/app.component";

@Component({
  selector: "app-comparison-diversity",
  templateUrl: "./comparison-diversity.component.html",
  styleUrls: ["./comparison-diversity.component.css"],
})
export class ComparisonDiversityComponent implements OnInit {
  @Input() data: any = {};

  doughnutChart1: any;
  doughnutChart2: any;
  doughnutChart3: any;
  shannonValue: any;
  gaugeColorVariants = {
    default: {
      normal: "#ad66d9",
      okay: "#cfbcf2",
      great: "#05e399",
      bad: "#fad408",
      aware: "#c8bfe7",
    },
    iprobio: {
      normal: "#62b3b3",
      okay: "#b5d2d2",
      great: "#6fd06f",
      bad: "#f299a1",
      aware: "#339d9c69",
    },
    patris_health: {
      normal: "#33ab9f",
      okay: "#81c784",
      great: "#66bb6a",
      bad: "#f44336",
      aware: "#b2dfdb",
    },
  };

  selectedColors = this.gaugeColorVariants["default"];
  graphData: any;
  shannonGraphs: any = [];
  legendColorsVariants: any = {
    default: ["purple", "green", "yellow", "blue", "light_purple"],
    iprobio: ["#62b3b3", "#b5d2d2", "#6fd06f", "#f299a1", "#339d9c"],
    patris_health: ["#62b3b3", "#b5d2d2", "#6fd06f", "#f299a1", "#339d9c"],
  }
  legendColors: any = this.legendColorsVariants['default'];

  constructor(public app: AppComponent) {}

  ngOnInit() {

    // Pick correct gauge colors
    if (this.app.partnerVersion != "") {
      this.selectedColors = this.gaugeColorVariants[this.app.partnerVersion];
      this.legendColors = this.legendColorsVariants[this.app.partnerVersion];
    }
    this.generateGraph();
    // this.generateShannonGraphs();
  }
  generateGraph() {
    let tempShannonValues = [];
    let tempShannonNeedles = [];
    let tempOtusValues = [];
    let tempOtusNeedles = [];
    let tempPielousValues = [];
    let tempPielousNeedles = [];

    this.data.forEach((element: any, index: any) => {
      let tempShannon = {
        data: [15, 70, 20],
        value: this.getDiversityScore(element.shannon),
        backgroundColor: [
          this.selectedColors["bad"],
          this.selectedColors["normal"],
          this.selectedColors["great"],
        ],
        hoverBackgroundColor: [
          this.selectedColors["bad"],
          this.selectedColors["normal"],
          this.selectedColors["great"],
        ],
        borderWidth: 0,
      };

      tempShannonValues.push(tempShannon);

      let tempNeedleShannon = {
        value: this.getDiversityScore(element.shannon),
        color: this.legendColors[index],
        length: 1.5,
        width: 15,
      };
      tempShannonNeedles.push(tempNeedleShannon);

      let tempOtus = {
        data: [15, 70, 15],
        value: this.getDiversityScore(element.otus),
        backgroundColor: [
          this.selectedColors["bad"],
          this.selectedColors["normal"],
          this.selectedColors["great"],
        ],
        hoverBackgroundColor: [
          this.selectedColors["bad"],
          this.selectedColors["normal"],
          this.selectedColors["great"],
        ],
        borderWidth: 0,
      };
      tempOtusValues.push(tempOtus);

      let tempNeedleOtus = {
        value: this.getDiversityScore(element.otus),
        color: this.legendColors[index],
        length: 1.5,
        width: 15,
      };
      tempOtusNeedles.push(tempNeedleOtus);

      let tempPielous = {
        data: [15, 70, 15],
        value: this.getDiversityScore(element.pielou),
        backgroundColor: [
          this.selectedColors["bad"],
          this.selectedColors["normal"],
          this.selectedColors["great"],
        ],
        hoverBackgroundColor: [
          this.selectedColors["bad"],
          this.selectedColors["normal"],
          this.selectedColors["great"],
        ],
        borderWidth: 0,
      };
      tempPielousValues.push(tempPielous);

      let tempNeedlePielous = {
        value: this.getDiversityScore(element.pielou),
        color: this.legendColors[index],
        length: 1.5,
        width: 15,
      };
      tempPielousNeedles.push(tempNeedlePielous);
    });

    this.createGaugeChart("shannon", tempShannonValues, tempShannonNeedles);

    this.createGaugeChart("otus", tempOtusValues, tempOtusNeedles);
    this.createGaugeChart("pielou", tempPielousValues, tempPielousNeedles);
  }
  getDiversityScore(value: any) {
    const upperRange = 6;
    let diversityScore = ((value + 3) / upperRange) * 100;
    if (diversityScore < 0) {
      diversityScore = 0;
    }
    if (diversityScore > 100) {
      diversityScore = 100;
    }

    return diversityScore;
  }
  getGraphConf(datasets: any) {
    return {
      type: "gauge",
      data: {
        datasets: datasets,
      },
      options: {
        interaction: {
          mode: "point",
        },
        responsive: true,
        needle: {
          radiusPercentage: 2,
          widthPercentage: 3.2,
        },
        valueLabel: {
          display: false,
          formatter: Math.round,
        },
        plugins: {
          datalabels: {
            display: true,
            formatter: function (value: any, context) {
              return "< " + Math.round(value);
            },
            color: function (context: any) {
              return context.dataset.backgroundColor;
            },
            //color: 'rgba(255, 255, 255, 1.0)',
            backgroundColor: "rgba(0, 0, 0, 1.0)",
            borderWidth: 0,
            borderRadius: 5,
            font: {
              weight: "bold",
            },
          },
        },
      },
    };
  }
  createGaugeChart(id, data, needles) {
    Chart.defaults.gauge = Chart.helpers.clone(Chart.defaults.doughnut);
    Chart.controllers.gauge = Chart.controllers.doughnut.extend({
      draw: function (ease: any) {
        const me = this;
        const ctx = me.chart.ctx;
        const chartArea = me.chart.chartArea;
        const opts = me.chart.options;
        const radius =
          Math.min(
            chartArea.right - chartArea.left,
            chartArea.bottom - chartArea.top,
          ) / 2;
        const cx = (chartArea.left + chartArea.right) / 2;
        const cy = chartArea.bottom;

        Chart.controllers.doughnut.prototype.draw.call(me, ease);

        // Draw the needles
        if (opts.gaugeNeedles) {
          opts.gaugeNeedles.forEach((needle: any) => {
            const angle =
              Math.PI +
              (Math.PI * (needle.value - opts.max)) / (opts.min - opts.max);
            const needleRadius = radius * needle.length;

            ctx.save();

            // Parameters for the needle
            const needleWidth = needle.width || 2;
            const needleColor = needle.color || "black";

            // Define the three vertices of the stretched triangle
            const tipX = cx - Math.cos(angle) * needleRadius;
            const tipY = cy + Math.sin(angle) * needleRadius;
            const baseLeftX =
              cx - (Math.cos(angle + Math.PI / 2) * needleWidth) / 2;
            const baseLeftY =
              cy + (Math.sin(angle + Math.PI / 2) * needleWidth) / 2;
            const baseRightX =
              cx - (Math.cos(angle - Math.PI / 2) * needleWidth) / 2;
            const baseRightY =
              cy + (Math.sin(angle - Math.PI / 2) * needleWidth) / 2;

            // Draw the black border (slightly larger triangle)
            ctx.beginPath();
            ctx.moveTo(tipX, tipY);
            ctx.lineTo(baseLeftX, baseLeftY);
            ctx.lineTo(baseRightX, baseRightY);
            ctx.closePath();
            ctx.lineWidth = 4; // Border width
            ctx.strokeStyle = "black";
            ctx.stroke();

            // Draw the needle (inner triangle)
            ctx.beginPath();
            ctx.moveTo(tipX, tipY);
            ctx.lineTo(baseLeftX, baseLeftY);
            ctx.lineTo(baseRightX, baseRightY);
            ctx.closePath();
            ctx.fillStyle = needleColor;
            ctx.fill();

            // Draw the circle at the base
            ctx.beginPath();
            ctx.arc(cx, cy, 10, 0, Math.PI * 2);
            ctx.fillStyle = needleColor; // Ensure the circle matches the needle color
            ctx.fill();

            ctx.restore();
          });
        }
      },
    });

    const ctx = (document.getElementById(id) as HTMLCanvasElement).getContext(
      "2d",
    );
    new Chart(ctx, {
      type: "gauge",
      data: {
        datasets: data,
      },
      options: {
        //@ts-ignore
        circumference: Math.PI,
        //@ts-ignore
        rotation: Math.PI,
        //@ts-ignore
        min: 0,
        //@ts-ignore
        max: 100,
        //@ts-ignore
        gaugeNeedles: needles,
      },
    });
  }
}
