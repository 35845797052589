import axios from 'axios';
import { Injectable } from '@angular/core';
import { IBacteria } from 'interfaces/tests';
import { IBacteriaGroup } from 'interfaces/tests';
import {environment} from '../../../environments/environment';

axios.defaults.baseURL = environment.url.api;

function authHeaders() {
  const token = localStorage.getItem('token');
  return {
    headers: {
      Authorization: `Bearer ${token}`
    }
  };
}

@Injectable()
export class TestsService {
  constructor() {}

  async GetBacteria(categoryId: number) {
    return axios.get<IBacteria[]>(`/api/v1/bacteria/${categoryId}`);
  }
  async GetSingleBacteria(bacteriaTitle: string) {
    return axios.get<IBacteria>(`/api/v1/bacteria/single/${bacteriaTitle}`, authHeaders());
  }
}
