import { Component, OnInit, Input } from "@angular/core";
import { INutrientAdvice } from "interfaces/advice";
import { Chart } from "chart.js";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: "app-comparison-target-nutrients",
  templateUrl: "./comparison-target-nutrients.component.html",
  styleUrls: ["./comparison-target-nutrients.component.css"],
})
export class ComparisonTargetNutrientsComponent implements OnInit {
  @Input() data: any = {};
  @Input() app: any;

  constructor(private translate: TranslateService) {}

  doughnutChart: any;

  carbohydratesValue = 5;
  fatValue = 4;
  saturatedFatValue = 3;
  sectionToDisplay: number = 0; // Default value or based on your logic

  saturatedFatTitle = "";
  fatTitle = "";
  carbsTitle = "";

  fiberChartValues: any;

  // fiber intake values
  lowerFiber = 10;
  mediumFiber = 10;
  higherFiber = 10;

  gaugeColorVariants = {
    default: {
      normal: "#ad66d9",
      okay: "#cfbcf2",
      great: "#05e399",
      bad: "#fad408",
      aware: "#c8bfe7",
    },
    iprobio: {
      normal: "#62b3b3",
      okay: "#b5d2d2",
      great: "#6fd06f",
      bad: "#f299a1",
      aware: "#339d9c69",
    },
    patris_health: {
      normal: "#33ab9f",
      okay: "#81c784",
      great: "#66bb6a",
      bad: "#f44336",
      aware: "#b2dfdb",
    },
  };
  selectedColors = this.gaugeColorVariants["default"];
  fiberValues: any = [];
  saltValues: any = [];
  proteinValues: any = [];

  lowerSalt = 0;
  mediumSalt = 6;
  higherSalt = 2;

  ngOnInit() {
    
    this.generateGraphs();
    this.getDietaryFiberLevel();
  }

  getDietaryFiberLevel() {
    this.data.forEach((element: any) => {
      const fiberValue = element.nutrientValues.find(
        (x: any) => x.type === "fibers",
      );
      const ref = this.lowerFiber + this.mediumFiber + this.higherFiber;
      if (fiberValue.value > 20) {
        fiberValue.value = fiberValue.value - 20;
      }
      const cal = (fiberValue.value / ref) * 100;
      let temp = {
        value: "calc(" + cal + "% - 15px)",
        date: element.date,
      };
      this.fiberValues.push(temp);

      const saltValue = element.nutrientValues.find(
        (x: any) => x.type === "salt",
      );
      const refSalt = this.mediumSalt + this.higherSalt;
      const calSalt = (saltValue.value / refSalt) * 100;

      let tempSalt = {
        value: "calc(" + calSalt + "% - 15px)",
        date: element.date,
      };
      this.saltValues.push(tempSalt);

      const protein = element.nutrientValues.find((x) => x.type === "protein");
      let tempProtein = {
        value: protein,
        date: element.date,
      };
      this.proteinValues.push(tempProtein);
    });
  }
  generateGraphs() {
    let tempCarbsValues = [];
    let tempFatsValues = [];
    let tempSatFatsValues = [];
    this.data.forEach((element: any) => {
      let tempCarbs = {
        data: [40, 60, 100],
        value: element.nutrientValues.find(
          (x: any) => x.type === "carbohydrates",
        ).value,
        backgroundColor: [
          this.selectedColors["bad"],
          this.selectedColors["great"],
          this.selectedColors["bad"],
        ],
        borderWidth: 2,
      };
      tempCarbsValues.push(tempCarbs);
      let tempSatFats = {
        data: [20, 40, 100],
        value: element.nutrientValues.find(
          (x: any) => x.type === "saturated_fat",
        ).value,
        backgroundColor: [
          this.selectedColors["bad"],
          this.selectedColors["great"],
          this.selectedColors["bad"],
        ],
        borderWidth: 2,
      };
      tempSatFatsValues.push(tempSatFats);

      let tempFats = {
        data: [20, 40, 100],
        value: element.nutrientValues.find((x: any) => x.type === "fat").value,
        backgroundColor: [
          this.selectedColors["bad"],
          this.selectedColors["great"],
          this.selectedColors["bad"],
        ],
        borderWidth: 2,
      };
      tempFatsValues.push(tempFats);
    });

    this.translate.get("TARGET_NUTRIENTS.CARBS").subscribe((text: string) => {
      this.carbsTitle = text;
      const confCarbohydrates = {
        type: "gauge",
        data: {
          datasets: tempCarbsValues,
        },
        options: {
          responsive: true,
          title: {
            display: true,
            text: this.carbsTitle,
          },
          layout: {
            padding: {
              bottom: 30,
            },
          },
          needle: {
            // Needle circle radius as the percentage of the chart area width
            radiusPercentage: 2,
            // Needle width as the percentage of the chart area width
            widthPercentage: 3.2,
            // Needle length as the percentage of the interval between inner radius (0%) and outer radius (100%) of the arc
            lengthPercentage: 80,
            // The color of the needle
            color: "rgba(0, 0, 0, 1)",
          },
          valueLabel: {
            display: false,
            formatter: Math.round,
          },
        },
      };

      const canvasCarbohydrates = document.getElementById(
        "carbohydrates",
      ) as HTMLCanvasElement;
      const ctxCarbohydrates = canvasCarbohydrates.getContext("2d");

      this.doughnutChart = new Chart(ctxCarbohydrates, confCarbohydrates);
    });

    this.translate.get("TARGET_NUTRIENTS.FAT").subscribe((text: string) => {
      this.fatTitle = text;
      const confFat = {
        type: "gauge",
        data: {
          datasets: tempFatsValues,
        },
        options: {
          responsive: true,
          title: {
            display: true,
            text: this.fatTitle,
          },
          layout: {
            padding: {
              bottom: 30,
            },
          },
          needle: {
            // Needle circle radius as the percentage of the chart area width
            radiusPercentage: 2,
            // Needle width as the percentage of the chart area width
            widthPercentage: 3.2,
            // Needle length as the percentage of the interval between inner radius (0%) and outer radius (100%) of the arc
            lengthPercentage: 80,
            // The color of the needle
            color: "rgba(0, 0, 0, 1)",
          },
          valueLabel: {
            display: false,
            formatter: Math.round,
          },
        },
      };

      const canvasFat = document.getElementById("fat") as HTMLCanvasElement;
      const ctxFat = canvasFat.getContext("2d");

      this.doughnutChart = new Chart(ctxFat, confFat);
    });
    this.translate
      .get("TARGET_NUTRIENTS.SATURATED")
      .subscribe((text: string) => {
        this.saturatedFatTitle = text;
        const confSaturatedFat = {
          type: "gauge",
          data: {
            datasets: tempSatFatsValues,
          },
          options: {
            responsive: true,
            title: {
              display: true,
              text: this.saturatedFatTitle,
            },
            layout: {
              padding: {
                bottom: 30,
              },
            },
            needle: {
              // Needle circle radius as the percentage of the chart area width
              radiusPercentage: 2,
              // Needle width as the percentage of the chart area width
              widthPercentage: 3.2,
              // Needle length as the percentage of the interval between inner radius (0%) and outer radius (100%) of the arc
              lengthPercentage: 80,
              // The color of the needle
              color: "rgba(0, 0, 0, 1)",
            },
            valueLabel: {
              display: false,
              formatter: Math.round,
            },
          },
        };

        const canvasSaturatedFat = document.getElementById(
          "saturated_fat",
        ) as HTMLCanvasElement;
        const ctxSaturatedFat = canvasSaturatedFat.getContext("2d");

        this.doughnutChart = new Chart(ctxSaturatedFat, confSaturatedFat);
      });
  }

  calculateDietaryFiberChart(fiberObject: INutrientAdvice) {
    if (fiberObject.value < 20) {
      this.lowerFiber = 30;
    }
    if (fiberObject.value > 40) {
      this.higherFiber =
        (fiberObject.value % 10 === 0
          ? fiberObject.value
          : fiberObject.value - (fiberObject.value % 5) + 5) -
        this.mediumFiber -
        this.higherFiber;
    }

    const total = this.lowerFiber + this.mediumFiber + this.higherFiber;

    this.fiberChartValues = [
      (this.lowerFiber / total) * 100 + "%",
      (this.mediumFiber / total) * 100 + "%",
      (this.higherFiber / total) * 100 + "%",
    ];
  }
  async getNewGaugeNames() {
    console.log("get names");

    this.translate
      .get("TARGET_NUTRIENTS.SATURATED")
      .subscribe((text: string) => {
        this.saturatedFatTitle = text;
      });
    this.translate.get("TARGET_NUTRIENTS.FAT").subscribe((text: string) => {
      this.fatTitle = text;
    });
    this.translate.get("TARGET_NUTRIENTS.CARBS").subscribe((text: string) => {
      this.carbsTitle = text;
    });
  }
  async createGauges() {
    console.log("create gauges");

    const confCarbohydrates = {
      type: "gauge",
      data: {
        datasets: [
          {
            data: [40, 60, 100],
            value: this.carbohydratesValue,
            backgroundColor: [
              this.selectedColors["bad"],
              this.selectedColors["great"],
              this.selectedColors["bad"],
            ],
            borderWidth: 2,
          },
        ],
      },
      options: {
        responsive: true,
        title: {
          display: true,
          text: this.carbsTitle,
        },
        layout: {
          padding: {
            bottom: 30,
          },
        },
        needle: {
          // Needle circle radius as the percentage of the chart area width
          radiusPercentage: 2,
          // Needle width as the percentage of the chart area width
          widthPercentage: 3.2,
          // Needle length as the percentage of the interval between inner radius (0%) and outer radius (100%) of the arc
          lengthPercentage: 80,
          // The color of the needle
          color: "rgba(0, 0, 0, 1)",
        },
        valueLabel: {
          display: false,
          formatter: Math.round,
        },
      },
    };

    const confFat = {
      type: "gauge",
      data: {
        datasets: [
          {
            data: [20, 40, 100],
            value: this.fatValue,
            backgroundColor: [
              this.selectedColors["bad"],
              this.selectedColors["great"],
              this.selectedColors["bad"],
            ],
            borderWidth: 2,
          },
        ],
      },
      options: {
        responsive: true,
        title: {
          display: true,
          text: this.fatTitle,
        },
        layout: {
          padding: {
            bottom: 30,
          },
        },
        needle: {
          // Needle circle radius as the percentage of the chart area width
          radiusPercentage: 2,
          // Needle width as the percentage of the chart area width
          widthPercentage: 3.2,
          // Needle length as the percentage of the interval between inner radius (0%) and outer radius (100%) of the arc
          lengthPercentage: 80,
          // The color of the needle
          color: "rgba(0, 0, 0, 1)",
        },
        valueLabel: {
          display: false,
          formatter: Math.round,
        },
      },
    };

    const confSaturatedFat = {
      type: "gauge",
      data: {
        datasets: [
          {
            data: [0, 10, 100],
            value: this.saturatedFatValue,
            backgroundColor: [
              this.selectedColors["bad"],
              this.selectedColors["great"],
              this.selectedColors["bad"],
            ],
            borderWidth: 2,
          },
        ],
      },
      options: {
        responsive: true,
        title: {
          display: true,
          text: this.saturatedFatTitle,
        },
        layout: {
          padding: {
            bottom: 30,
          },
        },
        needle: {
          // Needle circle radius as the percentage of the chart area width
          radiusPercentage: 2,
          // Needle width as the percentage of the chart area width
          widthPercentage: 3.2,
          // Needle length as the percentage of the interval between inner radius (0%) and outer radius (100%) of the arc
          lengthPercentage: 80,
          // The color of the needle
          color: "rgba(0, 0, 0, 1)",
        },
        valueLabel: {
          display: false,
          formatter: Math.round,
        },
      },
    };

    const canvasCarbohydrates = document.getElementById(
      "carbohydrates",
    ) as HTMLCanvasElement;
    const ctxCarbohydrates = canvasCarbohydrates.getContext("2d");

    const canvasFat = document.getElementById("fat") as HTMLCanvasElement;
    const ctxFat = canvasFat.getContext("2d");

    const canvasSaturatedFat = document.getElementById(
      "saturated_fat",
    ) as HTMLCanvasElement;
    const ctxSaturatedFat = canvasSaturatedFat.getContext("2d");

    this.doughnutChart = new Chart(ctxCarbohydrates, confCarbohydrates);

    this.doughnutChart = new Chart(ctxFat, confFat);

    this.doughnutChart = new Chart(ctxSaturatedFat, confSaturatedFat);
  }
  toggleSectionToDisplay(section: number): void {
    this.sectionToDisplay = section;
  }
  
}
