import { Component, NgModule, OnInit, ViewChild } from "@angular/core";
import { SubjectService } from "../api/test/subject";
import { TranslateService } from "@ngx-translate/core";
import { BacteriaLevelsComponent } from "../bacteria-levels/bacteria-levels.component";
import { ActivatedRoute } from "@angular/router";

@Component({
  selector: "app-reports",
  templateUrl: "./reports.component.html",
  styleUrls: ["./reports.component.css"],
})
@NgModule({
  declarations: [ReportsComponent, BacteriaLevelsComponent],
  providers: [BacteriaLevelsComponent],
})
export class ReportsComponent implements OnInit {
  @ViewChild("BacteriaLevelsComponent", { static: false })
  bacteriaLevelsComponent: BacteriaLevelsComponent;

  pdfLinks = {
    dashboard: null,
    supplementAdvice: null,
    nutritionAdvice: null,
    dietaryAdvice: null,
  };
  testKitID: number;

  constructor(
    private subjectService: SubjectService,
    private translate: TranslateService,
    private route: ActivatedRoute
  ) {}

  ngOnInit() {
    this.testKitID = Number(this.route.snapshot.paramMap.get("id"));

    this.loadPDFs();
  }

  async loadPDFs() {
    try {
      const response = await this.subjectService.GetPdfs(
        this.testKitID,
        localStorage.getItem("language")
      );
      if (response.data) {
        this.pdfLinks.dashboard = response.data["dashboard"];
        this.pdfLinks.supplementAdvice = response.data["supplement_advice"];
        this.pdfLinks.nutritionAdvice = response.data["nutrition_advice"];
        this.pdfLinks.dietaryAdvice = response.data["dietary_advice"];
      }
    } catch (error) {
      console.error("Error loading PDF links:", error);
    }
  }

  openPDF(url: string) {
    if (url) {
      window.open(url, "_blank");
    } else {
      console.error("PDF URL is null");
    }
  }
  downloadBacteriaPDF() {
    if (this.bacteriaLevelsComponent) {
      this.bacteriaLevelsComponent.downloadBacList();
    } else {
      console.error("BacteriaLevelsComponent is not available");
    }
  }
  downloadBacteriaCSV() {
    if (this.bacteriaLevelsComponent) {
      this.bacteriaLevelsComponent.downloadBacCsv();
    } else {
      console.error("BacteriaLevelsComponent is not available");
    }
  }
}
