import { Component, OnInit } from '@angular/core';
import {UtilityService} from "../utility.service";
import {TranslateService} from "@ngx-translate/core";

@Component({
  selector: 'app-create-account',
  templateUrl: './create-account.component.html',
  styleUrls: ['./create-account.component.css']
})
export class CreateAccountComponent implements OnInit {

  constructor(private translate: TranslateService) { }

  ngOnInit() {

  }

}
