import { Component, Input, OnInit } from "@angular/core";
import { AppComponent } from "../../app/app.component";

@Component({
  selector: "app-take-home-dashboard-food-recomendation",
  templateUrl: "./take-home-dashboard-food-recomendation.component.html",
  styleUrls: ["./take-home-dashboard-food-recomendation.component.css"],
})
export class TakeHomeDashboardFoodRecomendationComponent implements OnInit {
  constructor(public app: AppComponent) {}
  @Input() title: string = "";
  @Input() foodGroup: string = "";

  img: string = "";
  partnerImgSrc = '';
  ngOnInit() {
    if (this.app.partnerVersion != ''){
      this.partnerImgSrc = '-' + this.app.partnerVersion;
    }
    this.img = this.findFoodGroupImage(this.foodGroup);
  }

  findFoodGroupImage(foodGroup) {
    switch (foodGroup) {
      case "Animal-derived alternatives, meat":
      case "Organ meats":
      case "Meat, white meat":
      case "Liver":
      case "Meat, processed meat":
      case "Vervangers dierlijke producten, meat":
      case "Orgaanvlees temp disable":
      case "Meat, wit vlees":
      case "Lever":
      case "Vlees, bewerkt vlees":
        return "../../assets/meat"+this.partnerImgSrc+".png";

      case "Beverages, fermented drinks":
      case "Dranken, gefermenteerde dranken":
        return "../../assets/soft-drink"+this.partnerImgSrc+".png";

      case "Cereals and cereal products, bread":
      case "Graan en graanproducten, brood":
        return "../../assets/bread"+this.partnerImgSrc+".png";

      case "Cereals":
      case "Granen":
      case "Cereals and cereal products, breakfast cereals":
      case "Animal-derived alternatives, cereal":
      case "Graan en graanproducten, ontbijtgranen":
        return "../../assets/cereal"+this.partnerImgSrc+".png";

      case "Cereals and cereal products, grains":
      case "Graan en graanproducten, graan":
      case "Nuts and seeds":
      case "Noten en zaden":
        return "../../assets/grains"+this.partnerImgSrc+".png";

      case "Cereals and cereal products, rice":
      case "Graan en graanproducten, rijst":
        return "../../assets/rice"+this.partnerImgSrc+".png";

      case "Condiments and seasonings":
      case "Condiments and seasonings, plant-derived":
      case "Condiments and seasonings, animal-derived":
      case "Vetten en smaakmakers":
      case "Vetten en smaakmakers, dierlijk":
      case "Vetten en smaakmakers, plantaardig":
        return "../../assets/spice"+this.partnerImgSrc+".png";

      case "Dairy, cheese":
      case "Zuivel, kaas":
        return "../../assets/cheese"+this.partnerImgSrc+".png";

      case "Dairy, fermented products":
      case "Zuivel, gefermenteerde producten":
        return "../../assets/dairy-products"+this.partnerImgSrc+".png";

      case "Dairy, milk and yogurt":
      case "Animal-derived alternatives, milk and yogurt":
      case "Zuivel, melk en yoghurt":
      case "Vervangers dierlijke producten, melk en yoghurt":
        return "../../assets/milk-bottle"+this.partnerImgSrc+".png";

      case "Fruits":
      case "Fruit":
      case "Fruits, dried fruits":
      case "Fruits, nuts and seeds":
      case "Fruits, fresh fruits":
      case "Fruit, noten en zaden":
      case "Fruit, vers fruit":
        return "../../assets/fruit"+this.partnerImgSrc+".png";

      case "Legumes, fermented legumes":
      case "Legumes":
      case "Peulvruchten":
      case "Legumes, canned legumes":
      case "Legumes, fresh and dried legumes":
      case "Peulvruchten, gefermenteerde peulvruchten":
      case "Peulvruchten, ingeblikte peulvruchten":
      case "Peulvruchten, verse en gedroogde peulvruchten":
        return "../../assets/legumes"+this.partnerImgSrc+".png";

      case "Other vegetables":
      case "Vegetables, fermented vegetables":
      case "Vegetables, fresh vegetables":
      case "Vegetables":
      case "Andere groenten":
      case "Groenten, gefermenteerde groenten":
      case "Groenten":
      case "Groenten, verse groenten'":
        return "../../assets/vegetable"+this.partnerImgSrc+".png";

      case "Temporary holder food group":
      case "Other mammalian game":
      case "Ander vlees van zoogdieren":
        return "../../assets/food-general"+this.partnerImgSrc+".png";

      case "Fish, fresh fish":
      case "Vis, verse vis":
        return "../../assets/fish"+this.partnerImgSrc+".png";

      case "Beverages, alcoholic drinks":
      case "Dranken, alcoholische dranken":
        return "../../assets/liquor"+this.partnerImgSrc+".png";

      case "Butter":
      case "Boter":
        return "../../assets/spread"+this.partnerImgSrc+".png";

      case "Cereals and cereal products, biscuits":
      case "Graan en graanproducten, koekjes":
        return "../../assets/cookie"+this.partnerImgSrc+".png";

      case "Dairy, cream":
      case "Zuivel, room":
        return "../../assets/whip-cream"+this.partnerImgSrc+".png";

      case "Potato crisps":
      case "Aardappel chips":
        return "../../assets/chips"+this.partnerImgSrc+".png";

      case "Sweets, confectionery and pastries temp disable":
      case "Zoetwaren, snoep en gebak":
        return "../../assets/bakery"+this.partnerImgSrc+".png";

      default:
        return "../../assets/food-general"+this.partnerImgSrc+".png";
    }
  }
}
