import { Theme } from './symbols';

export const defaultTheme: Theme = {
  name: 'default',
  properties: {
    '--color-profile-tab': '#05e399',
    '--navbar-img': 'url("assets/IB LOGO.png")',
    '--footer-img': 'url("assets/IB LOGO.png")',
    '--navbar-color': '#381161',
    '--navbar-light-color': '#381161',
    '--conditions-color': '#381161',
    '--navigation-color': '#381161',
    '--contact-color': '#381161',
    '--copyrights-color': '#381161',
    '--btn-dark-color': 'linear-gradient(135deg, rgb(5, 227, 153) 0%, rgb(5, 227, 153) 100%)',
    '--btn-light-color': '#cfbcf2c7',
    '--btn-light-color-active': '#cfbcf2',
    '--table-header-color': '#3c1642',
    '--kit-code-btn-color': '#3c1642',
    '--btn-fill-in-color': '#756877',
    '--btn-fill-in-color-active': '#3c1642',
    '--food-diary-color': '#3c1642',
    '--food-diary-color-light': '#dfd6e1',
    '--survey-color': '#3c1642',
    '--survey-color-complete': '#05e399',
    '--dashboard-container': '#f5f7fa',
    '--dashboard-container-hover': '#eae2f8',
    '--sub-dropdown-color':'#34145d',
    '--dropdown-box':'#dacaf5',
    '--compound-container-color':'#eae2f8',
    '--normal-color': '#ad66d9',
    '--okay-color': '#cfbcf2',
    '--great-color': '#05e399',
    '--bad-color': '#fad408',
    '--aware-color': '#c8bfe7',
    '--warning-color': '#fad408',
    '--warning-color-fibre': '#cfbcf2',
    '--take-home-bubble-color': '#ab78d6',
    '--rectangle-color': '#eae2f8',
    '--description-color': '#cfbcf2',
    '--protein-color': '#EAE2F8',
    '--bmi-1-color': '#E5CEF3',
    '--bmi-2-color': '#D3ADEB',
    '--bmi-3-color': '#C28DE2',
    '--bmi-4-color': '#AD66D9',
    '--bmi-5-color': '#9F4BD2',
    '--recomendations-color': '#34145d',
    '--img-placeholder-color': '#eae3f9',
    '--fibre-color': '#cfbcf2',
    '--green-color': '#05e399',
    '--yellow-color': '#fad408',
    '--footer-justify-content': 'space-around'
  }
};