import { Component, OnInit } from '@angular/core';
import {TestsService} from '../api/test/bacteria';
import {IBacteria} from '../../../interfaces/tests';
import {TranslateService} from "@ngx-translate/core";

@Component({
  selector: 'app-top-bacteria',
  templateUrl: './top-bacteria.component.html',
  styleUrls: ['./top-bacteria.component.css']
})
export class TopBacteriaComponent implements OnInit {

  constructor(private testsService: TestsService, private translate: TranslateService) { }


  async ngOnInit() {
  }

}
