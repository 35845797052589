import { Component, OnInit, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import {TranslateService} from "@ngx-translate/core";

@Component({
  selector: 'app-human-buttons',
  templateUrl: './human-buttons.component.html',
  styleUrls: ['./human-buttons.component.css']
})
export class HumanButtonsComponent implements OnInit {

  constructor(private route: ActivatedRoute, private translate: TranslateService) { }
  public id:string;
  ngOnInit() {
    this.id = this.route.snapshot.paramMap.get('id');
    console.log(this.id);

    function replaceAll(str, find, replace) {
      return str.replace(new RegExp(find, '\n'), replace);
    }
  }

  human=[
    {
      'id': 1,
      'alias':'relationship',
      'name': 'The relationship between your diet & your microbiome',
      'paragraphs':[
        {
          'text':'Human beings mostly live with microbes in and on their body. They live in oral, vaginal, gut, and on our skin. However, sometimes these microbes cause problems. In humans, the gut microbiota (better known as the gut flora, but scientifically this phrase is now thought to be incorrect, as these microorganisms are not flowers) has the largest numbers of bacteria and the greatest number of species compared to other areas of the body. Some human gut microorganisms benefit the host by fermenting dietary components. Bacteria living in the intestines also play a role in synthesizing vitamin B and vitamin K. The composition of human gut microbiota changes over time. This is when the diet changes but also as overall health changes.',
          'image': '../../assets/chain_variant.png'
        },
        {
          'text':'The microbial composition of the gut microbiota varies across the digestive tract (from your mouth until the end of your large intestine). In the stomach and small intestine, relatively few species of bacteria are generally present. The large intestine, in contrast, contains a very high microbial density with up to 10 12 (or 100 times 1 million times 1 million!) cells per gram of poop. These bacteria represent between 300 and 1000 different species. However, 99% of the bacteria come from about 30 or 40 species. So, there are some bigger families living in our large intestine. As a consequence of their abundance in the intestine, bacteria also make up to 60% of the dry mass of feces. Fungi, protists, archaea, and viruses are also present in the gut flora, but less is known about their activities. \n \nThere are a numerous factors which influences the gut microbiota: -Age\n -Diet\n -Disease\n -Geography\n -Malnourishment\n -Race and ethnicity\n -Socioeconomic status\n -Use of medication (especially antibiotics)',
          'image': '../../assets/meal_variant.png'
        },
        {
          'text': 'When the study of gut microbiota began several decades ago it was thought to have three key roles:\n1. direct defense against pathogens;\n2. fortification of host defense by its role in developing and maintaining the intestinal epithelium and inducing antibody production there;\n' +
            '3. metabolizing otherwise indigestible compounds in food.\n' +
            '\n' + 'Subsequent work discovered its role in training the developing immune system, and yet further work focused on its role in the gut-brain axis. Manipulation of the gut microbiota towards a more balanced and optimal microbial community to promote human health has received increasing interest in recent years. In addition to traditional probiotics and prebiotics, new therapeutic approaches ranging from total microbiota manipulation by fecal microbiota transplantation to narrow-spectrum microbiota manipulation by targeted/personalized stimulation or inhibition of specific bacterial group using dietary strategies are being investigated.\n',
          'image':'../../assets/guts_variant.png'
        }
      ]
    },
    {
      'id': 2,
      'alias':'procedures',
      'name': 'Testing procedures',
      'paragraphs':[
        {
          'text':'In order to map the intestinal microflora, the microflora composition is currently determined from a fecal sample by sequencing the genetic material of the bacteria in our gut (the DNA of a [part of a] gene that each microorganism contains: the 16S rRNA gene). This gene contains a unique sequence (base sequence) for each microorganism that can be understood as a fingerprint. Specific software recognizes the fingerprint and can thus determine which microorganisms (in this analysis: bacteria) are present in the fecal sample. ',
          'image': '../../assets/test_tube_variant.png'
        },
        {
          'text':'Each sample yields several 10,000-odd sequences, and by simply counting the number of the same "fingerprints" an impression can be obtained about the amount of the various bacteria present. It is thought that each person has about 250 different bacteria in the gut. Some in very large numbers (several billion per gram), others in much lower quantities (several 10s). The latter in particular we will not be able to demonstrate with the current method, but we do measure the most common ones. The numbers are expressed as a relative quantity: a percentage of the total. We now know the composition of many individuals and can therefore compare the microflora of a single individual (in this case your own) with that of others.\n ',
          'image': '../../assets/flask_variant.png'
        }
      ]
    },
    {
      'id': 3,
      'alias':'laboratories',
      'name': 'Laboratories',
      'paragraphs':[
        {
          'text':' InnerBuddies works together with the University of Maastricht. The university has a certified laboratory at its disposal and InnerBuddies is allowed to use it to analyze the stool samples. The laboratory of the university has the right machines and materials to be able to perform the analyses. There are also specialized lab analysts working there who ultimately make the analyses possible. ',
          'image': '../../assets/lab_variant.png'
        }
      ]
    },
    {
      'id': 4,
      'alias':'diseases',
      'name': 'Diseases',
      'paragraphs':[
        {
          'text':'The microorganisms in the gastrointestinal tract play a significant role in nutrient uptake, vitamin synthesis, energy harvest, inflammatory modulation, and host immune response, collectively contributing to human health. Scientific evidence shows a correlation between gut microbial imbalance (dysbiosis) and a variety of disorders such as colon cancer, obesity, overweight, diabetes, inflammatory bowel disease, irritable bowel syndrome, cardiovascular disorder, and even neurobehavioral disorders though the gut-brain axis such as attention-deficit/hyperactivity disorder, autism, dementia, depression, stress, anxiety and Parkinson\'s disease. Therefore, the microbiome is becoming more and more important for our health. ',
          'image': '../../assets/virus_variant.png'
        }
      ]
    },
    {
      'id': 5,
      'alias':'sports',
      'name': 'Sports',
      'paragraphs':[
        {
          'text':'Sports also have an effect on our gut microbiota. Such features include a higher abundance of health-promoting bacterial species, increased microbial diversity, functional metabolic capacity and stimulation of bacterial abundance that can modulate mucosal immunity.  Physical activity appears to enrich microbiota diversity, stimulate the proliferation of bacteria which can modulate mucosal immunity, and stimulate bacteria and functional pathways capable of producing substances that protect against gastrointestinal disorders and improve performance. Indeed, exercise may be an important intervention to alter gut microbiota composition and restore gut symbiosis. However, excessive and/or prolonged high-intensity exercise may not impart these effects. ',
          'image': '../../assets/dumbbell_variant.png'
        }
      ]
    }
  ];

}
