import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import {IDashboardDietary, IEyyo} from "../../../interfaces/advice";
import {DietaryDashService} from "../api/calculations/dietarydash";
import {ActivatedRoute} from "@angular/router";
import {LangChangeEvent, TranslateService} from "@ngx-translate/core";

@Component({
  selector: 'app-colon-problem',
  templateUrl: './colon-problem.component.html',
  styleUrls: ['./colon-problem.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class ColonProblemComponent implements OnInit {

  constructor(public dietaryDashService: DietaryDashService, private route: ActivatedRoute, private translate: TranslateService) {
  }

  dietaryAdviceText = '';
  resultText = '';
  foodTableHTML = '';
  colonProblem;
  range;
  dashBacteria: IEyyo;
  dietaryAdvice: IDashboardDietary[];

  async ngOnInit() {
    this.updateFoodTable();
    this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      this.updateFoodTable();
      this.setText();
    });
    this.dashBacteria = JSON.parse(sessionStorage.getItem('Dashboard'));
    console.log(this.dashBacteria);
    this.colonProblem = this.dashBacteria.graphs.find(x => x.graph === 'Potential Colon').value;
    this.range = this.dashBacteria.graphs.find(x => x.graph === 'Potential Colon').range;
    const testKitID = Number(this.route.snapshot.paramMap.get('id'));
    this.dietaryAdvice = await (await this.dietaryDashService.GetDietaryAdvice(testKitID, 'Potential colon problem')).data;
    this.setText();
  }

  getBacteriaLevel() {

    const dif = (this.range[1] - this.range[0]) / 70 * 15;
    const refUp = this.range[1] + dif; // potential for gas production
    const refLow = this.range[0] - dif; // potential for gas production

    let cal = (this.colonProblem - refLow) / (refUp - refLow) * 100;
    if (cal < 0) {
      cal = 0;
    }
    if (cal > 100) {
      cal = 100;
    }
    console.log('calculation:');
    console.log(cal);
    cal = 100 - cal;
    return 'calc(' + cal + '% - 15px)';
  }

  updateFoodTable() {
    this.translate.get('FOOD_ITEM_LIMIT_TABLE.HTML').subscribe((text: string) => {
      this.foodTableHTML = text;
    });
  }

  resultSampleText() {
    if (this.colonProblem > this.range[1]) {
      this.createResultString('COLON_PROBLEM.BE_AWARE_RESULT');
      return 'be_aware';
    }
    if (this.colonProblem < this.range[0] || this.colonProblem === 0) {
      this.createResultString('COLON_PROBLEM.GREAT_RESULT');
      return 'great';
    }
    this.createResultString('COLON_PROBLEM.NORMAL_RESULT');
    return 'normal';
  }

  createResultString(tag: string) {
    this.translate.get(tag).subscribe((text: string) => {
      this.resultText = text;
    });
  }

  setText() {
    this.resultSampleText();
    this.translate.get('COLON_PROBLEM.NUTRITION_ADVICE').subscribe((text: string) => {
      this.dietaryAdviceText = text;
    });
  }
}
