import axios from 'axios';
import { Injectable } from '@angular/core';
import {IMealPlannerIntake} from 'interfaces/meal_planner_intake';
import {environment} from '../../../environments/environment';
import {INutrient} from "../../../../interfaces/advice";

axios.defaults.baseURL = environment.url.api;

function authHeaders() {
    const token = localStorage.getItem('token');
    return {
        headers: {
            Authorization: `Bearer ${token}`
        }
    };
}

@Injectable()
export class MealPlannerService {
    constructor() {}

    async PostIntake(data: IMealPlannerIntake) {
      return axios.post(`/api/v1/meal_planner_intake/`, data, authHeaders());
    }

    async GetIntakeByDate(subjectId: number, date: string) {
      return axios.get<IMealPlannerIntake[]>(`/api/v1/meal_planner_intake/${subjectId}/${date}`, authHeaders());
    }

    async RemoveIntake(intakeId: number) {
      return axios.delete<IMealPlannerIntake[]>(`/api/v1/meal_planner_intake/${intakeId}`, authHeaders());
    }
}
