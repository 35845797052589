import { HtmlTagDefinition } from "@angular/compiler";
import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { Chart } from "chart.js";
import { RichTextFieldReadError } from "pdf-lib";
import { setMaxListeners } from "process";
import { pipe } from "rxjs";
import { INutrientAdvice } from "../../../interfaces/advice";
import { DietaryDashService } from "../api/calculations/dietarydash";
import { AppComponent } from "../app.component";
import { max } from "rxjs/operators";

@Component({
  selector: "app-target-values",
  templateUrl: "./target-values.component.html",
  styleUrls: ["./target-values.component.css"],
})
export class TargetValuesComponent implements OnInit {
  constructor(
    private translate: TranslateService,
    public dietaryDashService: DietaryDashService,
    public app: AppComponent,
    private route: ActivatedRoute
  ) {}

  doughnutChart: any;

  carbohydratesValue = 5;
  carbohydratesValueRanges: any = [];
  fatValue = 4;
  fatValueRanges: any = [];
  saturatedFatValue = 3;
  saturatedFatValueRanges: any = [];
  proteinValue = 5; // Default value for protein
  proteinOptimalValue = 0;
  proteinValueRanges: any = []; // Protein value ranges
  proteinChartValues = [];
  loading: boolean = true;

  saltObject: INutrientAdvice;
  fibreObject: INutrientAdvice;
  carbohydratesObject: INutrientAdvice;
  fatObject: INutrientAdvice;
  satFatObject: INutrientAdvice;

  fiberChartValues: string[] = []; // Initialize as an empty array
  saltChartValues: string[] = [];
  proteinObject: INutrientAdvice;
  fiberValue;
  saltValue;
  carbohydratesChartValues = [];
  fatChartValues = [];
  saturatedFatChartValues = [];
  originalFiberValue: number;
  originalSaltValue: number;

  saturatedFatTitle = "";
  fatTitle = "";
  carbsTitle = "";

  sectionToDisplay: any = 0;

  nutrientValues: INutrientAdvice[];

  // fiber intake values
  lowerFiber = 10;
  mediumFiber = 10;
  higherFiber = 10;

  // salt intake values
  lowerSalt = 0;
  mediumSalt = 6;
  higherSalt = 4;

  partnerImgSrc = "";
  gaugeColorVariants = {
    default: {
      normal: "#ad66d9",
      okay: "#cfbcf2",
      great: "#05e399",
      bad: "#fad408",
      aware: "#c8bfe7",
    },
    iprobio: {
      normal: "#62b3b3",
      okay: "#b5d2d2",
      great: "#6fd06f",
      bad: "#f299a1",
      aware: "#339d9c69",
    },
    patris_health: {
      normal: "#33ab9f",
      okay: "#81c784",
      great: "#66bb6a",
      bad: "#f44336",
      aware: "#b2dfdb",
    },
  };
  selectedColors = this.gaugeColorVariants.default;

  explanationText = "TARGET_NUTRIENTS.EXPLANATION_TEXT";

  calculateDietaryFiberChart(fiberObject: INutrientAdvice) {
    console.log;
    if (!fiberObject || !fiberObject.range || fiberObject.range.length === 0) {
      console.warn("Invalid fiberObject data, using default values.");
      this.fiberChartValues = ["33%", "33%", "34%"]; // Default fallback
      return;
    }

    const totalRange = fiberObject.range[fiberObject.range.length - 1][1];
    this.fiberChartValues = fiberObject.range.map(
      (range) => `${((range[1] - range[0]) / totalRange) * 100}%`
    );
  }

  calculateSaltChart(saltObject: INutrientAdvice) {
    if (!saltObject || !saltObject.range || saltObject.range.length === 0) {
      console.warn("Invalid saltObject data, using default values.");
      this.saltChartValues = ["50%", "50%"]; // Default fallback
      return;
    }

    const totalRange = saltObject.range[saltObject.range.length - 1][1];
    this.saltChartValues = saltObject.range.map(
      (range) => `${((range[1] - range[0]) / totalRange) * 100}%`
    );

    console.log("Salt Chart Values:", this.saltChartValues);
  }

  async getNewGaugeNames() {
    this.translate
      .get("TARGET_NUTRIENTS.SATURATED")
      .subscribe((text: string) => {
        this.saturatedFatTitle = text;
      });
    this.translate.get("TARGET_NUTRIENTS.FAT").subscribe((text: string) => {
      this.fatTitle = text;
    });
    this.translate.get("TARGET_NUTRIENTS.CARBS").subscribe((text: string) => {
      this.carbsTitle = text;
    });
  }

  async createGauges() {
    console.log(this.carbohydratesValueRanges);
    const confCarbohydrates = {
      type: "gauge",
      data: {
        datasets: [
          {
            data: this.carbohydratesValueRanges,
            value: this.carbohydratesValue,
            backgroundColor: [
              this.selectedColors["bad"],
              this.selectedColors["great"],
              this.selectedColors["bad"],
            ],
            borderWidth: 2,
          },
        ],
      },
      options: {
        responsive: true,
        title: {
          display: true,
          text: this.carbsTitle,
        },
        layout: {
          padding: {
            bottom: 30,
          },
        },
        needle: {
          // Needle circle radius as the percentage of the chart area width
          radiusPercentage: 2,
          // Needle width as the percentage of the chart area width
          widthPercentage: 3.2,
          // Needle length as the percentage of the interval between inner radius (0%) and outer radius (100%) of the arc
          lengthPercentage: 80,
          // The color of the needle
          color: "rgba(0, 0, 0, 1)",
        },
        valueLabel: {
          display: false,
          formatter: Math.round,
        },
      },
    };

    const confFat = {
      type: "gauge",
      data: {
        datasets: [
          {
            data: this.fatValueRanges,
            value: this.fatValue,
            backgroundColor: [
              this.selectedColors["bad"],
              this.selectedColors["great"],
              this.selectedColors["bad"],
            ],
            borderWidth: 2,
          },
        ],
      },
      options: {
        responsive: true,
        title: {
          display: true,
          text: this.fatTitle,
        },
        layout: {
          padding: {
            bottom: 30,
          },
        },
        needle: {
          // Needle circle radius as the percentage of the chart area width
          radiusPercentage: 2,
          // Needle width as the percentage of the chart area width
          widthPercentage: 3.2,
          // Needle length as the percentage of the interval between inner radius (0%) and outer radius (100%) of the arc
          lengthPercentage: 80,
          // The color of the needle
          color: "rgba(0, 0, 0, 1)",
        },
        valueLabel: {
          display: false,
          formatter: Math.round,
        },
      },
    };

    const confSaturatedFat = {
      type: "gauge",
      data: {
        datasets: [
          {
            data: this.saturatedFatValueRanges,
            value: this.saturatedFatValue,
            backgroundColor: [
              this.selectedColors["bad"],
              this.selectedColors["great"],
              this.selectedColors["bad"],
            ],
            borderWidth: 2,
          },
        ],
      },
      options: {
        responsive: true,
        title: {
          display: true,
          text: this.saturatedFatTitle,
        },
        layout: {
          padding: {
            bottom: 30,
          },
        },
        needle: {
          // Needle circle radius as the percentage of the chart area width
          radiusPercentage: 2,
          // Needle width as the percentage of the chart area width
          widthPercentage: 3.2,
          // Needle length as the percentage of the interval between inner radius (0%) and outer radius (100%) of the arc
          lengthPercentage: 80,
          // The color of the needle
          color: "rgba(0, 0, 0, 1)",
        },
        valueLabel: {
          display: false,
          formatter: Math.round,
        },
      },
    };

    const canvasCarbohydrates = document.getElementById(
      "carbohydrates"
    ) as HTMLCanvasElement;
    const ctxCarbohydrates = canvasCarbohydrates.getContext("2d");

    const canvasFat = document.getElementById("fat") as HTMLCanvasElement;
    const ctxFat = canvasFat.getContext("2d");

    const canvasSaturatedFat = document.getElementById(
      "saturated_fat"
    ) as HTMLCanvasElement;
    const ctxSaturatedFat = canvasSaturatedFat.getContext("2d");

    this.doughnutChart = new Chart(ctxCarbohydrates, confCarbohydrates);

    this.doughnutChart = new Chart(ctxFat, confFat);

    this.doughnutChart = new Chart(ctxSaturatedFat, confSaturatedFat);
  }

  async ngOnInit() {
    if (this.app.partnerVersion !== "") {
      this.selectedColors = this.gaugeColorVariants[this.app.partnerVersion];
      this.partnerImgSrc = "-" + this.app.partnerVersion;
    }

    // this.hideNonPartnerElements();
    // this.explanationText =
    //   "TARGET_NUTRIENTS.EXPLANATION_TEXT_" +
    //   this.app.partnerVersion.toUpperCase();

    const testKitID = Number(this.route.snapshot.paramMap.get("id"));

    if (sessionStorage.getItem("TargetNutValues-" + testKitID)) {
      this.nutrientValues = JSON.parse(
        sessionStorage.getItem("TargetNutValues-" + testKitID)
      );
    } else {
      this.nutrientValues = await (
        await this.dietaryDashService.GetNutrientAdvice(testKitID)
      ).data["nutrients"];
    }
    console.log(this.nutrientValues);
    this.carbohydratesObject = this.nutrientValues.find(
      (x) => x.type === "carbohydrates"
    );
    this.fibreObject = this.nutrientValues.find((x) => x.type === "fibers");
    this.fatObject = this.nutrientValues.find((x) => x.type === "fat");
    this.satFatObject = this.nutrientValues.find(
      (x) => x.type === "saturated_fat"
    );
    this.proteinObject = this.nutrientValues.find((x) => x.type === "protein");
    this.saltObject = this.nutrientValues.find((x) => x.type === "salt");

    // Parse carbohydrate values and ranges
    this.carbohydratesValue = this.carbohydratesObject.value;
    this.carbohydratesValueRanges = this.carbohydratesObject.range.map(
      (range) => range
    );
    // Update specific values
    this.fiberValue = (this.fibreObject && this.fibreObject.value) || 0; // Default to 0 if null
    this.saturatedFatValue =
      (this.satFatObject && this.satFatObject.value) || 0; // Default to 0 if null
    this.carbohydratesValue =
      (this.carbohydratesObject && this.carbohydratesObject.value) || 0;
    this.fatValue = (this.fatObject && this.fatObject.value) || 0;
    this.proteinValue = (this.proteinObject && this.proteinObject.value) || 0;
    console.log(this.proteinValue);

    // Update ranges
    this.proteinValueRanges =
      (this.proteinObject && this.proteinObject.range) || [];
    console.log(this.proteinValueRanges);
    this.proteinOptimalValue =
      this.proteinValueRanges && Array.isArray(this.proteinValueRanges[1])
        ? this.proteinValueRanges[1][0] || 0 // Access the first value in the range
        : 0; // Default to 0 if invalid

    this.carbohydratesValueRanges =
      (this.carbohydratesObject && this.carbohydratesObject.range) || [];

    this.fatValueRanges = (this.fatObject && this.fatObject.range) || [];
    this.saturatedFatValueRanges =
      (this.satFatObject && this.satFatObject.range) || [];

    // Parse other nutrients similarly...
    this.calculateDietaryFiberChart(this.fibreObject);
    this.calculateSaltChart(this.saltObject);
    this.calculateCarbohydratesChart(this.carbohydratesObject);
    this.calculateFatChart(this.fatObject);
    this.calculateSaturatedFatChart(this.satFatObject);

    this.loading = false;
    // // TODO when changing language update values
    // this.translate
    //   .get("TARGET_NUTRIENTS.SATURATED")
    //   .subscribe((text: string) => {
    //     this.saturatedFatTitle = text;
    //     const confSaturatedFat = {
    //       type: "gauge",
    //       data: {
    //         datasets: [
    //           {
    //             data: this.saturatedFatValueRanges,
    //             value: this.saturatedFatValue,
    //             backgroundColor: [
    //               this.selectedColors["bad"],
    //               this.selectedColors["great"],
    //               this.selectedColors["bad"],
    //             ],
    //             borderWidth: 2,
    //           },
    //         ],
    //       },
    //       options: {
    //         responsive: true,
    //         title: {
    //           display: true,
    //           text: this.saturatedFatTitle,
    //         },
    //         layout: {
    //           padding: {
    //             bottom: 30,
    //           },
    //         },
    //         needle: {
    //           // Needle circle radius as the percentage of the chart area width
    //           radiusPercentage: 2,
    //           // Needle width as the percentage of the chart area width
    //           widthPercentage: 3.2,
    //           // Needle length as the percentage of the interval between inner radius (0%) and outer radius (100%) of the arc
    //           lengthPercentage: 80,
    //           // The color of the needle
    //           color: "rgba(0, 0, 0, 1)",
    //         },
    //         valueLabel: {
    //           display: false,
    //           formatter: Math.round,
    //         },
    //       },
    //     };

    //     const canvasSaturatedFat = document.getElementById(
    //       "saturated_fat"
    //     ) as HTMLCanvasElement;
    //     const ctxSaturatedFat = canvasSaturatedFat.getContext("2d");

    //     this.doughnutChart = new Chart(ctxSaturatedFat, confSaturatedFat);
    //   });

    // this.translate.get("TARGET_NUTRIENTS.FAT").subscribe((text: string) => {
    //   this.fatTitle = text;
    //   const confFat = {
    //     type: "gauge",
    //     data: {
    //       datasets: [
    //         {
    //           data: this.fatValueRanges,
    //           value: this.fatValue,
    //           backgroundColor: [
    //             this.selectedColors["bad"],
    //             this.selectedColors["great"],
    //             this.selectedColors["bad"],
    //           ],
    //           borderWidth: 2,
    //         },
    //       ],
    //     },
    //     options: {
    //       responsive: true,
    //       title: {
    //         display: true,
    //         text: this.fatTitle,
    //       },
    //       layout: {
    //         padding: {
    //           bottom: 30,
    //         },
    //       },
    //       needle: {
    //         // Needle circle radius as the percentage of the chart area width
    //         radiusPercentage: 2,
    //         // Needle width as the percentage of the chart area width
    //         widthPercentage: 3.2,
    //         // Needle length as the percentage of the interval between inner radius (0%) and outer radius (100%) of the arc
    //         lengthPercentage: 80,
    //         // The color of the needle
    //         color: "rgba(0, 0, 0, 1)",
    //       },
    //       valueLabel: {
    //         display: false,
    //         formatter: Math.round,
    //       },
    //     },
    //   };

    //   const canvasFat = document.getElementById("fat") as HTMLCanvasElement;
    //   const ctxFat = canvasFat.getContext("2d");

    //   this.doughnutChart = new Chart(ctxFat, confFat);
    // });

    // this.translate.get("TARGET_NUTRIENTS.CARBS").subscribe((text: string) => {
    //   this.carbsTitle = text;
    //   const confCarbohydrates = {
    //     type: "gauge",
    //     data: {
    //       datasets: [
    //         {
    //           data: this.carbohydratesValueRanges,
    //           value: this.carbohydratesValue,
    //           backgroundColor: [
    //             this.selectedColors["bad"],
    //             this.selectedColors["great"],
    //             this.selectedColors["bad"],
    //           ],
    //           borderWidth: 2,
    //         },
    //       ],
    //     },
    //     options: {
    //       responsive: true,
    //       title: {
    //         display: true,
    //         text: this.carbsTitle,
    //       },
    //       layout: {
    //         padding: {
    //           bottom: 30,
    //         },
    //       },
    //       needle: {
    //         // Needle circle radius as the percentage of the chart area width
    //         radiusPercentage: 2,
    //         // Needle width as the percentage of the chart area width
    //         widthPercentage: 3.2,
    //         // Needle length as the percentage of the interval between inner radius (0%) and outer radius (100%) of the arc
    //         lengthPercentage: 80,
    //         // The color of the needle
    //         color: "rgba(0, 0, 0, 1)",
    //       },
    //       valueLabel: {
    //         display: false,
    //         formatter: Math.round,
    //       },
    //     },
    //   };

    //   const canvasCarbohydrates = document.getElementById(
    //     "carbohydrates"
    //   ) as HTMLCanvasElement;
    //   const ctxCarbohydrates = canvasCarbohydrates.getContext("2d");

    //   this.doughnutChart = new Chart(ctxCarbohydrates, confCarbohydrates);
    // });

    // const coll = document.getElementsByClassName("collapsible");
    // for (let i = 0; i < coll.length; i++) {
    //   coll[i].addEventListener("click", function () {
    //     const content = this.nextElementSibling as HTMLElement;
    //     if (this.classList.contains("active")) {
    //       this.classList.remove("active");
    //       content.style.maxHeight = null;
    //     } else {
    //       let c;
    //       for (c = 0; c < coll.length; c++) {
    //         coll[c].classList.remove("active");
    //         const nextElmnt = coll[c].nextElementSibling as HTMLElement;
    //         nextElmnt.style.maxHeight = null;
    //       }
    //       this.classList.add("active");
    //       content.style.maxHeight = content.scrollHeight + "px";
    //     }
    //   });
    // }
  }

  getDietaryFiberLevel() {
    const fiberValue = this.nutrientValues.find((x) => x.type === "fibers");
    if (fiberValue.value > 100) {
      fiberValue.value = 100;
    }
    if (fiberValue.value < 0) {
      fiberValue.value = 0;
    }
    return "calc(" + fiberValue.value + "% - 15px";
  }

  getSaltLevel() {
    const saltValue = this.nutrientValues.find((x) => x.type === "salt");
    const ref = this.mediumSalt + this.higherSalt;
    let cal = (saltValue.value / ref) * 100;

    console.log(saltValue.value);

    // if (cal > 100) {
    //   cal = 95;
    // }

    // if (cal < 0) {
    //   cal = 5;
    // }

    return "calc(" + cal + "% - 15px)";
  }

  hideNonPartnerElements() {
    let elements = Array.from(
      document.getElementsByClassName("none-partner-elements")
    );
    for (const element of elements) {
      element.setAttribute("style", "display: none");
    }
  }

  toggleSectionToDisplay(section: number) {
    // If the same section is clicked again, close it
    this.sectionToDisplay = this.sectionToDisplay === section ? 0 : section;
  }
  calculateCarbohydratesChart(carbohydratesObject: INutrientAdvice) {
    if (
      !carbohydratesObject ||
      !carbohydratesObject.range ||
      carbohydratesObject.range.length === 0
    ) {
      console.warn("Invalid carbohydratesObject data, using default values.");
      this.carbohydratesValueRanges = ["33%", "33%", "34%"]; // Default fallback
      return;
    }

    const totalRange =
      carbohydratesObject.range[carbohydratesObject.range.length - 1][1];
    this.carbohydratesValueRanges = carbohydratesObject.range.map(
      (range) => `${((range[1] - range[0]) / totalRange) * 100}%`
    );

    console.log("Carbohydrates Chart Values:", this.carbohydratesValueRanges);
  }

  calculateFatChart(fatObject: INutrientAdvice) {
    if (!fatObject || !fatObject.range || fatObject.range.length === 0) {
      console.warn("Invalid fatObject data, using default values.");
      this.fatValueRanges = ["33%", "33%", "34%"]; // Default fallback
      return;
    }

    const totalRange = fatObject.range[fatObject.range.length - 1][1];
    this.fatValueRanges = fatObject.range.map(
      (range) => `${((range[1] - range[0]) / totalRange) * 100}%`
    );

    console.log("Fat Chart Values:", this.fatValueRanges);
  }

  calculateSaturatedFatChart(satFatObject: INutrientAdvice) {
    if (
      !satFatObject ||
      !satFatObject.range ||
      satFatObject.range.length === 0
    ) {
      console.warn("Invalid satFatObject data, using default values.");
      this.saturatedFatValueRanges = ["33%", "33%", "34%"]; // Default fallback
      return;
    }

    const totalRange = satFatObject.range[satFatObject.range.length - 1][1];
    this.saturatedFatValueRanges = satFatObject.range.map(
      (range) => `${((range[1] - range[0]) / totalRange) * 100}%`
    );

    console.log("Saturated Fat Chart Values:", this.saturatedFatValueRanges);
  }
  getFiberLevel() {
    const fiberObject = this.nutrientValues.find((x) => x.type === "fibers");

    if (!fiberObject || !fiberObject.range) {
      console.warn("Invalid fiber data");
      return "calc(0% - 15px)";
    }

    const totalRange = fiberObject.range[fiberObject.range.length - 1][1];
    const positionPercentage = (fiberObject.value / totalRange) * 100;

    console.log("Fiber Needle Position:", positionPercentage);
    return `calc(${positionPercentage}% - 15px)`;
  }

  getCarbohydratesLevel() {
    const carbohydratesObject = this.nutrientValues.find(
      (x) => x.type === "carbohydrates"
    );

    if (!carbohydratesObject || !carbohydratesObject.range) {
      console.warn("Invalid carbohydrates data");
      return "calc(0% - 15px)";
    }

    const totalRange =
      carbohydratesObject.range[carbohydratesObject.range.length - 1][1];
    const positionPercentage = (carbohydratesObject.value / totalRange) * 100;

    console.log("Carbohydrates Needle Position:", positionPercentage);
    return `calc(${positionPercentage}% - 15px)`;
  }

  getFatLevel() {
    const fatObject = this.nutrientValues.find((x) => x.type === "fat");

    if (!fatObject || !fatObject.range) {
      console.warn("Invalid fat data");
      return "calc(0% - 15px)";
    }

    const totalRange = fatObject.range[fatObject.range.length - 1][1];
    const positionPercentage = (fatObject.value / totalRange) * 100;

    console.log("Fat Needle Position:", positionPercentage);
    return `calc(${positionPercentage}% - 15px)`;
  }

  getSaturatedFatLevel() {
    const satFatObject = this.nutrientValues.find(
      (x) => x.type === "saturated_fat"
    );

    if (!satFatObject || !satFatObject.range) {
      console.warn("Invalid saturated fat data");
      return "calc(0% - 15px)";
    }

    const totalRange = satFatObject.range[satFatObject.range.length - 1][1];
    const positionPercentage = (satFatObject.value / totalRange) * 100;

    console.log("Saturated Fat Needle Position:", positionPercentage);
    return `calc(${positionPercentage}% - 15px)`;
  }
}
