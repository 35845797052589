import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-relationship-between-diet-and-microbiome',
  templateUrl: './relationship-between-diet-and-microbiome.component.html',
  styleUrls: ['./relationship-between-diet-and-microbiome.component.css']
})
export class RelationshipBetweenDietAndMicrobiomeComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
