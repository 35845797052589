import {Component, OnInit} from '@angular/core';
import {FormBuilder} from '@angular/forms';
import {ISubject} from 'interfaces/tests';
import {SubjectService} from '../api/test/subject';
import {ActivatedRoute} from '@angular/router';
import {UtilityService} from "../utility.service";

@Component({
  selector: 'app-subject-details',
  templateUrl: './subject-details.component.html',
  styleUrls: ['./subject-details.component.css']
})
export class SubjectDetailsComponent implements OnInit {

  constructor(private formBuilder: FormBuilder, private subjectService: SubjectService, private utils: UtilityService, private activatedRoute: ActivatedRoute) {
  }

  title = 'Profile photo';
  btnEditInfo = 'Edit information';
  subject: ISubject;
  private sub: any;
  id: number;

  subjectDetailsForm = this.formBuilder.group({
    full_name: '',
    gender: '',
    date_of_birth: ''
  });

  async ngOnInit() {
    this.sub = this.activatedRoute.params.subscribe(params => {
      this.id = params.id;
    });
    const response = await this.subjectService.GetSubject(this.id);
    this.subject = response.data;
    this.subjectDetailsForm.get('full_name').setValue(this.subject.full_name);
    this.subjectDetailsForm.get('gender').setValue(this.subject.gender);
    this.subjectDetailsForm.get('date_of_birth').setValue(this.subject.date_of_birth);
  }

  async onSubmit() {
    const newSubject: ISubject = {
      full_name: this.subjectDetailsForm.get('full_name').value,
      gender: this.subjectDetailsForm.get('gender').value,
      date_of_birth: this.subjectDetailsForm.get('date_of_birth').value
    };
    const response = await this.subjectService.UpdateSubject(newSubject, this.id).then(data => {
      this.utils.showSuccess('UPDATE_USER_SUBJECT_SUCCESS', 'UPDATE_USER_SUBJECT_SUCCESS');
    }).catch(error => {
      this.utils.showError('UPDATE_USER_SUBJECT_ERROR', 'UPDATE_USER_SUBJECT_ERROR');
    });
  }
}
