import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class TestKitIdService {
  public testKitID: number;

  constructor() { }

  setTestKitID(id: number): void {
    this.testKitID = id;
  }

  getTestKitID(): number {
    const testKitID = localStorage.getItem('currentTestKitID');
    if (testKitID) {
     return parseInt(testKitID, 10);
    }
      return null;
  }
}