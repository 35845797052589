import {Component, OnInit} from '@angular/core';
import {FormBuilder} from '@angular/forms';
import {ISubject} from 'interfaces/tests';
import {ActivatedRoute, Router} from '@angular/router';
import {SubjectService} from '../api/test/subject';
import {UtilityService} from "../utility.service";
import {TranslateService} from "@ngx-translate/core";

@Component({
  selector: 'app-new-user',
  templateUrl: './new-user.component.html',
  styleUrls: ['./new-user.component.css']
})
export class NewUserComponent implements OnInit {

  constructor(private formBuilder: FormBuilder, private router: Router, private subjectService: SubjectService,
              private route: ActivatedRoute, private utils: UtilityService, private translate: TranslateService) {
  }

  categoryId: number;

  private sub: any;

  createSubjectForm = this.formBuilder.group({
    name: '',
    gender: '',
    date_of_birth: ''
  });

  ngOnInit() {
    this.sub = this.route.params.subscribe(params => {
      this.categoryId = params.id;
    });
  }

  async onSubmit() {
    try {
      const newSubject: ISubject = {
        full_name: this.createSubjectForm.get('name').value,
        gender: this.createSubjectForm.get('gender').value,
        date_of_birth: this.createSubjectForm.get('date_of_birth').value,
        category_id: this.categoryId
      };

      const response = await this.subjectService.CreateSubject(newSubject);
      this.utils.showSuccess('NEW_USER_SUCCESS', 'NEW_USER_SUCCESS');
      await this.router.navigate(['/account-category/' + this.categoryId]);
    } catch {
      this.utils.showWarning('NEW_USER_WARNING', 'NEW_USER_WARNING');
    }
  }
}
