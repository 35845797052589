import {Component, OnInit, Input} from '@angular/core';
import * as Survey from 'survey-angular';
import {SurveyService} from '../api/survey/survey';
import {ActivatedRoute} from '@angular/router';
import {SubjectService} from '../api/test/subject';
import {IUserSurveyAnswerText} from '../../../interfaces/surveys';
import {ITestKit} from '../../../interfaces/tests';
import { AppComponent } from "../app.component";

@Component({
  selector: 'survey-answers',
  templateUrl: './survey-answers.component.html',
  styleUrls: ['./survey-answers.component.css']
})
export class SurveyAnswersComponent implements OnInit {

  @Input() questionIDs: number[] | null = null;  // Input to accept optional question IDs
  @Input() containerbStyles: any = {};  // Styles for the container
  @Input() tableStyles: any = {};

  userSurveyAnswers: IUserSurveyAnswerText[];
  testKitID: number;
  testKitStatus: string='finished';

  constructor(
    private surveyService: SurveyService, 
    private testkitService: SubjectService,
    private route: ActivatedRoute,
    public app: AppComponent,
  ) {}

  async ngOnInit() {
    try {
      this.testKitID = Number(this.route.snapshot.paramMap.get("id"));
      
      // Pass the optional questionIDs to the mergeAnswers method
      this.userSurveyAnswers = this.mergeAnswers(
        (await this.surveyService.GetUserServeyAnswers(this.testKitID)).data,
        this.questionIDs
      );
      console.log("test");
      console.log(this.userSurveyAnswers);
      
      let testKit = (await this.testkitService.GetTestKit(this.testKitID)).data;
      this.testKitStatus = testKit.status;
    } catch {
      console.log("error");
    }
  }

  mergeAnswers(answers: IUserSurveyAnswerText[], questionIDs?: number[]) {
    let uniqueQuestions: number[] = [];
    let filteredUserAnswers: IUserSurveyAnswerText[] = [];
    let duplicateUserAnswers: IUserSurveyAnswerText[] = [];

    // If questionIDs is provided, filter the answers to only include those question IDs
    if (questionIDs && questionIDs.length > 0) {
        answers = answers.filter(answer => questionIDs.includes(answer.question_id));
    }

    // First pass to collect unique questions
    for (let i = 0; i < answers.length; i++) {
        if (!uniqueQuestions.includes(answers[i].question_id)) {
            uniqueQuestions.push(answers[i].question_id);
            filteredUserAnswers.push(answers[i]);
        } else {
            duplicateUserAnswers.push(answers[i]);
        }
    }

    // Second pass to merge duplicate answers
    for (let x = 0; x < filteredUserAnswers.length; x++) {
        for (let y = 0; y < duplicateUserAnswers.length; y++) {
            if (filteredUserAnswers[x].question_id === duplicateUserAnswers[y].question_id) {
                // Merge answer_text
                if (duplicateUserAnswers[y].answer_text) {
                    filteredUserAnswers[x].answer_text = filteredUserAnswers[x].answer_text
                        ? filteredUserAnswers[x].answer_text.concat(', ', duplicateUserAnswers[y].answer_text)
                        : duplicateUserAnswers[y].answer_text;
                }
                // Merge openAnswer
                if (duplicateUserAnswers[y].openAnswer) {
                    filteredUserAnswers[x].answer_text = filteredUserAnswers[x].answer_text
                        ? filteredUserAnswers[x].answer_text.concat(', ', duplicateUserAnswers[y].openAnswer)
                        : duplicateUserAnswers[y].openAnswer;
                }
            }
        }
    }

    return filteredUserAnswers;
}


}
