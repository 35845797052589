import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-soil',
  templateUrl: './soil.component.html',
  styleUrls: ['./soil.component.css']
})
export class SoilComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

  image = '../../assets/plant.png';

}
