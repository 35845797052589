import { Component, OnInit } from '@angular/core';
import {LangChangeEvent, TranslateService} from "@ngx-translate/core";

@Component({
  selector: 'app-about-us',
  templateUrl: './about-us.component.html',
  styleUrls: ['./about-us.component.css']
})
export class AboutUsComponent implements OnInit {

  constructor(private translate: TranslateService) {
  }

  aboutus = [
    {
      id: 1,
      name: 'Our team',
      image: '../../assets/people_variant.png'
    },
    {
      id: 2,
      name: 'Our target',
      image: '../../assets/target_variant.png'
    },
    {
      id: 3,
      name: 'Our differentiators',
      image: '../../assets/transfer_variant.png'
    },
    {
      id: 4,
      name: 'FAQ',
      image: '../../assets/question_mark_variant.png'
    }
  ];

  ngOnInit() {
  }

}
