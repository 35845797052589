import { Component, Input, OnInit } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: "app-take-home-focus-group-dashboard",
  templateUrl: "./take-home-focus-group-dashboard.component.html",
  styleUrls: ["./take-home-focus-group-dashboard.component.css"],
})
export class TakeHomeFocusGroupDashboardComponent implements OnInit {
  constructor(private translate: TranslateService) {}
  @Input() focusGroup: string = "";
  ngOnInit() {}
}
