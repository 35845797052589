import { Component, Input, OnInit } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: "app-take-home-diet-adjustment-meal",
  templateUrl: "./take-home-diet-adjustment-meal.component.html",
  styleUrls: ["./take-home-diet-adjustment-meal.component.css"],
})
export class TakeHomeDietAdjustmentMealComponent implements OnInit {
  openContainer: boolean = false;

  constructor(private translate: TranslateService) {}
  @Input() title: string = "";
  @Input() recommendations: Array<string> = [];
  @Input() tips: Array<string> = [];

  ngOnInit() {}
  toggleAdviceContainer() {
    this.openContainer = !this.openContainer;
  }
}
