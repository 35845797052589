import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms'; // Import Validators
import { ISubject } from 'interfaces/tests';
import { ActivatedRoute, Router } from '@angular/router';
import { SubjectService } from '../api/test/subject';
import { UtilityService } from "../utility.service";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: 'app-new-user',
  templateUrl: './new-user.component.html',
  styleUrls: ['./new-user.component.css']
})
export class NewUserComponent implements OnInit {

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private subjectService: SubjectService,
    private route: ActivatedRoute,
    private utils: UtilityService,
    private translate: TranslateService
  ) {}

  categoryId: number;

  private sub: any;

  // Define separate fields for first_name and last_name
  createSubjectForm = this.formBuilder.group({
    first_name: ['', Validators.required], // Add Validators for required fields
    last_name: ['', Validators.required],  // Add Validators for required fields
    date_of_birth: ['', Validators.required] // Add Validators for required fields
  });

  ngOnInit() {
    // Fetch category ID from route parameters
    this.sub = this.route.params.subscribe(params => {
      this.categoryId = params.id;
    });
  }

  async onSubmit() {
    // Check if the form is valid
    if (this.createSubjectForm.invalid) {
      this.utils.showWarning('PAYMENT_USER_REGISTRATION_ERROR', 'PAYMENT_USER_REGISTRATION_ERROR');
      return;
    }

    try {
      // Combine first_name and last_name into full_name
      const fullName = `${this.createSubjectForm.get('first_name').value} ${this.createSubjectForm.get('last_name').value}`;

      const newSubject: ISubject = {
        full_name: fullName,
        gender: null, // Adjust this based on actual requirements (e.g., add a gender field if necessary)
        date_of_birth: this.createSubjectForm.get('date_of_birth').value,
        category_id: this.categoryId
      };

      // Send request to create a new subject
      const response = await this.subjectService.CreateSubject(newSubject);
      this.utils.showSuccess('NEW_USER_SUCCESS', 'NEW_USER_SUCCESS');

      // Navigate to account category page
      await this.router.navigate(['/account-category/' + this.categoryId]);
    } catch (error) {
      this.utils.showWarning('NEW_USER_WARNING', 'NEW_USER_WARNING');
    }
  }
}
