import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { AboutUsComponent } from "./about-us/about-us.component";
import { AccountCategoryComponent } from "./account-category/account-category.component";
import { AccountDetailsComponent } from "./account-details/account-details.component";
import { AccountQuestionsComponent } from "./account-questions/account-questions.component";
import { AkkermansiaComponent } from "./akkermansia/akkermansia.component";
import { AnimalsComponent } from "./animals/animals.component";
import { AuthGuardService as AuthGuard } from "./api/login/auth";
import { BacteriaDiversityComponent } from "./bacteria-diversity/bacteria-diversity.component";
import { BacteriaLevelsComponent } from "./bacteria-levels/bacteria-levels.component";
import { BifidobacteriumComponent } from "./bifidobacterium/bifidobacterium.component";
import { BmiComponent } from "./bmi/bmi.component";
import { BuyComponent } from "./buy/buy.component";
import { ColonProblemComponent } from "./colon-problem/colon-problem.component";
import { ContactUsComponent } from "./contact-us/contact-us.component";
import { CookiePolicyComponent } from "./cookie-policy/cookie-policy.component";
import { CreateAccountComponent } from "./create-account/create-account.component";
import { DashboardComponent } from "./dashboard/dashboard.component";
import { ExampleFoodDiaryComponent } from "./example-food-diary/example-food-diary.component";
import { ExtraOptionsComponent } from "./extra-options/extra-options.component";
import { FaqsComponent } from "./faqs/faqs.component";
import { FatAlarmComponent } from "./fat-alarm/fat-alarm.component";
import { FoodDiaryComponent } from "./food-diary/food-diary.component";
import { FoodTrackerComponent } from "./food-tracker/food-tracker.component";
import { GasProductionComponent } from "./gas-production/gas-production.component";
import { GutHealthComponent } from "./gut-health/gut-health.component";
import { HealthAndDiseasesComponent } from "./health-and-diseases/health-and-diseases.component";
import { HealthyMicroorganismsComponent } from "./healthy-microorganisms/healthy-microorganisms.component";
import { HealthySubstancesComponent } from "./healthy-substances/healthy-substances.component";
import { HumanButtonsComponent } from "./human-buttons/human-buttons.component";
import { HumanResultsComponent } from "./human-results/human-results.component";
import { HumanComponent } from "./human/human.component";
import { InfectionAlarmComponent } from "./infection-alarm/infection-alarm.component";
import { InformationComponent } from "./information/information.component";
import { LaboratoriesComponent } from "./laboratories/laboratories.component";
import { LandingPageComponent } from "./landing-page/landing-page.component";
import { MaintenanceComponent } from "./maintenance/maintenance.component";
import { NewUserComponent } from "./new-user/new-user.component";
import { NotLoggedInComponent } from "./not-logged-in-page/not-logged-in-page.component";
import { OrderHistoryComponent } from "./order-history/order-history.component";
import { PasswordRecoveryComponent } from "./password-recovery/password-recovery.component";
import { PaymentDetailsComponent } from "./payment-details/payment-details.component";
import { PdfDashboardComponent } from "./pdf-dashboard/pdf-dashboard.component";
import { PersonalizedDietaryAdviceComponent } from "./personalized-dietary-advice/personalized-dietary-advice.component";
import { PhysicalExerciseFitnessAndGutComponent } from "./physical-exercise-fitness-and-gut/physical-exercise-fitness-and-gut.component";
import { PrivacyPolicyComponent } from "./privacy-policy/privacy-policy.component";
import { ProcessComponent } from "./process/process.component";
import { RelationshipBetweenDietAndMicrobiomeComponent } from "./relationship-between-diet-and-microbiome/relationship-between-diet-and-microbiome.component";
import { ResetPasswordComponent } from "./reset-password/reset-password.component";
import { ResultsTableComponent } from "./results-table/results-table.component";
import { ShippingPolicyComponent } from "./shipping-policy/shipping-policy.component";
import { ShoppingCartComponent } from "./shopping-cart/shopping-cart.component";
import { SoilComponent } from "./soil/soil.component";
import { SubjectDetailsComponent } from "./subject-details/subject-details.component";
import { SuccessfulPaymentComponent } from "./successful-payment/successful-payment.component";
import { SurveyComponent } from "./survey/survey.component";
import { TakeHomeMessageComponent } from "./take-home-message/take-home-message.component";
import { TargetValuesComponent } from "./target-values/target-values.component";
import { TermsAndConditionsComponent } from "./terms-and-conditions/terms-and-conditions.component";
import { TestCategoryComponent } from "./test-category/test-category.component";
import { TestComponentComponent } from "./test-component/test-component.component";
import { TestResultsComponent } from "./test-results/test-results.component";
import { TestingProceduresComponent } from "./testing-procedures/testing-procedures.component";
import { TopBacteriaComponent } from "./top-bacteria/top-bacteria.component";
import { UnsubscribeEmail } from "./unsubscribe-email/unsubscribe-email.component";
import { KitComparisonComponent } from "./kit-comparison/kit-comparison.component";
import { SurveyAnswersComponent } from "./survey-answers/survey-answers.component";
import { SupplementAdviceComponent } from "./supplement-advice/supplement-advice.component";

const routes: Routes = [
  { path: "buy", component: TestCategoryComponent }, /// done
  { path: "test-results", component: TestResultsComponent }, /// done
  { path: "test-results/:token", component: TestResultsComponent }, /// done
  { path: "information", component: InformationComponent }, /// done
  { path: "about-us", component: AboutUsComponent }, /// done
  { path: "information/human", component: HumanComponent }, /// done
  {
    path: "information/human/relationship",
    component: RelationshipBetweenDietAndMicrobiomeComponent,
  }, /// done
  {
    path: "information/human/testing-procedures",
    component: TestingProceduresComponent,
  }, /// done
  { path: "information/human/laboratories", component: LaboratoriesComponent }, /// done
  {
    path: "information/human/health-and-diseases",
    component: HealthAndDiseasesComponent,
  }, /// done
  {
    path: "information/human/physical-exercise",
    component: PhysicalExerciseFitnessAndGutComponent,
  }, /// done
  // {path: 'information/animals', component: AnimalsComponent},  /// done
  // {path: 'information/soil', component: SoilComponent},  /// done
  { path: "information/bacteria", component: TopBacteriaComponent }, /// done
  { path: "buy/test-category/:id", component: ExtraOptionsComponent }, /// done
  { path: "create-account", component: CreateAccountComponent }, /// done
  {
    path: "account-details",
    component: AccountDetailsComponent,
    canActivate: [AuthGuard],
  }, /// done
  {
    path: "food-tracker/:id",
    component: FoodTrackerComponent,
    canActivate: [AuthGuard],
  }, /// done
  { path: "information/process", component: ProcessComponent },
  { path: "create-account/:type", component: AccountQuestionsComponent }, /// done
  {
    path: "food-diary/:id",
    component: FoodDiaryComponent,
    canActivate: [AuthGuard],
  }, /// almost done (waiting for jellas email)
  { path: "food-diary-example", component: ExampleFoodDiaryComponent }, /// almost done (waiting for jellas email)
  {
    path: "account-category",
    component: AccountCategoryComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "account-category/:id", /// Google Analytics cross domain tracking
    component: HumanResultsComponent,
  }, /// done
  {
    path: "new-user/:id",
    component: NewUserComponent,
    canActivate: [AuthGuard],
  }, /// done
  { path: "shopping-cart", component: ShoppingCartComponent }, /// done
  {
    path: "payment-details",
    component: PaymentDetailsComponent,
  }, /// done
  {
    path: "payment-successful",
    component: SuccessfulPaymentComponent,
  }, /// done
  {
    path: "results-table/:id",
    component: ResultsTableComponent,
    canActivate: [AuthGuard],
  }, /// done (does need code for buttons)
  {
    path: "dashboard/:id",
    component: DashboardComponent,
    canActivate: [AuthGuard],
  },
  { path: "target-nutrient-values/:id", component: TargetValuesComponent },
  {
    path: "personalized-dietary-advice/:id",
    component: PersonalizedDietaryAdviceComponent,
  },
  { path: "bmi/:id", component: BmiComponent, canActivate: [AuthGuard] },
  {
    path: "healthy-microorganisms/:id",
    component: HealthyMicroorganismsComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "bifidobacterium/:page/:id",
    component: BifidobacteriumComponent,
    canActivate: [AuthGuard],
  },
  { path: "survey/:id", component: SurveyComponent, canActivate: [AuthGuard] }, 
  { path: "survey-answers/:id", component: SurveyAnswersComponent, canActivate: [AuthGuard] }, 
  { path: "faqs", component: FaqsComponent },
  { path: "password-recovery", component: PasswordRecoveryComponent }, /// done
  { path: "reset-password/:token", component: ResetPasswordComponent }, /// done
  {
    path: "subject-details/:id",
    component: SubjectDetailsComponent,
    canActivate: [AuthGuard],
  }, /// done
  {
    path: "order-history",
    component: OrderHistoryComponent,
    canActivate: [AuthGuard],
  },
  { path: "terms-and-conditions", component: TermsAndConditionsComponent },
  { path: "privacy-policy", component: PrivacyPolicyComponent },
  { path: "shipping-policy", component: ShippingPolicyComponent },
  { path: "cookie-policy", component: CookiePolicyComponent },
  { path: "contact-us", component: ContactUsComponent },
  {
    path: "subcategory/:page/:id",
    component: GutHealthComponent,
  },
  {
    path: "take-home-message/:id",
    component: TakeHomeMessageComponent,
    canActivate: [AuthGuard],
  },
  { 
    path: 'supplement-advice/:id',
    component: SupplementAdviceComponent,
    canActivate: [AuthGuard] },
  {
    path: "kit-comparison",
    component: KitComparisonComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "gut-health/:id",
    component: GutHealthComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "colon-problem/:id",
    component: ColonProblemComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "infection-alarm/:id",
    component: InfectionAlarmComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "gas-production/:id",
    component: GasProductionComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "fat-alarm/:id",
    component: FatAlarmComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "healthy-substances/:id",
    component: HealthySubstancesComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "bacteria-diversity",
    component: BacteriaDiversityComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "bacteria-levels",
    component: BacteriaLevelsComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "akkermansia",
    component: AkkermansiaComponent,
    canActivate: [AuthGuard],
  },
  { path: "test-component", component: TestComponentComponent },
  { path: "mantainance", component: MaintenanceComponent },
  { path: "not-logged-in", component: NotLoggedInComponent },
  { path: "pdf-dashboard/:id", component: PdfDashboardComponent },
  { path: "unsubscribe-email/:email", component: UnsubscribeEmail },
  { path: "", component: TestResultsComponent },

  // Iprobio link alternatives

  { path: "vysledky", component: TestResultsComponent },
  { path: "vysledky/:token", component: TestResultsComponent },
  { path: "obnova-hesla", component: PasswordRecoveryComponent },
  { path: "vytvorit-ucet", component: CreateAccountComponent },
  { path: "vytvorit-ucet/:type", component: AccountQuestionsComponent },
  {
    path: "detaily-účtu",
    component: AccountDetailsComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "ucet",
    component: AccountCategoryComponent,
    canActivate: [AuthGuard],
  },
  { path: "ucet/:id", component: HumanResultsComponent },
  {
    path: "nový-užívateľ/:id",
    component: NewUserComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "stravovací-denník/:id",
    component: FoodDiaryComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "príklad-potravinového-denníka",
    component: ExampleFoodDiaryComponent,
  },
  {
    path: "prieskum/:id",
    component: SurveyComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "moje-výsledky/:id",
    component: DashboardComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "diverzita-baktérií",
    component: BacteriaDiversityComponent,
    canActivate: [AuthGuard],
  },
  { path: "cieľové-hodnoty-živín/:id", component: TargetValuesComponent },
  { path: "podkategória/:page/:id", component: GutHealthComponent },
  { path: "hladiny-baktérií", component: BacteriaLevelsComponent, canActivate: [AuthGuard]},
  { path: "zhrnutie/:id", component: TakeHomeMessageComponent, canActivate: [AuthGuard]},
  { path: "detaily-pacienta/:id", component: SubjectDetailsComponent, canActivate: [AuthGuard]},
  { path: "odpovede-prieskumu/:id", component: SurveyAnswersComponent, canActivate: [AuthGuard]},

  
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
