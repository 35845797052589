import {
  Component,
  OnInit,
  ElementRef,
  Renderer2,
  AfterViewInit,
  HostListener,
  ViewChild,
  ChangeDetectorRef,
  NgZone,
} from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { Chart } from "chart.js";
import {
  IEyyo,
  INutrientAdvice,
  IPrebioticCompound,
  IPrebioticSupplement,
  IProbioticPrebioticAdvice,
  IProbioticResult,
} from "../../../interfaces/advice";
import { DietaryDashService } from "../api/calculations/dietarydash";
import { BacteriaLevelsComponent } from "../bacteria-levels/bacteria-levels.component";
import { TargetValuesComponent } from "../target-values/target-values.component";
import { AppComponent } from "../app.component";
import { DashboardService } from "../api/calculations/dashboard";
import { TestKitIdService } from "../testkit-id-get/testkitid.service";
import top35 from "../../assets/json/top35.json";
import BacteriaDivision from "../../assets/json/bacteriaDivision.json";
import jsPDF from "jspdf";
import autoTable from "jspdf-autotable";

import { SurveyAnswersComponent } from "../survey-answers/survey-answers.component";

@Component({
  selector: "app-supplement-advice",
  templateUrl: "./supplement-advice.component.html",
  styleUrls: ["./supplement-advice.component.css"],
})
export class SupplementAdviceComponent implements OnInit {
  @ViewChild("infoIcon", { static: false }) infoIcon: ElementRef | null = null;

  testKitID: number;
  importantSurveyQuestions = [
    279, 280, 281, 288, 293, 297, 299, 313, 330, 363, 368, 404,
  ];
  // Bacteria graphs
  bacteriaJson: any[] = [];
  selectedBacteria: any[] = [];
  specificBacteriaNames = [
    "Lactobacillus",
    "Faecalibacterium",
    "Akkermansia",
    "Bifidobacterium",
  ];
  dashBacteria: IEyyo;
  division;
  bacteriaDescription: any;
  loading: boolean = true;

  // Pre/Probiotics
  probioticResults: IProbioticResult[] = [];
  groupedProbioticResults: { [key: string]: IProbioticResult[] } = {};
  // Prebiotic supplements data
  prebioticSupplements: IPrebioticSupplement[] = [];
  prebioticCompounds: IPrebioticCompound[] = [];

  medicalTagImages = {
    2: `assets/medical_tags/gluten${
      this.app.partnerVersion ? "-" + this.app.partnerVersion : ""
    }.png`,
    4: `assets/medical_tags/histamine${
      this.app.partnerVersion ? "-" + this.app.partnerVersion : ""
    }.png`,
    1: `assets/medical_tags/ibs${
      this.app.partnerVersion ? "-" + this.app.partnerVersion : ""
    }.png`,
    3: `assets/medical_tags/lactose${
      this.app.partnerVersion ? "-" + this.app.partnerVersion : ""
    }.png`,
  };

  medicalTagDescriptions = {
    2: "SUB_CATEGORIES.GUT_WALL_STRENGTH.GLUTEN_IMG_DESCRIPTION",
    4: "SUB_CATEGORIES.GUT_WALL_STRENGTH.HISTAMINE_IMG_DESCRIPTION",
    1: "SUB_CATEGORIES.GUT_WALL_STRENGTH.IBS_IMG_DESCRIPTION",
    3: "SUB_CATEGORIES.GUT_WALL_STRENGTH.LACTOSE_IMG_DESCRIPTION",
  };
  showIbsTag = false;
  showGlutenTag = false;
  showLactoseTag = false;
  showHistamineTag = false;
  hoveredTag: string | null = null;

  hoveredFoodItem: any | null = null;
  dynamicMaxWidth: string | null = null;

  // Dropdown states for probiotics and prebiotics
  dropdownState = {
    probioticsAnalysis: false,
    prebioticsAnalysis: false,
    probioticsRec: false,
    prebioticsRec: false,
    probioticsRecGuidelines: false,
    probioticsRecFAQ: false,
    prebioticsRecGuidelines: false,
    prebioticsRecFAQ: false,
    probioticStrainsCategories: false,
    dynamicProbioticsDropdownState: {} as { [key: string]: boolean },
    prebioticFoods: false,
    prebioticSupplements: false,
  };

  // Sub-dropdown states
  subDropdownProbioticsRecGuidelinesState = {
    dropdown_guidelines_3_1: false,
    dropdown_guidelines_3_2: false,
    dropdown_guidelines_3_3: false,
    dropdown_guidelines_3_4: false,
  };
  subDropdownProbioticsRecFAQState = {
    dropdown_faq_4_1: false,
    dropdown_faq_4_2: false,
    dropdown_faq_4_3: false,
    dropdown_faq_4_4: false,
    dropdown_faq_4_5: false,
    dropdown_faq_4_6: false,
    dropdown_faq_4_7: false,
  };
  subDropdownPrebioticsRecGuidelinesState = {
    dropdown_guidelines_3_1: false,
    dropdown_guidelines_3_2: false,
    dropdown_guidelines_3_3: false,
  };
  subDropdownPrebioticsRecFAQState = {
    dropdown_faq_4_1: false,
    dropdown_faq_4_2: false,
    dropdown_faq_4_3: false,
    dropdown_faq_4_4: false,
    dropdown_faq_4_5: false,
    dropdown_faq_4_6: false,
    dropdown_faq_4_7: false,
    dropdown_faq_4_8: false,
    dropdown_faq_4_9: false,
    dropdown_faq_4_10: false,
  };

  // Nutrient chart-related variables
  carbohydratesValue = 5;
  carbohydratesValueRanges: any = [];
  fatValue = 4;
  fatValueRanges: any = [];
  saturatedFatValue = 3;
  saturatedFatValueRanges: any = [];

  fiberChartValues: any = [];
  fiberValue: any;
  saltChartValues: any = [];

  proteineObject: INutrientAdvice;
  saltObject: INutrientAdvice;
  fibreObject: INutrientAdvice;
  carbohydratesObject: INutrientAdvice;
  fatObject: INutrientAdvice;
  satFatObject: INutrientAdvice;

  nutrientValues: INutrientAdvice[] = [];

  allFoods: any[] = [];

  lowerFiber = 10;
  mediumFiber = 10;
  higherFiber = 10;
  lowerSalt = 0;
  mediumSalt = 6;
  higherSalt = 2;

  partnerImgSrc = "";
  gaugeColorVariants = {
    default: {
      normal: "#ad66d9",
      okay: "#cfbcf2",
      great: "#05e399",
      bad: "#fad408",
      aware: "#c8bfe7",
    },
    iprobio: {
      normal: "#62b3b3",
      okay: "#b5d2d2",
      great: "#6fd06f",
      bad: "#f299a1",
      aware: "#339d9c69",
    },
    patris_health: {
      normal: "#33ab9f",
      okay: "#81c784",
      great: "#66bb6a",
      bad: "#f44336",
      aware: "#b2dfdb",
    },
  };
  selectedColors = this.gaugeColorVariants["default"];

  constructor(
    private route: ActivatedRoute,
    private router: Router,

    private cdr: ChangeDetectorRef,
    private zone: NgZone,
    private dashboardService: DashboardService,
    private testKitIdService: TestKitIdService,
    private translate: TranslateService,
    public dietaryDashService: DietaryDashService,
    public app: AppComponent,
    private renderer: Renderer2,
    private elRef: ElementRef
  ) {}

  //////////////////////////////////////////////////////////////////////////////
  async ngOnInit() {
    this.loading = true; // Start loading spinner
    try {
      if (this.app.partnerVersion !== "") {
        this.partnerImgSrc = "-" + this.app.partnerVersion;
      }

      console.log(this.partnerImgSrc);

      if (this.app.partnerVersion === "iprobio") {
        this.router.navigate(["../"]); // Redirect to the previous page
        return;
      }

      this.testKitID = Number(this.route.snapshot.paramMap.get("id"));
      window.scrollTo(0, 0);

      this.dashBacteria = JSON.parse(sessionStorage.getItem("Dashboard"));
      this.division = BacteriaDivision;

      await this.loadNutrientValues();
      await this.getBacteriaList();
      this.filterSpecificBacteria();
      await this.ensureProbioticAndPrebioticData();

      // Manually trigger change detection to ensure the view is updated
      this.cdr.detectChanges();
    } catch (error) {
      console.error("Error during initialization:", error);
    } finally {
      this.loading = false;
      // Trigger change detection after loading is false
      this.cdr.detectChanges();
    }
  }
  async ensureProbioticAndPrebioticData() {
    try {
      await this.getProbioticAndPrebioticData();

      if (!this.probioticResults || this.probioticResults.length === 0) {
        throw new Error("Prebiotic data is still not loaded.");
      }

      // Ensure change detection is triggered after data is loaded
      this.cdr.detectChanges();
      console.log("Prebiotic and probiotic data loaded successfully.");
    } catch (error) {
      console.error("Error ensuring probiotic and prebiotic data:", error);
      throw error;
    }
  }

  //////////////////////////////////////////////////////////////////////////////
  // Bacteria graphs
  async getBacteriaList() {
    try {
      const bacteriaList = (
        await this.dashboardService.GetFullBacteriaList(this.testKitID)
      ).data;
      const dataArray = bacteriaList.split("\n");

      // Process each row and store bacteria data in an array
      this.bacteriaJson = dataArray
        .map((line, index) => {
          if (index === 0 || !line.trim()) return null;
          const columns = line.split(",");
          const bacteriaNameArray = columns[1].split(";");
          const bacteriaName = `${
            bacteriaNameArray[bacteriaNameArray.length - 2]
          } ${bacteriaNameArray[bacteriaNameArray.length - 1]}`
            .replace(/[_[\];]/g, " ")
            .trim();
          const count = parseInt(columns[2], 10);
          const percentage = parseFloat(columns[3]);

          // Exclude bacteria with 0 counts/percentages if they are not in top35
          if (
            count === 0 &&
            percentage === 0 &&
            !top35["top35"].includes(bacteriaName.split(" ")[1])
          ) {
            return null;
          }

          return { bacteriaName, count, percentage };
        })
        .filter((item) => item !== null);
    } catch (error) {
      console.error(error);
    }
  }

  filterSpecificBacteria() {
    this.dashBacteria.top35.forEach((elem) => {
      if (Object.values(this.division.good).includes(elem.bacteria)) {
        if (this.specificBacteriaNames.includes(elem.bacteria)) {
          this.selectedBacteria.push(elem);
        }
      }
    });

    // Sort the selectedBacteria alphabetically by the bacteria name
    this.selectedBacteria.sort((a, b) => a.bacteria.localeCompare(b.bacteria));

    console.log("Filtered and Sorted Bacteria List:", this.selectedBacteria);
  }

  toggleBacteriaDescription(bacteria) {
    bacteria
      ? (this.bacteriaDescription = bacteria)
      : (this.bacteriaDescription = "");
    if (this.infoIcon) {
      const iconPosition =
        this.infoIcon.nativeElement.getBoundingClientRect().left;
      console.log(iconPosition);
      this.dynamicMaxWidth =
        this.calculateMaxWidthFromIconPosition(iconPosition).toString();
      console.log(this.dynamicMaxWidth);
    }
  }

  getBacteriaLevel(bacteria: any): string {
    console.log(bacteria);
    const position =
      BacteriaLevelsComponent.prototype.getBacteriaLevel(bacteria);
    console.log(position);
    return position;
  }

  //////////////////////////////////////////////////////////////////////////////
  // Nutrient chart logic
  async loadNutrientValues() {
    if (sessionStorage.getItem("TargetNutValues-" + this.testKitID)) {
      this.nutrientValues = JSON.parse(
        sessionStorage.getItem("TargetNutValues-" + this.testKitID)
      );
    } else {
      this.nutrientValues = await (
        await this.dietaryDashService.GetNutrientAdvice(this.testKitID)
      ).data["nutrients"];
    }

    this.fibreObject = this.nutrientValues.find((x) => x.type === "fibers");
    this.calculateDietaryFiberChart(this.fibreObject);

    console.log(this.fibreObject);
  }

  calculateDietaryFiberChart(fiberObject: INutrientAdvice) {
    if (!fiberObject || !fiberObject.range || fiberObject.range.length === 0) {
      console.warn("Invalid fiberObject data, using default values.");
      this.fiberChartValues = ["33%", "33%", "34%"]; // Default fallback
      return;
    }

    // Calculate the percentage distribution of each range segment
    const totalRange = fiberObject.range[fiberObject.range.length - 1][1];
    this.fiberChartValues = fiberObject.range.map(
      (range) => `${((range[1] - range[0]) / totalRange) * 100}%`
    );

    // Adjust needle position for fiber value
    const fiberValue = fiberObject.value;
    this.fiberValue =
      fiberValue > totalRange
        ? `calc(100% - 15px)`
        : fiberValue < 0
        ? `calc(0% - 15px)`
        : `calc(${(fiberValue / totalRange) * 100}% - 15px)`;

    console.log("Updated Fiber Chart Values:", this.fiberChartValues);
    console.log("Fiber Needle Position:", this.fiberValue);
  }

  getDietaryFiberLevel() {
    const fiberValue = this.nutrientValues.find((x) => x.type === "fibers");
    if (fiberValue.value > 100) {
      fiberValue.value = 100;
    }
    if (fiberValue.value < 0) {
      fiberValue.value = 0;
    }
    return "calc(" + fiberValue.value + "% - 15px";
  }

  getFiberLevelAnalysisText(): string {
    // Check if fiberValue is in the format of "calc(XX% - YYpx)"
    const match = this.fiberValue.match(/calc\((\d+)%/); // Extract the percentage value from the calc() string
    const fiberValueNum = match ? parseFloat(match[1]) : NaN; // If match exists, convert to number

    console.log("Extracted Fiber Value:", fiberValueNum);

    // If fiberValueNum is NaN, handle it gracefully
    if (isNaN(fiberValueNum)) {
      console.error("fiberValue is not a valid number.");
      return this.translate.instant(
        "SUPPLEMENT_ADVICE.ANALYSIS_PREBIOTICS_DIETARY_FIBRES_CONTENT_1_BE_AWARE"
      );
    }

    const lowerBound = parseFloat(this.fiberChartValues[0]); // Lower range of the fiber graph
    const middleRange = parseFloat(this.fiberChartValues[1]); // Middle range of the fiber graph
    const upperBound = lowerBound + middleRange;

    // Determine which text to display based on the fiber value
    if (fiberValueNum <= lowerBound) {
      return this.translate.instant(
        "SUPPLEMENT_ADVICE.ANALYSIS_PREBIOTICS_DIETARY_FIBRES_CONTENT_1_BE_AWARE"
      );
    } else if (fiberValueNum <= upperBound) {
      return this.translate.instant(
        "SUPPLEMENT_ADVICE.ANALYSIS_PREBIOTICS_DIETARY_FIBRES_CONTENT_1_NORMAL"
      );
    } else {
      return this.translate.instant(
        "SUPPLEMENT_ADVICE.ANALYSIS_PREBIOTICS_DIETARY_FIBRES_CONTENT_1_GREAT"
      );
    }
  }

  //////////////////////////////////////////////////////////////////////////////

  // Dropdown toggling logic with grouping
  toggleDropdown(dropdownKey: string) {
    this.dropdownState[dropdownKey] = !this.dropdownState[dropdownKey];
  }

  getProbioticAndPrebioticData() {
    this.dietaryDashService
      .GetSupplementAdvice(this.testKitID, this.translate.getDefaultLang())
      .then((data: IProbioticPrebioticAdvice | undefined) => {
        if (data) {
          // Probiotic data
          this.probioticResults = data.probiotic_results;

          // Group the results by category
          this.groupedProbioticResults = this.probioticResults.reduce(
            (acc, current) => {
              const category = current.category;
              if (!acc[category]) {
                acc[category] = [];
              }
              acc[category].push(current);
              return acc;
            },
            {}
          );
          console.log("DATA: ");
          console.log(data);
          console.log(this.groupedProbioticResults);

          // Prebiotic supplements data
          this.prebioticSupplements = data.prebiotic_supplements;
          this.sortPrebioticBacteria();

          // Prebiotic compounds data from backend
          this.prebioticCompounds = data.prebiotic_compounds;
          console.log(this.prebioticCompounds);
          this.sortAndFilterFoods();

          this.getLegendMedicalTags(data.medical_tags);
        }
      })
      .catch((error) => {
        console.error("Error fetching probiotic recommendations: ", error);
      });
  }

  objectKeys(obj: any): string[] {
    return Object.keys(obj);
  }

  groupedBySubcategory(probiotics: IProbioticResult[]): {
    [key: string]: string[];
  } {
    const grouped: { [key: string]: string[] } = {};

    // Group species by their function (subcategory)
    probiotics.forEach((probiotic) => {
      const subcategory = probiotic.function;
      if (!grouped[subcategory]) {
        grouped[subcategory] = [];
      }
      grouped[subcategory].push(probiotic.probiotic_strain);
    });

    // Create a new object to hold the sorted result
    const sortedGrouped: { [key: string]: string[] } = {};

    // Sort subcategories alphabetically, leaving "Other" last
    const sortedSubcategories = Object.keys(grouped).sort((a, b) => {
      if (a.toLowerCase() === "other" || a.toLowerCase() === "others") return 1;
      if (b.toLowerCase() === "other" || b.toLowerCase() === "others")
        return -1;
      return a.localeCompare(b);
    });

    // Sort strains alphabetically within each subcategory and populate the sortedGrouped object
    sortedSubcategories.forEach((subcategory) => {
      grouped[subcategory].sort((a, b) => a.localeCompare(b)); // Sorting strain names alphabetically
      sortedGrouped[subcategory] = grouped[subcategory]; // Add the sorted strains into the sortedGrouped object
    });

    // Return the fully sorted object with both sorted subcategories and strains
    return sortedGrouped;
  }

  // Sort prebiotic supplements bacteria alphabetically before rendering
  sortPrebioticBacteria() {
    this.prebioticSupplements.sort((a, b) => {
      return a.bacteria.localeCompare(b.bacteria);
    });
  }

  getSortedCategories(): string[] {
    const categories = Object.keys(this.groupedProbioticResults);

    // Define "Other"/"Others" in multiple languages
    const otherCategoryTranslations = [
      "other",
      "others",
      "anders",
      "andere",
      "altro",
      "altri",
    ];

    // Filter to separate "Other"/"Others" categories
    const otherCategories = categories.filter((category) =>
      otherCategoryTranslations.includes(category.toLowerCase())
    );

    // Sort remaining categories alphabetically
    const sortedCategories = categories
      .filter(
        (category) =>
          !otherCategoryTranslations.includes(category.toLowerCase())
      )
      .sort();

    // Concatenate sorted categories with "Other"/"Others" categories
    return sortedCategories.concat(otherCategories);
  }

  getProbioticSpecies(strain: string): string {
    const probiotic = this.probioticResults.find(
      (item) => item.probiotic_strain === strain
    );
    return probiotic ? probiotic.species : "";
  }

  getProbioticNomenclature(strain, category, subcategory): string {
    if (!this.selectedStrain) {
      return "";
    }
    const probiotic = this.probioticResults.find(
      (item) =>
        item.probiotic_strain === this.selectedStrain.strain &&
        item.category === this.selectedStrain.category &&
        item.function === this.selectedStrain.subcategory
    );
    return probiotic ? probiotic.different_nomenclature : "";
  }

  getProbioticEvidenceLevel(
    strain: string,
    category: string,
    subcategory: string
  ): string {
    const probiotic = this.probioticResults.find(
      (item) =>
        item.probiotic_strain === strain &&
        item.category === category &&
        item.function === subcategory
    );

    if (probiotic && Array.isArray(probiotic.level_of_evidence)) {
      return probiotic.level_of_evidence.join(", "); // Join multiple evidence levels, if any
    } else {
      return "No evidence available"; // Fallback if no evidence level is provided
    }
  }

  getProbioticAdditionalInfo(
    strain: string,
    category: string,
    subcategory: string
  ): string {
    const probiotic = this.probioticResults.find(
      (item) =>
        item.probiotic_strain === strain &&
        item.category === category &&
        item.function === subcategory
    );
    return probiotic ? probiotic.additional_information : "";
  }

  getFormattedAdditionalInfo(
    strain: string,
    category: string,
    subcategory: string
  ): string {
    let info = this.getProbioticAdditionalInfo(strain, category, subcategory);
    if (!info) {
      return "";
    }

    const currentLanguage = this.translate.currentLang;
    const keywords = {
      effect: this.getTranslatedKeyword("Effect"),
      effects: this.getTranslatedKeyword("Effects"),
      remark: this.getTranslatedKeyword("Remark"),
      remarks: this.getTranslatedKeyword("Remarks"),
      strains: this.getTranslatedKeyword("Strains"),
    };

    // Apply formatting for each keyword to be bolded and on a new line
    info = info.replace(
      new RegExp(`\\b(${keywords.effect}|${keywords.effects}):`, "g"),
      "<br><b>$1</b>:"
    );
    info = info.replace(
      new RegExp(`\\b(${keywords.remark}|${keywords.remarks}):`, "g"),
      "<br><b>$1</b>:"
    );

    // Place "Strains" on a new line, bold it, and add a double line break after its content
    info = info.replace(
      new RegExp(`\\b(${keywords.strains}):([^<]*)`, "g"),
      "<br><b>$1</b>:$2<br>"
    );

    // Trim any leading/trailing <br> tags and whitespace for cleaner output
    return info
      .trim()
      .replace(/^(<br>)+/, "")
      .replace(/(<br>)+$/, "");
  }

  // Helper method to get language-specific translations for keywords
  getTranslatedKeyword(keyword: string): string {
    const translations = {
      en: {
        Effect: "Effect",
        Effects: "Effects",
        Remark: "Remark",
        Remarks: "Remarks",
        Strains: "Strains",
      },
      nl: {
        Effect: "Effect",
        Effects: "Effecten",
        Remark: "Opmerking",
        Remarks: "Opmerkingen",
        Strains: "Stammen",
      },
      it: {
        Effect: "Effetto",
        Effects: "Effetti",
        Remark: "Nota",
        Remarks: "Note",
        Strains: "Ceppi",
      },
      de: {
        Effect: "Wirkung",
        Effects: "Wirkungen",
        Remark: "Anmerkung",
        Remarks: "Anmerkungen",
        Strains: "Stämme",
      },
    };
    return translations[this.translate.currentLang][keyword] || keyword;
  }

  selectedStrain: {
    strain: string;
    category: string;
    subcategory: string;
  } | null = null;
  selectedSupplement: string = "";
  selectedPrebioticBacteria: string = "";
  selectedPrebioticCompound: string = "";

  toggleStrainInfo(strain: string, category: string, subcategory: string) {
    console.log(
      `Strain: ${strain}, Category: ${category}, Subcategory: ${subcategory}`
    );
    if (
      this.selectedStrain &&
      this.selectedStrain.strain === strain &&
      this.selectedStrain.category === category &&
      this.selectedStrain.subcategory === subcategory
    ) {
      this.selectedStrain = null;
    } else {
      this.selectedStrain = { strain, category, subcategory };
    }
    if (this.infoIcon) {
      const iconPosition =
        this.infoIcon.nativeElement.getBoundingClientRect().left;
      this.dynamicMaxWidth =
        this.calculateMaxWidthFromIconPosition(iconPosition).toString();
    }
  }

  calculateMaxWidthFromIconPosition(iconPosition: number): string {
    const viewportWidth = window.innerWidth;
    const remainingWidthInPx = viewportWidth - iconPosition;

    // Convert the remaining width to vw units
    const remainingWidthInVw = (remainingWidthInPx / viewportWidth) * 100;

    // Return the result as a string with 'vw' appended
    return `${Math.max(remainingWidthInVw - 10, 5)}vw`; // Set a minimum of 5vw for better UI scaling
  }

  toggleSupplementInfo(supplement: string) {
    this.selectedSupplement =
      this.selectedSupplement === supplement ? "" : supplement;
    if (this.infoIcon) {
      const iconPosition =
        this.infoIcon.nativeElement.getBoundingClientRect().left;
      console.log(iconPosition);
      this.dynamicMaxWidth =
        this.calculateMaxWidthFromIconPosition(iconPosition).toString();
      console.log(this.dynamicMaxWidth);
    }
  }
  isStrainSelected(
    strain: string,
    category: string,
    subcategory: string
  ): boolean {
    return (
      this.selectedStrain &&
      this.selectedStrain.strain === strain &&
      this.selectedStrain.category === category &&
      this.selectedStrain.subcategory === subcategory
    );
  }

  togglePrebioticCompoundInfo(compound: string) {
    this.selectedPrebioticCompound =
      this.selectedPrebioticCompound === compound ? "" : compound;
  }

  togglePrebioticBacteriaInfo(bacteria: string) {
    this.selectedPrebioticBacteria =
      this.selectedPrebioticBacteria === bacteria ? "" : bacteria;
  }
  // Prebiotic foods sorting and duplicate removal logic
  sortAndFilterFoods() {
    const displayedFoods = new Set<string>();
    const sortedFoods: any[] = [];

    // Get the compound order from supplements
    const compoundOrder: number[] = [];
    this.prebioticSupplements.forEach((supplement) => {
      supplement.prebiotic_compounds.forEach((compound) => {
        if (!compoundOrder.includes(compound.prebiotic_compound_id)) {
          compoundOrder.push(compound.prebiotic_compound_id);
        }
      });
    });

    console.log("Compound Order (from supplements):", compoundOrder);

    // Sort prebiotic compounds based on the order from supplements
    this.prebioticCompounds.sort((a, b) => {
      const indexA = compoundOrder.indexOf(a.prebiotic_compound_id);
      const indexB = compoundOrder.indexOf(b.prebiotic_compound_id);

      if (indexA !== -1 && indexB !== -1) return indexA - indexB; // Sort by index
      if (indexA !== -1) return -1; // A comes first
      if (indexB !== -1) return 1; // B comes first
      return 0; // Keep original order if neither is found
    });

    console.log("Sorted Compounds:", this.prebioticCompounds);

    // Now sort the foods within each compound
    this.prebioticCompounds.forEach((compound) => {
      const sortedFoodsByContent = compound.foods.sort((a, b) => {
        const contentDiff =
          this.parseContent(b.content) - this.parseContent(a.content);
        console.log(a);
        console.log(this.parseContent(a.content));
        console.log(b);
        console.log(this.parseContent(b.content));
        // If content is the same, sort alphabetically
        if (contentDiff === 0) {
          return a.name.localeCompare(b.name);
        }
        return contentDiff;
      });

      // Filter out duplicates and add remaining foods to the sortedFoods array
      sortedFoodsByContent.forEach((food) => {
        if (!displayedFoods.has(food.name)) {
          displayedFoods.add(food.name); // Mark the food as displayed
          sortedFoods.push({
            food: food.name,
            portion_size: food.portion_size,
            portion_size_text: food.portion_size_text,
            medical_tag: food.medical_tags,
          });
        }
      });
    });

    console.log("Sorted Foods (Final):", sortedFoods);
    this.allFoods = sortedFoods; // Store the result
  }

  // Helper function to parse food content, handling ranges
  parseContent(content: string): number {
    if (!content) return 0;
    const range = content.split("-");
    if (range.length === 2) {
      return (parseFloat(range[0]) + parseFloat(range[1])) / 2; // Average of the range
    }
    return parseFloat(content); // Single value
  }

  toggleProbioticCategoryDropdown(category: string) {
    this.dropdownState.dynamicProbioticsDropdownState[category] =
      !this.dropdownState.dynamicProbioticsDropdownState[category];
  }

  toggleSubDropdownProbioticsRecGuidelines(key: string): void {
    this.subDropdownProbioticsRecGuidelinesState[key] =
      !this.subDropdownProbioticsRecGuidelinesState[key];
  }

  // Method to toggle the FAQ dropdowns for probiotics recommendations
  toggleSubDropdownProbioticsRecFAQ(key: string): void {
    this.subDropdownProbioticsRecFAQState[key] =
      !this.subDropdownProbioticsRecFAQState[key];
  }

  // Similarly, for prebiotics dropdowns, add their toggling methods
  toggleSubDropdownPrebioticsRecGuidelines(key: string): void {
    this.subDropdownPrebioticsRecGuidelinesState[key] =
      !this.subDropdownPrebioticsRecGuidelinesState[key];
  }

  toggleSubDropdownPrebioticsRecFAQ(key: string): void {
    this.subDropdownPrebioticsRecFAQState[key] =
      !this.subDropdownPrebioticsRecFAQState[key];
  }

  // Download PDF
  async downloadPDF() {
    try {
      const pdfData = await this.dietaryDashService.GetSupplementAdvicePDF(
        this.testKitID,
        this.app.selectedLanguage
      );

      if (pdfData && pdfData.pdf_link && pdfData.pdf_link.file_url) {
        const pdfUrl = pdfData.pdf_link.file_url;

        // Create a hidden link element
        const link = document.createElement("a");
        link.href = pdfUrl;

        // Set the target to open in a new tab
        link.setAttribute("target", "_blank");

        // Append the link to the body
        document.body.appendChild(link);

        // Programmatically click the link to open the PDF in a new tab
        link.click();

        // Remove the link from the document after the PDF is opened
        document.body.removeChild(link);
      } else {
        console.error("PDF download link not found");
      }
    } catch (error) {
      console.error("Error downloading PDF: ", error);
    }
  }

  getLegendMedicalTags(medical_tags: number[]) {
    // Loop through each prebiotic compound

    medical_tags.forEach((tagId) => {
      switch (tagId) {
        case 1: // IBS
          this.showIbsTag = true;
          break;
        case 2: // Gluten
          this.showGlutenTag = true;
          break;
        case 3: // Lactose
          this.showLactoseTag = true;
          break;
        case 4: // Histamine
          this.showHistamineTag = true;
          break;
        default:
          break;
      }

      // Check if all flags are true, if so, break the loop
      if (
        this.showIbsTag &&
        this.showGlutenTag &&
        this.showLactoseTag &&
        this.showHistamineTag
      ) {
        return; // Breaks the inner loop
      }
    });
  }

  showTagInfo(tag: string, foodItem: any) {
    this.hoveredTag = tag;
    this.hoveredFoodItem = foodItem;
    if (this.infoIcon) {
      const iconPosition =
        this.infoIcon.nativeElement.getBoundingClientRect().left;
      console.log(iconPosition);
      this.dynamicMaxWidth =
        this.calculateMaxWidthFromIconPosition(iconPosition).toString();
      console.log(this.dynamicMaxWidth);
    }
  }
  // Method to hide the info box
  hideTagInfo() {
    this.hoveredTag = null;
    this.hoveredFoodItem = null;
  }
  getMedicalTagDescription(tagId: string): string {
    const descriptionKey = this.medicalTagDescriptions[parseInt(tagId, 10)];
    return this.translate.instant(descriptionKey); // Translate the description
  }
  getMedicalTagImage(tagId: number): string | null {
    return this.medicalTagImages[tagId] || null;
  }

  isHovered(tag: string, foodItem: any): boolean {
    return this.hoveredTag === tag && this.hoveredFoodItem === foodItem;
  }
  getSortedMedicalTags(medicalTags: { [key: string]: any }): string[] {
    return Object.keys(medicalTags).sort((a, b) => {
      const nameA = this.getMedicalTagDescription(a).toLowerCase();
      const nameB = this.getMedicalTagDescription(b).toLowerCase();
      return nameA.localeCompare(nameB);
    });
  }

  hasMedicalTags(): boolean {
    return this.allFoods.some(
      (foodItem) =>
        foodItem.medical_tag && Object.keys(foodItem.medical_tag).length > 0
    );
  }
}
