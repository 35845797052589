import { Component, OnInit } from '@angular/core';
import {LangChangeEvent, TranslateService} from "@ngx-translate/core";

@Component({
  selector: 'app-information',
  templateUrl: './information.component.html',
  styleUrls: ['./information.component.css']
})
export class InformationComponent implements OnInit {

  constructor(private translate: TranslateService) {
    this.updateInformation();
    this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      this.updateInformation();
    });
  }

  title = 'Select a category to discover more about our work and our testing procedures';
  baseInformation = [
    {id: 1, alias: 'process', name: 'INFORMATION.PROCESS', image: '../../assets/process_variant.png'},
    {id: 2, alias: 'human', name: 'INFORMATION.HUMAN', image: '../../assets/human_variant.png'},
    // {id: 3, alias: 'animals', name: 'Pets & Farm animals', image: '../../assets/dog_new.png'},
    // {id: 4, alias: 'soil', name: 'Soil', image: '../../assets/plant_new.png'},
    {id: 5, alias: 'bacteria', name: 'INFORMATION.TOP_BACTERIA', image: '../../assets/bacteria_variant.png'}
  ];

  information = [
    {id: 1, alias: 'process', name: 'Process', image: '../../assets/process_variant.png'},
    {id: 2, alias: 'human', name: 'Human', image: '../../assets/human_variant.png'},
    // {id: 3, alias: 'animals', name: 'Pets & Farm animals', image: '../../assets/dog_new.png'},
    // {id: 4, alias: 'soil', name: 'Soil', image: '../../assets/plant_new.png'},
    {id: 5, alias: 'bacteria', name: 'Top Bacteria', image: '../../assets/bacteria_variant.png'}
  ];

  ngOnInit() {
  }

  updateInformation() {
    let i = 0;
    for (const step of this.baseInformation) {
      this.translate.get(step.name).subscribe((text: string) => {
        this.information[i].name = text;
      });
      i++;
    }
  }

}
