import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import {IDashboardDietary, IEyyo} from "../../../interfaces/advice";
import {DietaryDashService} from "../api/calculations/dietarydash";
import {ActivatedRoute} from "@angular/router";
import {LangChangeEvent, TranslateService} from "@ngx-translate/core";

@Component({
  selector: 'app-infection-alarm',
  templateUrl: './infection-alarm.component.html',
  styleUrls: ['./infection-alarm.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class InfectionAlarmComponent implements OnInit {
  constructor(public dietaryDashService: DietaryDashService, private route: ActivatedRoute, private translate: TranslateService) {
  }

  resultText = '';
  dietaryAdviceText = '';
  foodTableHTML = '';
  bottomTableHTML = '';
  infectionAlarm;
  infectionAlarmResult;
  range;
  dashBacteria: IEyyo;
  dietaryAdvice: IDashboardDietary[];

  async ngOnInit() {
    this.updateFoodTable();
    this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      this.updateFoodTable();
      this.setText();
    });
    this.dashBacteria = JSON.parse(sessionStorage.getItem('Dashboard'));
    console.log(this.dashBacteria);
    this.infectionAlarm = this.dashBacteria.graphs.find(x => x.graph === 'Infection Alarm').value;
    this.range = this.dashBacteria.graphs.find(x => x.graph === 'Infection Alarm').range;
    const testKitID = Number(this.route.snapshot.paramMap.get('id'));
    this.dietaryAdvice = await (await this.dietaryDashService.GetDietaryAdvice(testKitID, 'Infection Alarm')).data;
    this.setText();
  }

  getBacteriaLevel() {

    const dif = (this.range[1] - this.range[0]) / 70 * 15;
    const refUp = this.range[1] + dif; // potential for gas production
    const refLow = this.range[0] - dif; // potential for gas production

    let cal = (this.infectionAlarm - refLow) / (refUp - refLow) * 100;
    if (cal < 0) {
      cal = 0;
    }
    if (cal > 100) {
      cal = 100;
    }
    cal = 100 - cal;
    return 'calc(' + cal + '% - 15px)';
  }

  updateFoodTable() {
    this.translate.get('FOOD_ITEM_LIMIT_TABLE.HTML').subscribe((text: string) => {
      this.foodTableHTML = text;
    });
    this.translate.get('INFECTION_ALARM.BOTTOM_TABLE_HTML').subscribe((text: string) => {
      this.bottomTableHTML = text;
    });
  }

  resultSampleText() {
    if (this.infectionAlarm > this.range[1]) {
      this.createResultString('INFECTION_ALARM.BE_AWARE_RESULT');
      return 'be_aware';
    }
    if (this.infectionAlarm < this.range[0] || this.infectionAlarm === 0) {
      this.createResultString('INFECTION_ALARM.GREAT_RESULT');
      return 'great';
    }
    this.createResultString('INFECTION_ALARM.NORMAL_RESULT');
    return 'normal';
  }

  createResultString(tag: string) {
    this.translate.get(tag).subscribe((text: string) => {
      this.resultText = text;
    });
  }

  setText() {
    this.resultSampleText();
    this.translate.get('INFECTION_ALARM.NUTRITION_ADVICE').subscribe((text: string) => {
      this.dietaryAdviceText = text;
    });
  }

}
