import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {IItem, IItemAddon, IOrder, IOrderItem} from '../../../interfaces/orders';
import {ItemsService} from '../api/orders/items';
import {FormBuilder} from '@angular/forms';

@Component({
  selector: 'app-extra-options',
  templateUrl: './extra-options.component.html',
  styleUrls: ['./extra-options.component.css']
})
export class ExtraOptionsComponent implements OnInit {

  constructor(private formBuilder: FormBuilder, private route: ActivatedRoute, private itemsService: ItemsService) {
  }

  public id: number;

  // elements that stay the same
  lblQuantity = 'Quantity';
  lblTotalPrice = 'Total price';
  btnAddKit = 'Add kit(s) to shopping cart';
  btnCart = 'Go to shopping cart';
  basket: IOrder;
  // lblQuantity = 'Quantity';
  // lblTotalPrice = 'Total price';
  // btnAddKit = 'Add kit(s) to shopping cart';
  // btnCart = 'Go to shopping cart';
  item: IItem;
  itemAddons: IItemAddon[] = [];

  // elements that change based on id
  optionTitle = 'Add extra options to your basic kit';
  optionExplanation = 'For 130€, the basic kit will tell you about the general condition of your microbiome and give you some dietary...';
  // if it's not a checkbox, it's a radio button
  isCheckbox = true;

  orderForm = this.formBuilder.group({
    quantity: ''
  });

  addonForm = this.formBuilder.group({
    addon: false,
  });


  async ngOnInit() {
    if (localStorage.getItem('Basket') === null) {
      const order: IOrder = {
        notes: '',
        total_price: 0,
        shipping_address_obj: null,
        billing_address_obj: null,
        order_items: []
      };
      localStorage.setItem('Basket', JSON.stringify(order));
    }
    this.basket = JSON.parse(localStorage.getItem('Basket'));

    // TODO: swap all the active route parameters with the following row:
    this.id = Number(this.route.snapshot.paramMap.get('id'));
    const response = await this.itemsService.GetItem(this.id);
    this.item = response.data;
  }

  addAddon(event: any, id: number) {
    if (event.target.checked) {
      this.itemAddons.push(this.item.item_addons.find(x => x.id === id));

    } else {
      this.itemAddons.forEach((value, index) => {
        if (value.id === id) {
          this.itemAddons.splice(index, 1);

        }
      });
    }
  }

  async onSubmit() {
    if (this.orderForm.get('quantity').value > 0) {
      const orderRow: IOrderItem = {
        title: this.item.title,
        item_id: this.item.id,
        quantity: this.orderForm.get('quantity').value,
        price: this.item.price,
        total_price: 0,
        item_addons: this.itemAddons
      };
      orderRow.total_price += (orderRow.price + orderRow.item_addons.reduce((sum, current) => sum + current.price, 0)) * orderRow.quantity;
      this.basket.total_price += orderRow.total_price;
      this.basket.order_items.push(orderRow);
      localStorage.setItem('Basket', JSON.stringify(this.basket));
      this.itemAddons = [];
      this.addonForm.reset();
    }
  }


}
