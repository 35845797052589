export default function setupAxiosPlugin({ $axios }) {
  // Ensure $axios is an Axios instance
  if (!$axios || typeof $axios.interceptors === 'undefined') {
    throw new Error('$axios must be an Axios instance');
  }

  // Add a response interceptor to handle errors globally
  $axios.interceptors.response.use(
    (response) => response, // Pass through successful responses
    (error) => {
      if (error.response && error.response.status === 401) {
        console.error("401 Error: Unauthorized. Navigating back to the previous page...");
        // Go back to the previous page
        localStorage.removeItem("token");
        window.location.href = '/'; // This navigates the user back to the previous page
      }
      return Promise.reject(error); // Reject the error to propagate it
    }
  );
}
