import { Component, OnInit } from '@angular/core';
import {TranslateService} from "@ngx-translate/core";

@Component({
  selector: 'app-cookie-policy',
  templateUrl: './cookie-policy.component.html',
  styleUrls: ['./cookie-policy.component.css']
})
export class CookiePolicyComponent implements OnInit {

  constructor(private translate: TranslateService) { }

  pageTitle = 'Cookie policy of InnerBuddies B.V.';
  pageSubtitle = 'Version May 24, 2018';

  paragraphs = [
    {
      id: 1,
      title: 'General information',
      text: 'InnerBuddies B.V. uses cookies on its web shop and related sites (refer to the \'webshop\'). Because we want to guarantee your privacy and improve the user-friendliness of your visit (s) to our webshop, we think it is important that you know how and why we use cookies. Below we give you as much information as possible about the use of these cookies by InnerBuddies B.V. Via the cookie settings option on your screen you can always change your cookie settings easily.'
    },
    {
      id: 2,
      title: 'Who is responsible for the processing of your data?',
      text: 'InnerBuddies B.V., located in Venlo, is responsible for the processing of data as described in this cookie policy. The following websites belong to InnerBuddies B.V.:\n' +
        'Innerbuddies.com'
    },
    {
      id: 3,
      title: 'What are cookies?',
      text: 'Cookies are small, simple text files that store your computer or mobile device when you use our webshop. Cookies and other techniques are used at all existing web stores. For example, by using cookies, we ensure that:\n\n' +
        '- Surfing in our web store is more fun and easier for you;\n' +
        '- When you visit our web store you do not always receive or need to enter the same information;\n' +
        '- We can offer you special functionalities such as the wishlist or your shopping cart;\n' +
        '- We can measure how our web store is used and where we can still improve it;\n' +
        '- We can show your ads and recommendations based on your interests;\n' +
        '- We can recognize which device you use when you visit us;\n' +
        '- We can (let) register how often an advertisement is shown in order to better target the advertisements to your needs and interests and to prevent you from seeing the same advertisement each time. \n'
    },
    {
      id: 4,
      title: 'Third party cookies',
      text: 'Cookies from third parties are also placed via our webshop. The privacy and cookie policy of the company in question applies to the use of cookies by other companies. In our case it specifically concerns the following companies:\n\n' +
        '- Google for the services Google Analytics (tracking number of visitors per page for example) and Google Advertising (eg measuring the return on each individual advertisement we place via Google)\n' +
        '- Microsoft for Bing Advertising services (for example, measuring the return on each individual advertisement we post through Google)\n' +
        '- In addition, Facebook, LinkedIn and Twitter place cookies on our website, this is the result of the possibility that we offer you to log in via our Facebook, LinkedIn or Twitter account on our website) '
    },
    {
      id: 5,
      title:'What types of cookies do we use?',
      text:'To summarize:\n\n' +
        'Functional cookies or necessary cookies\n' +
        'Functional cookies ensure that our web shop functions correctly. Consider, for example, functionalities such as placing an item in your shopping cart or paying for your order.\n\n' +
        'Analytic cookies\n' +
        'With analytical cookies we collect statistics about how our webshop is used with the help of third parties. By measuring website usage, we can continue to improve our store for the benefit of our users.\n\n' +
        'Marketing cookies\n' +
        'We place marketing cookies on our web store. Third parties can also place marketing cookies on our web store (such as Google, Microsoft and Facebook). We do this so that we can make you the most relevant offers based on your online surfing, searching and buying behavior. With these marketing cookies we also keep track of which ads you have seen.\n' +
        'We only use analytical and marketing cookies if you have given permission for this. If you do not give permission, advertisements will still be shown, but these are random advertisements. \n'
    },
    {
      id: 6,
      title:'Browser settings',
      text:'If you do not want websites to store cookies on your computer, you can adjust your browser settings. You will then receive a warning before cookies are placed. You can also adjust your settings so that your browser refuses all cookies or only cookies from third parties. You can also delete cookies that have already been placed. Note that you must adjust the settings separately for each browser on each device you use. We would like to draw your attention to the fact that if you do not want to use cookies, we unfortunately cannot guarantee that our webshop is working properly. It may be that some functions of the web shop are lost or that you cannot even see certain pages at all. In addition, refusing cookies does not mean that you will no longer see advertisements. The ads are then no longer adapted to your interests and are repeated more often. How you can adjust your settings differs per browser. If necessary, consult the help function of your browser.'
    },
    {
      id: 7,
      title:'Delete cookie history',
      text:'If you have chosen \'refuse marketing cookies\' above, then these cookies will no longer be placed. If you also want to delete your cookie history, you can do this directly in the settings of your browser.'
    },
    {
      id: 8,
      title:'The privacy policy of InnerBuddies B.V.',
      text:'With the use of some cookies, personal data are processed as you have read above. In our privacy policy you will find more information about how InnerBuddies B.V. deal with your data.'
    },
    {
      id: 9,
      title:'Conclusion',
      text:'We adjust our cookie policy when our webshop or the rules around cookies change. You can consult the current version of this cookie policy by clicking on the link to the cookie policy at the bottom of each page.'
    }
  ];

  ngOnInit() {
  }

}
