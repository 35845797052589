import {Component, OnInit} from '@angular/core';
import {ItemsService} from '../api/orders/items';
import {IItem, IItemAddon, IOrder, IOrderItem} from '../../../interfaces/orders';
import {FormBuilder} from '@angular/forms';
import {UtilityService} from '../utility.service';
import {TranslateService} from "@ngx-translate/core";
import {UtilsService} from '../api/utils/utils';

declare let dataLayer: any[];

@Component({
  selector: 'app-test-category',
  templateUrl: './test-category.component.html',
  styleUrls: ['./test-category.component.css']
})
export class TestCategoryComponent implements OnInit {

  public id: number;

  // elements that stay the same
  lblQuantity = 'Quantity';
  lblTotalPrice = 'Total price';
  btnAddKit = 'Add kit(s) to shopping cart';
  btnCart = 'Go to shopping cart';
  basket: IOrder;
  item: IItem;
  itemAddons: IItemAddon[] = [];

  orderForm = this.formBuilder.group({
    quantity: 1
  });

  addonForm = this.formBuilder.group({
    addon: false,
  });

  timeStamp = Math.round(Date.now() / 1000);

  facebookPixlData = {
    "data": [
        {
            "event_name": "Add item",
            "event_time": this.timeStamp,
            "action_source": "website",
            "user_data": {
                "em": [
                    "7b17fb0bd173f625b58636fb796407c22b3d16fc78302d79f0fd30c2fc2fc068"
                ],
                "ph": [
                    null
                ]
            },
            "custom_data": {
                "currency": "EUR",
                "value": "185"
            }
        }
    ]
}

  constructor(private utilsAPI: UtilsService, private itemsService: ItemsService, private formBuilder: FormBuilder, private utils: UtilityService, private translate: TranslateService) {
  }

  items: IItem[];
  totalPrice = 0;

  title = 'Select a test category';
  explanation = 'Not only can you test your own microbiome, but soon you will be able to also check the health of your pets and even soil.';

  async ngOnInit() {
    if (localStorage.getItem('Basket') === null) {
      const order: IOrder = {
        notes: '',
        total_price: 0,
        shipping_address_obj: null,
        billing_address_obj: null,
        order_items: []
      };
      localStorage.setItem('Basket', JSON.stringify(order));
    }
    this.basket = JSON.parse(localStorage.getItem('Basket'));

    // TODO: swap all the active route parameters with the following row:
    const response = await this.itemsService.GetItem(1);
    this.item = response.data;
    this.totalPrice = this.item.price;
  }

  updateCartNumber() {
    if (localStorage.getItem('Basket')) {
      this.basket = JSON.parse(localStorage.getItem('Basket'));
      let total = 0;
      for (const item of this.basket.order_items) {
        total += item.quantity;
      }
      localStorage.setItem('shoppingCartNumber', total.toString());
      (document.getElementById('shoppingCartNumber') as HTMLSpanElement).textContent = total.toString();
    }
  }

  updatePrice(newValue) {
    this.totalPrice = newValue * this.item.price;
  }

  async onSubmit() {
    console.log(this.facebookPixlData);
    this.utilsAPI.FacebookTag(this.facebookPixlData);

    let quantityValue = 1;
    if (this.orderForm.get('quantity').value > 0) {
      quantityValue = this.orderForm.get('quantity').value;
    }

    const orderRow: IOrderItem = {
      title: this.item.title,
      item_id: this.item.id,
      quantity: quantityValue,
      price: this.item.price,
      total_price: 0,
      item_addons: this.itemAddons
    };
    orderRow.total_price += (orderRow.price + orderRow.item_addons.reduce((sum, current) => sum + current.price, 0)) * orderRow.quantity;
    this.basket.total_price += orderRow.total_price;
    this.basket.order_items.push(orderRow);
    localStorage.setItem('Basket', JSON.stringify(this.basket));
    this.itemAddons = [];
    this.addonForm.reset();
    this.updateCartNumber();
    this.utils.showSuccess('CART_UPDATED_SUCCESS', 'CART_UPDATED_SUCCESS');

    dataLayer.push({  ecommerce: null});

    dataLayer.push({
      
      event: "add_to_cart",
      ecommerce: {
        currency: "EUR",
        value: orderRow.total_price,
        items: [
          {
            item_id: '1',
            item_name: "Microbiome test kit",
            price: 185,
            quantity: orderRow.quantity
          }
        ]
      }
    });
    
  }
}
