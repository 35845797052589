import axios from 'axios';
import { Injectable } from '@angular/core';
import {ISubject, ITestKit} from 'interfaces/tests';
import { ITestKitUpdate } from 'interfaces';
import {environment} from '../../../environments/environment';

axios.defaults.baseURL = environment.url.api;

function authHeaders() {
    const token = localStorage.getItem('token');
    return {
        headers: {
            Authorization: `Bearer ${token}`
        }
    };
}

@Injectable()
export class SubjectService {
    constructor() {}


    async GetSubjects(category_id: number) {
        return axios.get<ISubject[]>(`/api/v1/subjects/${category_id}`, authHeaders());
    }

    async GetHCPSubjects() {
        return axios.get<ISubject[]>(`/api/v1/subjects/hcp_linked/`, authHeaders());
    }

    async TryConenctingHCPKit(testkit_uid: string) {
        return axios.post<ISubject[]>(`/api/v1/testkit_hcp_connection/create_within_range/${testkit_uid}`, {}, authHeaders());
    }

    async CreateSubject(data: ISubject) {
        return axios.post(`/api/v1/subjects/`, data, authHeaders());
    }

    async GetSubject(id: number) {
        return axios.get<ISubject>(`/api/v1/subjects/single/${id}`, authHeaders());
    }

    async UpdateSubject(data: ISubject, id: number) {
        return axios.put<ISubject>(`/api/v1/subjects/${id}`, data, authHeaders());
    }

    async LinkTestKit(data: ITestKitUpdate, id: number) {
        return axios.put<ITestKit>(`/api/v1/result_uploads/add_subject/${id}`, data, authHeaders());
    }

    async SubmitDietary(id: number) {
        return axios.get<ITestKit>(`/api/v1/test_kits/add_dietary/${id}`, authHeaders());
    }

    async ReopenDietary(id: number) {
        return axios.get<ITestKit>(`/api/v1/test_kits/reopen_dietary/${id}`, authHeaders());
    }

    async GetTestKit(kitId: number) {
        return axios.get<ITestKit>(`/api/v1/test_kits/${kitId}`, authHeaders());
    }

    async GetSubjectByTestkit(testkitID: number) {
        return axios.get<ITestKit>(`/api/v1/test_kits/get_subject_id/${testkitID}`, authHeaders());
    }
}
