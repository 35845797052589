import { Theme } from './symbols';

export const iprobioTheme: Theme = {
  name: 'iprobio',
  properties: {
    '--color-profile-tab': '#54ADB4',
    '--navbar-img': 'url("assets/iProbio_logo_brand.png")',
    '--footer-img': 'url("assets/iProbio_logo_brand.png")',
    '--navbar-color': '#333333',
    '--navbar-light-color': 'white',
    '--conditions-color': '#1b757c',
    '--navigation-color': '#1b757c',
    '--contact-color': '#1b757c',
    '--copyrights-color': '#17a19e',
    '--btn-dark-color': 'linear-gradient(135deg, rgba(23,161,158,1) 0%, rgba(125,199,144,1) 100%)',
    '--btn-light-color': '#8bc28ba1',
    '--btn-light-color-active': '#8bc28b',
    '--table-header-color': '#54adb4',
    '--kit-code-btn-color': '#54ADB4',
    '--btn-fill-in-color': '#54aeb48c',
    '--btn-fill-in-color-active': '#54aeb4',
    '--food-diary-color': '#54aeb4',
    '--food-diary-color-light': '#54adb43b',
    '--survey-color': '#54ADB4',
    '--survey-color-complete': '#298C89',
    '--dashboard-container': '#54aeb421',
    '--dashboard-container-hover': '#54aeb454',
    '--sub-dropdown-color':'#54ADB4',
    '--dropdown-box':'#dbeceb',
    '--compound-container-color':'#dbeceb',
    '--normal-color': '#62b3b3',
    '--okay-color': '#b5d2d2',
    '--great-color': '#6fd06f',
    '--bad-color': '#f299a1',
    '--aware-color': '#339d9c69',
    '--warning-color': '#62b3b3',
    '--warning-color-fibre': '#62b3b3',
    '--take-home-bubble-color': '#62b3b3',
    '--rectangle-color': '#dbeceb',
    '--description-color': '#298c892b',
    '--protein-color': '#dbeceb',
    '--bmi-1-color': '#54adb426',
    '--bmi-2-color': '#54adb440',
    '--bmi-3-color': '#54adb47d',
    '--bmi-4-color': '#54adb4c7',
    '--bmi-5-color': '#54ADB4',
    '--recomendations-color': '#54ADB4',
    '--img-placeholder-color': '#dbeceb',
    '--fibre-color': '#62b3b38f',
    '--green-color': '#6fd06f',
    '--yellow-color': '#f299a1',
    '--footer-justify-content': 'space-between',
    '--button-light-1-color': '#8bc28ba1', 
    '--lessnormal-color': '#8bc28ba1',
    '--morenormal-color': '#8bc28ba1',
  }
};